import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../asset/images/SignIn/cogito-metaverse.png";
// import logoDark from "../../asset/images/cogito-logo_wht.png";
import logodark from "../../asset/images/Header/logo_dark.svg";

const useStyles = makeStyles((theme) => ({
	image: {
		height: "80px",
		width: "80px",
		marginLeft: "-10px",
	},
}));

const Logo = () => {
	const classes = useStyles();

	return (
		<div>
			<img src={logodark} className={classes.image} />
		</div>
	);
};

export default Logo;
