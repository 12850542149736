export const updateObject = (oldObject, updateObject) => {
  return {
    ...oldObject,
    ...updateObject,
  };
};
export const arrayUnique = (array) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};
export const updateObjectProperty = (oldObject, property, value) => {
  return {
    ...oldObject,
    [property]: value,
  };
};
export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 900000,
  };
  // 900000 = 15 min
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage?.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  let item;
  try {
    item = JSON.parse(itemStr);
  } catch (err) {
    console.log("JSON.parse Error: ", err);
  }
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item?.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value ? item.value : item;
};

export const setLocal = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocal = (key) => {
  const item = localStorage?.getItem(key);
  if (!item) {
    return null;
  }
  return item;
};

export const removeLocal = (key) => {
  localStorage.removeItem(key);
};

export function deleteAllCookies() {
  try {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name == "__tawkuuid") {
        document.cookie =
          name +
          "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" +
          document.location.hostname +
          ";";
      } else {
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function maskNumber(number) {
  // Convert the number to a string
  const numberStr = number.toString();

  // Check if the numberStr has more than 4 characters
  if (numberStr.length > 4) {
    // Extract the last 4 digits
    const lastFourDigits = numberStr.slice(-4);
    // Create a masked string with asterisks for the remaining digits
    const maskedString = "*".repeat(numberStr.length - 4) + lastFourDigits;
    return maskedString;
  }

  // If the number has 4 or fewer digits, return it as is
  return numberStr;
}


export function hasTimePassed(targetTime) {
  // Parse the target time string to a Date object
  const targetDate = new Date(targetTime);

  // Get the current time
  const currentDate = new Date();

  // Compare the target time with the current time
  return targetDate < currentDate;
}
// Your base64 string (shortened for this example)
// const base64 = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD…Yz1OMVlZUS2BlpipFwU8pLSVrUojYCeg/sYrKysqqWSp//9k=';

// Convert Base64 string to a Blob
export function base64ToBlob(base64input, mime, name) {
  let base64 = base64input.split(",")[1];

  mime = mime || "";
  var sliceSize = 1024;
  var byteChars = atob(base64);
  var byteArrays = [];

  for (
    var offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: mime });

  // console.log("convertedd", URL.createObjectURL(blob));

  // Create a File object with additional properties
  const capturedFile = new File([blob], `captured-image${name}.png`, {
    lastModified: new Date().getTime(),
    type: "image/png",
  });

  return { url: URL.createObjectURL(blob), capturedFile: capturedFile };
}
