import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import kyc_first from "../../asset/images/Banking/kyc_first.svg";
import ThemeButton from "../common/button/index";
import AddAccount from "./add_account";
import * as actionCreator from "../../store/action/index";
import Spinner from "../common/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 0),
  },
  accordian: {
    width: "100%",
    background: theme.palette.background.default,
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 13px 7px rgba(16,16,16,1), -5px 2px 13px 7px rgba(16,16,16,1), 2px 2px 13px 7px rgba(16,16,16,1)",
    borderRadius: 10,
    borderLeft: "4px solid #359DB6",
    minHeight: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 32,
  },
  accordianContainer: {
    padding: 0,
    width: "100%",
  },
  spaceHolder: {
    width: 68,
    height: 60,
    border: "2px solid black",
    background: theme.palette.background.default,
    borderRadius: 11,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 22,
    margin: "auto 24px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down(600)]: {
      height: "35vh",
    },
  },
}));

const Stocks = (props) => {
  const classes = useStyles();
  const { kyc, getProfile, userKYC } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (kyc !== null) {
      setLoading(false);
    }
  }, [kyc]);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Grid container>
      {kyc !== null ? (
        <AddAccount />
      ) : kyc !== null && !(kyc?.status && userKYC?.status === "completed") ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              style={{ color: "#ff7300" }}
            >
              Complete Your KYC
            </Typography>
            <Typography variant="body1" align="center">
              Sorry, Stocks section will be available only post KYC
            </Typography>
          </Grid>

          {/* Logo */}
          <Grid item xs={10} sm={8} className={classes.imageContainer}>
            <img
              src={kyc_first}
              alt="complete_kyc_first"
              className={classes.image}
            />
          </Grid>

          <Grid item xs={8} sm={6} md={5}>
            <ThemeButton
              title="Go To Profile"
              width="100%"
              onClick={() => {
                window.location = "/profile";
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    userKYC: userReducer.userKYC,
    kyc: userReducer.kyc,
    profile: userReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(actionCreator.getProfile()),
    notify: (message, variant) =>
      dispatch(actionCreator.notify(message, variant)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
