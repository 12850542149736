import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import * as yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";

import CustomButtons from "../preloggedin/CustomButtons";
import * as actionCreator from "../../store/action/index";
import callApi from "../../asset/api";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const validationSchema = yup.object({
	username: yup.string().required("Username is required!"),
	password: yup
		.string()
		.matches(lowercaseRegex, "one lowercase required!")
		.matches(uppercaseRegex, "one uppercase required!")
		.matches(numericRegex, "one number required!")
		.min(8, "Password should be of minimum 8 characters")
		.required("Password is required!"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password")], "Password must be the same!")
		.required("Confirm Password is required!"),
});

const useStyles = makeStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: "blue",
		},
		"& .MuiInputBase-root": {
			color: "#000",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "green",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "blue",
			},
			"&.Mui-focused fieldset": {
				borderColor: "blue",
			},
		},
	},

	input: {
		color: "grey",
	},

	buttonContainer: {
		background: "red",
		width: isMobile ? "40vw" : "20vw",
		fontSize: 18,
		margin: "auto",
		height: 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
	},
}));

const UsernamePage = (props) => {
	const { activeStep, setActiveStep, regUser, registered, notify, token } =
		props;
	const [loading, setLoading] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const staffRegister = (staff_details) => {
		const reqBody = { staff_details: staff_details, token: token };

		callApi("RegisterStaff", "POST", reqBody)
			.then((res) => {
				const { error, errorMessage, successMessage, status, token } = res;
				if (status) {
					setLoading(false);
				} else {
					setLoading(false);
					notify(errorMessage, "error");
				}
			})
			.catch((err) => {
				setLoading(false);
				notify(err?.response?.data?.errorMessage, "error");
			});
	};

	const staffRegisterCreds = (username, password) => {
		const reqBody = { username: username, password: password, token: token };

		callApi("RegisterStaffCreds", "POST", reqBody)
			.then((res) => {
				const { error, errorMessage, successMessage, status, token } = res;
				if (status) {
					setLoading(false);
					let data = {
						...regUser,
						...props.user,
					};
					staffRegister({ ...data, dob: moment(data.dob).toISOString() });
					setActiveStep(activeStep + 1);
					notify(successMessage, "success");
				} else {
					setLoading(false);
					notify(errorMessage, "error");
				}
			})
			.catch((err) => {
				setLoading(false);
				notify(err?.response?.data?.errorMessage, "error");
			});
	};

	const formik = useFormik({
		initialValues: props.formData
			? {
					username: props.formData.username,
					password: props.formData.password,
					confirm_password: props.formData.confirm_password,
			  }
			: { username: "", password: "", confirm_password: "" },

		validationSchema: validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: (values) => {
			const { username, password, confirm_password } = values;

			staffRegisterCreds(username, password);
		},
	});

	useEffect(() => {
		if (registered) {
			window.location = `/login`;
		}
	}, [registered]);

	const classes = useStyles();

	return (
		<>
			<form
				onSubmit={formik.handleSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					height: "55vh",
					margin: "1vh 0 5vh 0",
				}}
			>
				<div style={{ width: "80%" }}>
					<Grid item xs={12} style={{ background: "#fff" }}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Grid container direction="column" spacing={isMobile ? 5 : 6}>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										label="Username"
										type="text"
										id="username"
										name="username"
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										value={formik.values.username}
										onChange={formik.handleChange}
										fullWidth
										error={formik.touched.username && Boolean(formik.errors.username)}
										helperText={formik.touched.username && formik.errors.username}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										variant="outlined"
										label="Password"
										id="password"
										name="password"
										type={showPassword ? "text" : "password"}
										value={formik.values.password}
										onChange={formik.handleChange}
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={formik.touched.password && Boolean(formik.errors.password)}
										helperText={formik.touched.password && formik.errors.password}
									/>
								</Grid>
								<Grid item xs={12} style={{ marginBottom: "5vh" }}>
									<TextField
										fullWidth
										variant="outlined"
										id="confirm_password"
										name="confirm_password"
										type={showConfirmPassword ? "text" : "password"}
										label="Re-enter Password"
										value={formik.values.confirm_password}
										onChange={formik.handleChange}
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowConfirmPassword}
														// onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={
											formik.touched.confirm_password &&
											Boolean(formik.errors.confirm_password)
										}
										helperText={
											formik.touched.confirm_password && formik.errors.confirm_password
										}
										// required
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</div>
				<CustomButtons
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					allChecked={true}
				/>
			</form>
		</>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return { regUser: authReducer.regUser, registered: authReducer.registered };
};

const mapDispatchToProps = (dispatch) => {
	return {
		notify: (message, varient) =>
			dispatch(actionCreator.notify(message, varient)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsernamePage);
