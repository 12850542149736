import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

import { Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import EmailIcon from "@material-ui/icons/Email";

import * as actionCreator from "../../store/action/index";
import CustomStepper from "../common/stepper/CustomStepper";
import RegisterPage from "../common/userInfoForm";
import UsernamePage from "./usernamePage";
import TermsPage from "./termsPage";
import Nav from "../preloggedin/Nav";
import ThankYouPage from "../Register/thankYouPage";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "30vh",
    marginTop: "1rem",
    [theme.breakpoints.down(1000)]: {
      marginTop: "2rem",
    },
    [theme.breakpoints.down(720)]: {
      marginTop: "4rem",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "1rem",
    },
  },
  head: {
    color: "#000",
    fontWeight: "700",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },
  stepper: {
    marginTop: "5vh",
    backgroundColor: "#F0D290",
  },
  buttonContainer: {
    width: isMobile ? "60vw" : "20vw",
    fontSize: 18,
    margin: "auto",
    height: 48,
    borderRadius: 8,
    color: "#fff",
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
  },
}));

const LinearStepper = (props) => {
  const { setRegUser, regUser } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [registerPageData, setRegisterPageData] = useState();
  const [termsPageData, setTermsPageData] = useState(false);
  const [usernamePageData, setUsernamePageData] = useState();
  const [citiesList, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [skipped, setSkipped] = useState(false);

  const [addressFields, setAddressFields] = useState({
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    dob: "",
    address_details: {
      country: "",
      state: "",
      city: "",
      pincode: null,
      address: "",
    },
    termsAgreed: true,
    userName: "",
    password: "",
    re_password: "",
  });

  const steps = [
    "Verify Email",
    "Create a profile",
    "Agree to terms",
    "Username & Password",
  ];

  const icons = {
    1: <EmailIcon />,
    2: <AccountCircleIcon />,
    3: <VerifiedUserIcon />,
    4: <PermContactCalendarIcon />,
  };

  const handleStatesList = (data) => {
    if (data) {
      setStatesList(data);
    }
  };
  const handleCitiesList = (data) => {
    if (data) {
      setCitiesList(data);
    }
  };
  const handleRegPageData = (data) => {
    const { firstName, lastName, phone, dateOfBirth, ...address_part } = data;
    const { address } = address_part;
    //adding fisrtname,lastname,phoneNumber,dob and address to already having country,state,city,pincode
    setRegisterPageData({
      firstName,
      lastName,
      phone,
      dateOfBirth,
      address,
      ...addressFields,
    });
    setUser({
      ...user,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      dob: dateOfBirth,
      address_details: { ...addressFields, address: address },
    });
  };

  //handling empty data incoming
  const handleAddressInput = (data) => {
    if (data.country !== "") {
      setAddressFields(data);
    } else {
      setAddressFields({
        country: addressFields.country,
        state: data.state ? data.state : addressFields.state,
        city: data.city ? data.city : addressFields.city,
        pincode: data.pincode ? data.pincode : addressFields.pincode,
      });
    }
  };

  const handleTermsPageData = (data) => {
    setTermsPageData(data);
    setUser({ ...user, termsAgreed: data });
  };

  const handleUsernamePageData = (data) => {
    setUsernamePageData(data);
    const { username, password, confirm_password } = data;
    setUser({
      ...user,
      userName: username,
      password: password,
      re_password: confirm_password,
    });
  };

  function getStepContent(step) {
    switch (step) {
      case 1:
        return (
          <RegisterPage
            location="register"
            spacing={0}
            height="60vh"
            disableField={false}
            statesList={handleStatesList}
            citiesList={handleCitiesList}
            renderStatesList={statesList ? statesList : []}
            renderCitiesList={citiesList ? citiesList : []}
            activeStep={step}
            setActiveStep={setActiveStep}
            loadData={handleRegPageData}
            formData={registerPageData}
            getAddressInputData={handleAddressInput}
            skipped={skipped}
            setSkipped={setSkipped}
          />
        );
      case 2:
        return (
          <TermsPage
            activeStep={step}
            setActiveStep={setActiveStep}
            loadData={handleTermsPageData}
            formData={termsPageData}
          />
        );
      case 3:
        return (
          <UsernamePage
            activeStep={step}
            setActiveStep={setActiveStep}
            loadData={handleUsernamePageData}
            formData={usernamePageData}
            user={user}
          />
        );
      case 4:
        return <ThankYouPage />;
      default:
        return "unknown step";
    }
  }

  useEffect(() => {
    if (!regUser) {
      let value = JSON.parse(localStorage.getItem("regUser"))?.value;
      if (value) {
        setRegUser({
          ...value,
        });
      }
    }
  }, []);
  return (
    <Grid
      component={Paper}
      style={{
        height: isMobile ? "85vh" : "100vh",
        overflow: isMobile && "scroll",
      }}
      item
      xs={12}
      sm={12}
      md={8}
      elevation={0}
    >
      <div
        style={{
          mx: 4,
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "80vh" : "100vh",
        }}
      >
        <Nav detailOne={"Already have an account? "} detailTwo={"Sign In"} />
        {activeStep !== -1 && activeStep !== 4 && (
          <Box className={classes.container}>
            <Typography
              variant="h4"
              className={classes.head}
              style={{
                color: "#000",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Create Your Free Account
            </Typography>

            <CustomStepper
              // optionals={true}
              activeStep={activeStep}
              icons={icons}
              steps={steps}
            />
          </Box>
        )}

        {getStepContent(activeStep)}
      </div>
    </Grid>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    registered: authReducer.registered,
    regUser: authReducer.regUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    setRegUser: (data) => dispatch(actionCreator.setRegUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearStepper);
