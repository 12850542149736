import React, { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Spinner from "../../../common/Spinner/Spinner";
import { priceFormatter } from "../../../../utils/numberFormater/priceFormater";

const LineChart = memo(({ data, loadings }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const formatChartData = () => {
      if (data && data.length > 0) {
        let prev = null;
        const formattedData = data.map((item) => {
          // start temporay data mapping
          let price = item?.price;
          // if (prev && (prev - price > 0.1 || price - prev > 0.1)) {
          //   if (prev - price > 0.1) {
          //     price = prev - 0.1;
          //   } else {
          //     price = prev + 0.1;
          //   }
          // }
          prev = price;
          // end of temporay data mapping
          return {
            x: new Date(item.createdAt).getTime(),
            y: price,
          };
        });

        setChartData([{ data: formattedData }]);
        setLoading(false);
      }
    };

    formatChartData();
  }, [data]);

  const options = {
    grid: {
      show: false,
    },
    chart: {
      id: "basic-line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        // formatter: function (value, timestamp) {
        // 	let dte = formatDate(timestamp);
        // 	return dte;
        // },
        // showDuplicates: true,
        show: false,
      },
    },
    yaxis: {
      title: {
        // 	text: "Price",
        show: false,
      },
      show: false,
    },
    stroke: {
      width: 1,
    },
    tooltip: {
      theme: "dark",
      x: {
        formatter: function (value) {
          return formatDate(new Date(value));
        },
      },
      y: {
        formatter: function (value) {
          return priceFormatter(value);
        },
      },
      style: {
        backgroundColor: "#f4f4f4",
        color: "#333",
      },
    },
  };
  return (
    <div>
      {loadings ? (
        <Spinner />
      ) : (
        <Chart options={options} series={chartData} type="line" height={300} />
      )}
    </div>
  );
});

export default LineChart;
