export const constant = {
  assetsOptions: ["CBC100", "CB75CO25", "CB50CO50", "CB25CO75", "COG100"],
  breakPointUriLevelScreen: 600,
};

export const defaultCurrencies = constant.assetsOptions.map((data) => {
  return {
    locked: 0,
    free: 0,
    asset: data,
  };
});

export const stringAvatar = (name) => {
  return name?.match(/(\b\S)?/g).join("");
};

export const CalculatePL = (inv, currPrice, quantity) => {
  return ((currPrice - inv) * 100) / inv;
};
