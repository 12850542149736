import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
//material
import { Box, Typography } from "@material-ui/core";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import clsx from "clsx";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chart: {
    width: 305,
    borderRadius: 13,
    margin: "28px 0",
    maxWidth: "100%",
    background: localStorage.getItem("dark")
      ? theme.palette.background.default
      : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
  },
  chartContainer: {
    padding: 5,
    width: "100%",
  },
  chartTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 7,
  },
  title: {
    fontSize: 11,
    // color: "rgba(255,255,255,0.7)",
    fontWeight: "bold",
    padding: "6px 0px",
  },
  sideOptions: {
    display: "flex",
    flexDirection: "column",
  },
  xAxis: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 115, 0, 0.7)",
    fontSize: 10,
    position: "relative",
  },
  arrow: {
    position: "relative",
    right: 5,
  },
  dropDownContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    background:
      "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
    boxShadow:
      "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%)",
    width: "3.4rem",
    padding: 5,
    borderRadius: 5,
  },
  dropDownOptions: {
    display: "flex",
    justifyContent: "center",
    margin: "2px 0",
    "&:hover": {
      backgroundColor: "rgba(31,31,31,0.8)",
      cursor: "pointer",
    },
  },
  selectedDropDownOptions: {
    backgroundColor: "rgba(23,23,23,1)",
  },
  graphScale: {
    fontSize: 9,
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
  },
  cogScale: {
    color: "#359DB6",
  },
  euroScale: {
    color: "#FF7300",
  },
  filterName: {
    display: "flex",
    justifyContent: "center",
    color: "rgba(255, 115, 0, 0.7)",
  },
}));

const Chart = (props) => {
  const classes = useStyles();
  const { graphData, width, aspect } = props;

  const dropDownOptions = ["Hour", "Day", "Month"];

  const [filter, setFilter] = useState(dropDownOptions[0]);
  const [option, setOption] = useState(false);

  const dataCheck = [];

  const data = [];
  var curDate = new Date();
  graphData
    .filter((data) => {
      let date = new Date(data.time_stamp);
      let day = date.getDate();
      let month = date.getMonth;
      let year = date.getFullYear();

      if (filter == "Hour") {
        return true;
      } else if (filter == "Day") {
        let dayCheck = `${day}-${month}-${year}`;
        if (dataCheck.includes(dayCheck)) {
          return false;
        } else {
          dataCheck.push(dayCheck);
          return true;
        }
      } else {
        let monthCheck = `${month}-${year}`;
        if (dataCheck.includes(monthCheck)) {
          return false;
        } else {
          dataCheck.push(monthCheck);
          return true;
        }
      }
    })
    .slice(0, 10)
    .map((d) => {
      if (data.length >= 10) return;
      let stockDate = new Date(d.time_stamp);
      if (filter == dropDownOptions[0]) {
        curDate.setSeconds(0);
        curDate.setMilliseconds(0);
        curDate.setMinutes(0);
        stockDate.setMilliseconds(0);
        stockDate.setSeconds(0);
        stockDate.setMinutes(0);
        if (
          curDate.getDate() == stockDate.getDate() &&
          curDate.getHours() == stockDate.getHours() &&
          curDate.getMonth() == stockDate.getMonth() &&
          curDate.getFullYear() == stockDate.getFullYear()
        ) {
          let tempData = {
            cog: d.total_cog_circulation,
            euro: d.cog_asset_backing,
            xVal: stockDate.getHours(),
          };
          data.push(tempData);
          curDate.setHours(curDate.getHours() - 1);
        } else {
          while (curDate.getTime() >= stockDate.getTime() && data.length < 10) {
            let tempData = {
              cog: d.total_cog_circulation,
              euro: d.cog_asset_backing,
              xVal: curDate.getHours(),
            };
            data.push(tempData);
            curDate.setHours(curDate.getHours() - 1);
          }
        }
      } else if (filter == dropDownOptions[1]) {
        curDate.setSeconds(0);
        curDate.setMilliseconds(0);
        curDate.setMinutes(0);
        curDate.setHours(0);
        stockDate.setMilliseconds(0);
        stockDate.setSeconds(0);
        stockDate.setMinutes(0);
        stockDate.setHours(0);
        if (
          curDate.getDate() == stockDate.getDate() &&
          curDate.getMonth() == stockDate.getMonth() &&
          curDate.getFullYear() == stockDate.getFullYear()
        ) {
          let tempData = {
            cog: d.total_cog_circulation,
            euro: d.cog_asset_backing,
            xVal: stockDate.getDate(),
          };
          data.push(tempData);
          curDate.setDate(curDate.getDate() - 1);
        } else {
          while (curDate.getTime() > stockDate.getTime() && data.length < 10) {
            let tempData = {
              cog: d.total_cog_circulation,
              euro: d.cog_asset_backing,
              xVal: curDate.getDate(),
            };
            data.push(tempData);
            curDate.setDate(curDate.getDate() - 1);
          }
        }
      } else {
        curDate.setSeconds(0);
        curDate.setMilliseconds(0);
        curDate.setMinutes(0);
        curDate.setHours(0);
        curDate.setDate(1);
        stockDate.setMilliseconds(0);
        stockDate.setSeconds(0);
        stockDate.setMinutes(0);
        stockDate.setHours(0);
        stockDate.setDate(1);
        if (
          curDate.getMonth() == stockDate.getMonth() &&
          curDate.getFullYear() == stockDate.getFullYear()
        ) {
          let tempData = {
            cog: d.total_cog_circulation,
            euro: d.cog_asset_backing,
            xVal: stockDate.getMonth() + 1,
          };
          data.push(tempData);
          curDate.setMonth(curDate.getMonth() - 1);
        } else {
          while (curDate.getTime() > stockDate.getTime() && data.length < 10) {
            let tempData = {
              cog: d.total_cog_circulation,
              euro: d.cog_asset_backing,
              xVal: curDate.getMonth() + 1,
            };
            data.push(tempData);
            curDate.setMonth(curDate.getMonth() - 1);
          }
        }
      }
    });
  data.reverse();

  const handleOptions = () => {
    setOption(!option);
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleOptions();
  };
  return (
    <Box className={classes.chart} style={{ width: width }}>
      <Box className={classes.chartTitleContainer}>
        <Box>
          <Typography className={classes.title}>Asset COG vs EURO</Typography>
        </Box>
        <Box className={classes.sideOptions}>
          <Box>
            <Typography variant="body1" className={classes.xAxis}>
              {filter}
              <ButtonBase className={classes.arrow} onClick={handleOptions}>
                <ArrowDropDownIcon />
              </ButtonBase>
              {option ? (
                <Box className={classes.dropDownContainer}>
                  {dropDownOptions.map((data) => {
                    return (
                      <Box
                        className={clsx(
                          classes.dropDownOptions,
                          data == filter ? classes.selectedDropDownOptions : ""
                        )}
                        key={data}
                        onClick={() => handleFilter(data)}
                      >
                        {data}
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Typography>
            <Typography className={classes.graphScale}>
              <span>COG</span> <span className={classes.cogScale}>O</span>
            </Typography>
            <Typography className={classes.graphScale}>
              <span>EURO</span> <span className={classes.euroScale}>O</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.chartContainer}>
        <ResponsiveContainer width={"100%"} aspect={aspect}>
          <LineChart data={data || []}>
            <XAxis dataKey={"xVal"} interval={"preserveStartEnd"} />
            {/* <YAxis /> */}
            <Line type="monotone" dataKey="cog" stroke="#359DB6" />
            <Line type="monotone" dataKey="euro" stroke="#FF7300" />
          </LineChart>
        </ResponsiveContainer>
        <Box className={classes.filterName}>{filter}</Box>
      </Box>
    </Box>
  );
};
Chart.defaultProps = {
  aspect: 2,
};
export default Chart;
