import {
  Grid,
  IconButton,
  List,
  Modal,
  Paper,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import NotificationBox from "../NotificationBox";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  notificationModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "55%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "fit-content",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    height: 80,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 28,
    textAlign: "left",
  },
  body: {
    backgroundColor: "#2E2D2D",
  },
}));
const NotificationModal = (props) => {
  const { open, handleClose, title, allNotifications, today } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          component={Paper}
          item
          container
          xs={10}
          className={classes.notificationModal}
        >
          <Grid item xs={12} className={classes.heading}>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton id="closeIconButton" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.body}>
            <List
              style={{
                height: "75vh",
                overflowY: "scroll",
                // position: "relative",
              }}
            >
              {allNotifications &&
                allNotifications?.map((item) => {
                  let date = moment(new Date(`${item?.created_at}`)).format(
                    "DD-MM-YYYY"
                  );
                  let time =
                    new Date(`${item?.created_at}`).getHours() +
                    ":" +
                    new Date(`${item?.created_at}`).getMinutes();
                  return (
                    <NotificationBox
                      date={date}
                      time={time}
                      message={{ ...item }}
                      today={today}
                    />
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default NotificationModal;
