import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Modal,
  MenuItem,
  Select,
  Paper,
  Collapse,
  List,
  ListItem,
  // Card,
  // CardContent,
  Checkbox,
  ListItemText,
  IconButton,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FormControl,
  Card,
  CardContent,
  TableContainer,
} from "@material-ui/core";
import { useTheme } from "@mui/material";

import BuyStockModal from "./BuyStockModal";
import * as actionCreator from "../../../store/action/index";
import { connect } from "react-redux";
import { CalculatePL, stringAvatar } from "../../../utils/constant";
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PaginationComponent from "../../pagination/PaginationComponent";

import Spinner from "../../common/Spinner/Spinner";
import StockOrderBook from "./OrderBook";
import {
  formatPrice,
  priceFormatter,
} from "../../../utils/numberFormater/priceFormater";
import { CancelOutlined, CloseOutlined } from "@material-ui/icons";
import moment from "moment";
import Delete_Stock from "../../../asset/images/stocks/DeleteStock.svg";
import OTPInput from "react-otp-input";
import StockTransactionList from "./StockTransactionList";
import NumberWithTooltip from "../../../utils/numberFormater/NumberWithTooltip";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    textTransform: "none",
    minWidth: "auto",
    color: "#359DB6",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  icon: {
    fill: "#359DB6",
  },
  icon1: {
    fill: "#359DB6",
    fontSize: "2rem",
  },
  cardBox: {
    outline: "none",
    padding: "10px 30px",
    margin: "1% 0%",
    // width: "100%",
    boxShadow: localStorage?.getItem("dark")
      ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
      : "none",
    border: localStorage?.getItem("dark")
      ? "1px solid #359DB6 "
      : "1px solid #6993A4",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%", padding: "10px 12px" },
  },
  portfolioCardBox: {
    padding: "10px 30px",
    margin: "8px 2px",
    width: "90%",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    // border: localStorage?.getItem("dark")
    //   ? "1px solid #359DB6 "
    //   : "1px solid #C9E5EC",
    // borderRadius: 0,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  BuyButton: {
    backgroundColor: "#359DB6",
    textAlign: "center",
    margin: "6px 0px",
    borderRadius: "25px",
    width: "55%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "20%",
    },
  },
  logoGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  textGrid: {
    paddingLeft: "20px",
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },
  subText: {
    display: "flex",
    margin: "0",
    overflowWrap: "anywhere",
    paddingRight: "10px",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
  },
  price: {
    color: localStorage?.getItem("dark") ? "#359DB6" : "#C9E5EC",
  },
  title: {
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  listedText: {
    color: "#359DB6",
    fontSize: "0.8rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  viewButton: {
    fontSize: "0.8rem",
    // textAlign: "left",
    color: "#359DB6",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
      textAlign: "center",
    },
  },
  avatarStyle: {
    backgroundColor: "#C9E5EC !important",
    [`&.MuiAvatar-root`]: {
      fontSize: "0.8rem",
    },
  },
  select: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  PortfolioButtons: {
    backgroundColor: "#359DB6",
    textAlign: "center",
    borderRadius: "25px",
    width: "30%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.4rem",
    },
  },
  multiSelect: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    overflow: "auto",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    // border: "2px solid #1B5563",
    padding: "6px 20px",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      overflow: "auto",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      padding: 0,
    },
  },
  StockNameAndMenu: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
  },
  CollapsedSection: {
    marginTop: "10px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  PriceData: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  transacitonModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "50vw",
    width: "90vw",
    maxHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  ModalPaper: {
    backgroundColor: "#2a2a2a",
    color: "white",
    width: "70%",
    height: "94%",
    overflowY: "scroll",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  transparentButton: {
    background: "transparent",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  PinSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    color: localStorage.getItem("dark") ? "#000" : "#fff",
    backgroundColor: localStorage.getItem("dark") ? "#fff" : "#000",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  PinModalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "20vw",
    height: "50vh",
    width: "80vw",
    margin: "10% 12%",
    inset: 132,
    background: localStorage.getItem("dark") ? "#ffff" : "#1a1a1a",
    border: localStorage.getItem("dark")
      ? "1px solid #0000"
      : "1px solid #1a1a1a",
    // position: "relative",
    right: 20,
    border: "1px solid green",

    [theme.breakpoints.down("sm")]: {
      right: "0",
      width: "90vw",
      height: "56vh",
    },
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark")
      ? "#cdcdcd"
      : "rgb(31, 31, 31)",
    height: "13vh",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  verticalDivider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  companyInfoButton: {
    backgroundColor: "transparent",
    textAlign: "center",
    // margin: "6px 0px",
    // borderRadius: "25px",
    border: "none",
    width: "100%",
    fontSize: "0.7rem",
    fontWeight: "bold",
    color: "#359DB6",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.4rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  titleForCurrency: {
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    display: "flex",
    gap: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
      gap: 0,
      flexDirection: "column",
    },
  },
}));

const StockCard = (props) => {
  const {
    stockDetails,
    tab,
    handleStocks,
    stockList,
    data,
    stockDetailsPortfolio,
    singleUserStockList,
    allStockDataCount,
    paginationCB,
    currPage,
    profile,
    stockCategory,
    getAllStocksEngine,
    storeSelectedStockCategory,
    selectedStockCategory,
    onSelect,
    getStockTxnEngineUser,
    stockTransactionDataUser,
    selectedStockId,
    transactionList,
    getInvestmentDetail,
    investmentData,
  } = props;

  const [currencyType, setCurrencyType] = useState("COG100");
  const [totalAmounts, setTotalAmounts] = useState({});
  const [currentPage, setCurrentPage] = useState(currPage);
  const [selectedStocks, setSelectedStocks] = useState(selectedStockCategory);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectTransaction, setSelectTransaction] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const dataPerPage = 10;
  const totalPage = Math.ceil(allStockDataCount / dataPerPage);
  const [isSelected, setIsSelected] = useState(false);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [security, setSecurity] = React.useState("");
  const [modalData, setModalData] = React.useState(null);
  const [disclamerScreen, setDisclamerScreen] = React.useState(true);
  const [selectedStockIdTransaction, setSelectedStockId] = useState(null);
  const [openPLTransaction, setOpenPLTransaction] = useState(null);

  useEffect(() => {
    if (singleUserStockList) {
      const newTotalAmounts = {};
      singleUserStockList.forEach((item) => {
        const selectedPrice = item.currentPrice.find(
          (price) => price.currencyName === currencyType
        );
        if (selectedPrice) {
          newTotalAmounts[item.stockId] =
            selectedPrice.currentPrice * item.quantity;
        }
      });
      setTotalAmounts(newTotalAmounts);
    }
  }, [singleUserStockList, currencyType]);

  const handlePagData = (val) => {
    paginationCB(dataPerPage, val);
  };

  const viewDetails = (name) => {
    window.open("/stocks/" + name);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
        marginTop: "30px",
        fontSize: "1rem",
        width: "auto",

        // color: "#fff",
      },
    },
  };

  const MultiSelectMenuProps = {
    PaperProps: {
      style: {
        backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
        marginTop: "4rem",
        fontSize: "1rem",
        width: "auto",
        maxHeight: 300,

        // color: "#fff",
      },
    },
  };

  const handleCurrencyChange = (event) => {
    setCurrencyType(event.target.value);
  };

  const handleCurrChange = (item, event) => {
    setCurrencyType(event.target.value);
    getAmount(item, event.target.value);
  };

  const getAmount = (val, currType) => {
    const selectedItem = val.currentPrice.find(
      (item) => item.currencyName === currType
    );

    if (selectedItem) {
      setTotalAmounts((prevState) => ({
        ...prevState,
        [val.stockId]: selectedItem.currentPrice * val.quantity,
      }));
    }
  };

  useEffect(() => {
    if (tab == "portfolio" && stockDetails) {
      setSelectedCurrency(stockDetails?.currentPrice[4].currencyName);
    } else {
      return;
    }
  }, []);

  const getCurrentPrice = (stockDetails) => {
    if (stockDetails?.currentPrice !== undefined && tab == "portfolio") {
      const selected = stockDetails?.currentPrice.find(
        (price) => price.currencyName === selectedCurrency
      );
      return selected ? selected.currentPrice : 0;
    } else {
      const priceObj = stockDetails?.stockPrice.find(
        (price) => price.currencyName === currencyType
      );
      let obj = priceObj ? priceObj.currentPrice : "N/A";
      return obj.toString();
    }
  };

  const handleStockChange = (event) => {
    let updatedCategory = [];
    if (selectedStocks?.length == 0) {
      setSelectedStocks([...event.target.value]);
      updatedCategory = [...event.target.value];
    } else {
      setSelectedStocks(event.target.value);
      updatedCategory = event.target.value;
    }

    filterCategory(updatedCategory);
  };

  const filterCategory = (data) => {
    storeSelectedStockCategory({ data: data });
    getAllStocksEngine({
      limit: 10,
      page: 0,
      category: data,
    });
  };

  const handleTransactionForStock = (id) => {
    setSelectTransaction(true);
    setSelectedStockId(id);
    getStockTxnEngineUser({
      stockId: id,
      limit: dataPerPage,
      page: currentPage,
    });
  };

  const closeTransactionsModal = (field) => {
    if (field == "PL") {
      setOpenPLTransaction(false);
    } else {
      setSelectTransaction(false);
    }
  };

  const handleSelect = () => {
    setIsSelected(!isSelected);
  };

  const handleStockDeletion = (data) => {
    const modalData = {
      stock_name: data?.stockName,
      quantity: data?.quantity,
      executed_quantity: data?.executedQuantity,
      price_limit: data?.stockPrice,
      wallet: data?.walletName,
      buyModal: data?.isBuy,
    };
    setModalData(modalData);
    setModelOpen(true);
  };

  const closeModal = () => {
    setModelOpen(false);
  };

  const handleConfirm = () => {
    setDisclamerScreen(false);
  };

  const handleCancel = () => {
    closeModal();
  };
  // const plPercentage = CalculatePL(
  //   stockDetails?.avgInvested,
  //   priceFormatter(getCurrentPrice(stockDetails)),
  //   stockDetails?.quantity
  // );

  const handleDelete = (id) => {
    const updatedStocks = selectedStocks.filter(
      (selectedId) => selectedId !== id
    );
    setSelectedStocks(updatedStocks);
    storeSelectedStockCategory({ data: updatedStocks });
    getAllStocksEngine({
      limit: 10,
      page: 0,
      category: updatedStocks,
    });
  };

  let ValueOnHand = 0;
  const getValueOFOnHand = (mp, tp, ts) => {
    return (ValueOnHand = mp * (tp - ts));
  };

  let totalProfit = 0;
  const calculateProfit = (
    valueOnHand,
    totalSoldValue,
    totalPurchasedValue
  ) => {
    return (totalProfit = valueOnHand + (totalSoldValue - totalPurchasedValue));
  };

  const [localInvestmentData, setLocalInvestmentData] = useState(null);

  useEffect(() => {
    if (investmentData) {
      setLocalInvestmentData(investmentData);
    }
  }, [investmentData]);

  const getMarketPrice = (stockDetails) => {
    const priceObj = stockDetails?.currentPrice.find(
      (price) => price.currencyName === currencyType
    );
    return priceObj ? priceObj.currentPrice : "N/A";
  };

  const calculatePLPercentage = () => {
    if (investmentData) {
      const totalProfit =
        getValueOFOnHand(
          getMarketPrice(stockDetails),
          investmentData?.totalPurchased,
          investmentData?.totalSold
        ) +
        investmentData?.totalSoldValue -
        investmentData?.totalPurchasedValue;
      return (totalProfit * 100) / investmentData?.totalPurchasedValue || 0;
    }
    return 0;
  };

  const color = calculatePLPercentage() >= 0 ? "#76CEAB" : "#C49C39";

  const profitColor = color;
  return (
    <>
      {tab === "portfolio" && false ? (
        <></>
      ) : (
        <>
          <Grid item container>
            <Grid item container xs={12}>
              {selectedStocks &&
                selectedStocks?.map((id) => {
                  const foundStock = stockCategory.find(
                    (stock) => stock.id === id
                  );

                  return (
                    <Chip
                      label={foundStock?.name}
                      onDelete={() => handleDelete(foundStock?.id)}
                      variant="outlined"
                      color="#13677B"
                      style={{
                        marginTop: "1rem",
                        borderColor: "#359DB6",
                        fontSize: "1rem",
                        marginLeft: "1rem",
                      }}
                    />
                  );
                })}
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                display: "flex",
                padding: "10px 30px 10px",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                {/* <Grid item> */}
                <Typography className={classes.title}>
                  Stock Category
                </Typography>
                {/* </Grid> */}

                <Select
                  disableUnderline
                  multiple
                  displayEmpty
                  IconComponent={ArrowDropDownIcon}
                  value={selectedStocks}
                  onChange={handleStockChange}
                  renderValue={(selected) => {
                    return;
                  }}
                  MenuProps={MultiSelectMenuProps}
                  id="stockTypeDropdown"
                  classes={{
                    root: classes.multiSelect,
                    icon: classes.icon1,
                    title: classes.title,
                  }}
                >
                  {stockCategory?.map((stock) => {
                    return (
                      <MenuItem key={stock.id} value={stock.id}>
                        <Checkbox
                          checked={
                            selectedStocks &&
                            selectedStocks?.indexOf(stock.id) > -1
                          }
                        />
                        <ListItemText primary={stock.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={7} container direction="column">
                <Typography
                  className={classes.title}
                  style={{ textAlign: "center" }}
                >
                  Price
                </Typography>
                <Grid item style={{ textAlign: "center" }}>
                  <Select
                    disableUnderline
                    value={currencyType}
                    onChange={handleCurrencyChange}
                    IconComponent={ArrowDropDownIcon}
                    MenuProps={MenuProps}
                    id="paymentTypeDropdown"
                    classes={{
                      root: classes.select,
                      icon: classes.icon,
                      title: classes.title,
                    }}
                  >
                    <MenuItem
                      value="COG100"
                      id="cog100"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          currencyType == "COG100"
                            ? "#1B5563"
                            : theme.palette.background.default,
                        color: currencyType === "COG100" ? "#fff" : "#212121",
                      }}
                    >
                      COG 100%
                    </MenuItem>
                    <MenuItem
                      value="CB25CO75"
                      id="cog75"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          currencyType == "CB25CO75"
                            ? "#1B5563"
                            : theme.palette.background.default,
                        color: currencyType === "CB25CO75" ? "#fff" : "#212121",
                      }}
                    >
                      Cog 75%,CBC 25%
                    </MenuItem>
                    <MenuItem
                      value="CB50CO50"
                      id="CBC50"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          currencyType == "CB50CO50"
                            ? "#1B5563"
                            : theme.palette.background.default,
                        color: currencyType === "CB50CO50" ? "#fff" : "#212121",
                      }}
                    >
                      Cog 50%,CBC 50%
                    </MenuItem>
                    <MenuItem
                      value="CB75CO25"
                      id="cog25"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          currencyType == "CB75CO25"
                            ? "#1B5563"
                            : theme.palette.background.default,
                        color: currencyType === "CB75CO25" ? "#fff" : "#212121",
                      }}
                    >
                      Cog 25%,CBC 75%
                    </MenuItem>
                    <MenuItem
                      value="CBC100"
                      id="cbc100"
                      style={{
                        fontSize: "12px",
                        backgroundColor:
                          currencyType == "CBC100"
                            ? "#1B5563"
                            : theme.palette.background.default,
                        color: currencyType === "CBC100" ? "#fff" : "#212121",
                      }}
                    >
                      CBC 100%
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={2} className={classes.title}>
                <Typography style={{ textAlign: "center" }}>Action</Typography>
              </Grid>
            </Grid>

            {stockList?.length ? (
              <Grid container>
                {stockList?.map((stockDetails) => {
                  return (
                    <Grid
                      // component="button"
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        // padding: "2%",
                        justifyContent: "center",
                      }}
                      className={classes.cardBox}
                    >
                      <Grid item xs={4} sm={3} className={classes.title}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_SPACE_KEY +
                              "/" +
                              stockDetails.stockLogo
                            }
                            alt={`logo`}
                            style={{
                              marginRight: "10px",
                              width: "40px",
                              height: "40px",
                            }}
                          />
                          <Typography className={classes.subText}>
                            {stockDetails?.stockName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={4}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          className={classes.titleForCurrency}
                          style={{ textAlign: "center" }}
                        >
                          <span style={{ color: "#359DB6" }}>
                            {currencyType}
                          </span>{" "}
                          <NumberWithTooltip
                            value={priceFormatter(
                              getCurrentPrice(stockDetails)
                            )}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          className={classes.companyInfoButton}
                          onClick={() => viewDetails(stockDetails?.stockName)}
                          style={{ cursor: "default", textDecoration: "none" }}
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            View Firm Profile
                          </span>
                        </Button>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        <Button
                          disabled={!profile?.status?.is_cogito_stocks_profile}
                          className={classes.BuyButton}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleStocks({
                              stockDetails: stockDetails,
                              currencyType: currencyType,
                            });
                          }}
                        >
                          Buy
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                item
                container
                xs={12}
                style={{
                  padding: "10px",
                  margin: "2% 0%",
                  boxShadow: localStorage?.getItem("dark")
                    ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
                    : "none",
                  backgroundColor: localStorage?.getItem("dark")
                    ? "#fff"
                    : "#000",
                  borderRadius: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ padding: "40px" }}>
                  No Stocks available
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box
            display="flex"
            direction="row"
            justifyContent="center"
            style={{ marginTop: "1rem" }}
          >
            <PaginationComponent
              page={currentPage + 1}
              pageCount={totalPage}
              onChange={(e, v) => {
                setCurrentPage(v - 1);
                handlePagData(v - 1);
              }}
            />
          </Box>
        </>
      )}
      {selectTransaction ? (
        <Modal
          open={"transactions"}
          onClose={() => closeTransactionsModal("transaction")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{}}
          className={classes.transacitonModal}
        >
          <Paper style={{}} className={classes.ModalPaper}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 20px",
                }}
              >
                <Grid item xs={12}>
                  {/* <Typography variant="h6">
                    {stockDetails?.stockName}
                  </Typography> */}
                </Grid>

                <IconButton
                  onClick={() => closeTransactionsModal("transaction")}
                  style={{ color: "white" }}
                >
                  <CloseOutlined />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0 20px",
                }}
              >
                <StockTransactionList
                  stockId={selectedStockIdTransaction}
                  location="Portfolio"
                />
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      ) : null}{" "}
      {modelOpen && (
        <Modal
          open={modelOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className={classes.PinModalContainer}
        >
          <>
            <Grid item className={classes.modalHeading}>
              <Typography style={{ paddingLeft: "20px", fontSize: "1.6rem" }}>
                Order Details
              </Typography>
              <IconButton id="close-buy-stocks" onClick={closeModal}>
                <CancelOutlined style={{ fontSize: "2.5rem" }} />
              </IconButton>
            </Grid>
            <Grid item className={classes.PinSectionContainer}>
              <Grid
                item
                xs={12}
                sm={5}
                direction="column"
                justifyContent="flex-start"
                container
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "2rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Stock Name: &nbsp;{modalData?.stock_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Quantity: &nbsp;{modalData?.quantity}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Executed Quantity: &nbsp;
                    {modalData?.executed_quantity
                      ? modalData.executed_quantity
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Price Limit: &nbsp;{modalData?.price_limit}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Wallet: &nbsp;{modalData?.wallet}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={1}
                direction="column"
                container
                className={classes.verticalDivider}
              >
                <Divider
                  style={{
                    background: localStorage.getItem("dark")
                      ? "#ccc"
                      : "#808080",
                  }}
                  orientation="vertical"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                direction="column"
                container
                style={{
                  display: "flex",
                  height: "100%",
                }}
              >
                {disclamerScreen && modalData?.buyModal ? (
                  <Typography style={{ fontSize: "1rem", padding: "8% 5% 0" }}>
                    If cancelled, you will get €{" "}
                    {(modalData?.quantity - modalData?.executed_quantity) *
                      modalData?.price_limit}{" "}
                    into your {modalData?.wallet} wallet as refund.
                  </Typography>
                ) : null}

                {disclamerScreen && !modalData?.buyModal ? (
                  <Typography style={{ fontSize: "1rem", padding: "5% 5% 0" }}>
                    If cancelled, you will get €{" "}
                    {modalData?.quantity - modalData?.executed_quantity} stocks
                    back as refund.
                  </Typography>
                ) : null}

                {!disclamerScreen && modalData?.buyModal && (
                  <>
                    <Typography>PIN</Typography>
                    <Typography>Confirm Your Transaction</Typography>
                    <FormControl>
                      <OTPInput
                        numInputs={6}
                        renderSeparator={<span style={{ margin: "2%" }}></span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className={classes.otpBox}
                            autoComplete="new-password"
                          />
                        )}
                        shouldAutoFocus
                        isInputNum={true}
                        inputType="password"
                        value={security}
                        onChange={setSecurity}
                        inputStyle={{
                          border: "1px solid transparent",
                          borderRadius: "8px",
                          color: localStorage.getItem("dark")
                            ? "black"
                            : "#DCDCDC",
                          fontWeight: "400",
                          backgroundColor: localStorage.getItem("dark")
                            ? "#c6c6c6"
                            : "#2e2e2e",
                        }}
                      />
                    </FormControl>
                  </>
                )}
                {!disclamerScreen &&
                  !modalData?.buyModal &&
                  console.log("otp for sell modal")}

                {disclamerScreen ? (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleConfirm();
                      }}
                      style={{
                        width: "30%",
                        height: "90%",
                        backgroundColor: "#505050",
                        color: "#fff",
                      }}
                    >
                      Confirm
                    </Button>

                    <Button
                      onClick={() => {
                        handleCancel();
                      }}
                      style={{
                        width: "30%",
                        height: "90%",
                        backgroundColor: "#ccc",
                        marginLeft: "0.5rem",
                        color: "#000",
                      }}
                    >
                      cancel
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleConfirm();
                      }}
                      // style={{
                      //   width: "30%",
                      //   height: "90%",
                      //   backgroundColor: "#505050",
                      //   color: "#fff",
                      // }}
                      className={classes.PayButton}
                    >
                      Confirm
                    </Button>
                  </Grid>
                )}

                {/* <Typography>PIN</Typography>
                <Typography>Confirm Your Transaction</Typography> */}

                {/* <OTPInput
                  numInputs={6}
                  renderSeparator={<span style={{ margin: "2%" }}></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className={classes.otpBox}
                      autoComplete="new-password"
                    />
                  )}
                  shouldAutoFocus
                  isInputNum={true}
                  inputType="password"
                  value={security}
                  onChange={setSecurity}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    color: localStorage.getItem("dark") ? "black" : "#DCDCDC",
                    fontWeight: "400",
                    backgroundColor: localStorage.getItem("dark")
                      ? "#c6c6c6"
                      : "#2e2e2e",
                  }}
                /> */}

                {/* <Button
                  style={{ width: "30%" }}
                  className={classes.PayButton}
                  disabled={security?.length == 6 ? false : true}
                >
                  Confirm
                </Button> */}
              </Grid>
            </Grid>
          </>
        </Modal>
      )}
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    singleUserStockList: userReducer.singleUserStock,
    singleUserStockCount: userReducer.singleUserStockCount,
    allStocksEngineList: userReducer.allStocksEngineList,
    allStockDataCount: userReducer.allStockDataCount,
    profile: userReducer.profile,
    stockCategory: userReducer.stockCategory,
    stockCategoryLoading: userReducer.stockCategoryLoading,
    selectedStockCategory: userReducer.selectedStockCategory,
    stockTransactionDataUser: userReducer.stockTransactionData,
    transactionList: userReducer.loadings.transactionList,
    investmentData: userReducer.investmentData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllStocksEngine: (data) =>
      dispatch(actionCreator.getAllStocksEngine(data)),
    storeSelectedStockCategory: (data) =>
      dispatch(actionCreator.storeSelectedStockCategory(data)),
    getStockTxnEngineUser: (data) =>
      dispatch(actionCreator.getStockTxnEngine(data)),
    getStockCategory: (data) => dispatch(actionCreator.getStockCategory(data)),
    getInvestmentDetail: (data) =>
      dispatch(actionCreator.getInvestmentDetail(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StockCard);
