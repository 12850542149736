import React, { useEffect, useState } from "react";
import email_verified from "../../asset/images/email_verified.svg";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as actionCreator from "../../store/action/index";
import {
	updateObject,
	updateObjectProperty,
	setWithExpiry,
	getWithExpiry,
} from "../../asset/utility";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: "#359DB6",
		},
		"& .MuiInputBase-root": {
			color: "#000",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#359Db6",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "#359Db6",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#359Db6",
			},
		},
	},

	input: {
		color: "grey",
	},

	inputText: {
		color: "#000",
	},

	buttonContainer: {
		fontSize: 18,
		margin: "auto",
		height: 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
		"&:disabled": {
			background: "#9ACEDB",
			color: "#919191",
		},
	},
	img: {
		height: "30vh",
		top: 0,
	},
}));

const CheckYourEmailPage = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { errorMessage, successMessage, confirmOTP, loading, regUser, Signup,ResendOTP } =
		props;
	const [onSend, setOnSend] = useState({
		email: localStorage.getItem("email"),
		otp: "",
	});
	const [user	, setUser] = useState({
		email: localStorage.getItem("email"),
		ref: getWithExpiry("ref") || null,
	});

	const {initialMinute = 4,initialSeconds =59} = props;

    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);

    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });

	// const [counter,setCounter]=useState(localStorage.getItem("counter")||60);

	// useEffect(() => {
	// 	counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	// 	localStorage.setItem("counter",counter)
	// }, [counter]);

	const handleSubmit = (e) => {
		e.preventDefault();
		localStorage.setItem("OTP", onSend.otp);
		confirmOTP(onSend);
	};

	useEffect(() => {
		if (!onSend.email) {
			window.location = "/signup";
		}
		if (successMessage === "OTP verified") {
			history.push("/register");
			// localStorage.removeItem("OTP");
			// localStorage.removeItem("email")
		}
	}, [successMessage]);

	const handleResend = () => {
		Signup(user);
		// ResendOTP(user);
		setMinutes(initialMinute);
		setSeconds(initialSeconds);
		// localStorage.removeItem("counter");
	};

	const onInputValueChanged = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setOnSend(updateObjectProperty(onSend, name, value));
	};
	return (
		<Grid container style={{ background: "#fff" }}>
			<Grid
				item
				xs={12}
				style={{
					height: "95vh",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img className={classes.img} src={email_verified} />
				<h1 style={{ color: "green" }}>OTP sent on your email for verification.</h1>
				<form
					onSubmit={handleSubmit}
					style={{
						// height: isMobile ? "80vh" : "95vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						flexDirection: "column",
						color: "black",
					}}
				>
					<Grid
						container
						style={{
							display: "flex",
							justifyContent:"space-between",
						}}
					>
						<Grid item xs={7}>
							<TextField
								fullWidth
								variant="outlined"
								required
								id="otp"
								type="tel"
								name="otp"
								placeholder="Enter your 6 digit OTP here..."
								label="Enter OTP"
								autoFocus
								className={classes.root}
								InputLabelProps={{
									className: classes.input,
								}}
								InputProps={classes.inputText}
								onChange={onInputValueChanged}
							/>
						</Grid>
						<Grid item xs={4}>
								<Button
									fullWidth
									className={classes.buttonContainer}
									type="submit"
									variant="contained"
									disabled={onSend.otp.length === 6 ? false : true}
									// onClick={handleOnClick}
								>
									Verify
								</Button>
						</Grid>
						
						<Grid style={{align:"left"}}>
						{minutes > 0 && seconds >= 0?
								(
								<Typography variant="h5" style={{cursor:"pointer",color:"#075062"}}>Resend OTP in {minutes}:{seconds < 10 ?  `0${seconds}` : seconds} seconds</Typography>
								) : (
								<Button fullWidth style={{color:"#075062",fontWeight:"bolder",fontSize:"1rem"}} onClick={handleResend}>Resend</Button>
								)}
							
						</Grid>
					
						
					</Grid>
					<Grid container style={{ marginTop: "2rem" }}>
						<Grid item xs={8}>
							{errorMessage.length ? (
								<Grid item xs={12}>
									<Alert
										severity="error"
										icon={false}
										style={{ background: "#FCDDC4", color: "#FE7B10" }}
									>
										<strong>Error : {errorMessage}</strong>
									</Alert>
								</Grid>
							) : null}
							{successMessage.length ? (
								<Grid item xs={12}>
									<Alert
										severity="success"
										icon={false}
										style={{
											backgroundColor: "#B7D2D9",
											color: "#12586A",
										}}
									>
										<strong>Success :{successMessage}</strong>
									</Alert>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				</form>
			</Grid>
			<Grid item xs={12}></Grid>
		</Grid>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return {
		regUser: authReducer.regUser,
		loading: authReducer.loading,
		error: authReducer.error,
		errorMessage: authReducer.errorMessage,
		successMessage: authReducer.successMessage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		confirmOTP: (data) => dispatch(actionCreator.confirmOTP(data)),
		Signup: (data) => dispatch(actionCreator.Signup(data)),
		LogInAsync: (data) => dispatch(actionCreator.LogInAsync(data)),
		ResendOTP:(data)=>dispatch(actionCreator.ResendPasswordOTP(data)),
		notify: (message, varient) =>
			dispatch(actionCreator.notify(message, varient)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckYourEmailPage);
