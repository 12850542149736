import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import {
	Button,
	Typography,
	Backdrop,
	Link,
	Paper,
	IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import agreement from "../../asset/docs/agreement.pdf";
import privacyPolicy from "../../asset/docs/privacyPolicy.pdf";
import Switch from "../preloggedin/Switch";
import CheckBox from "../preloggedin/CheckBox";
import PdfViwer from "../common/pdfViewer/index";
import CustomButtons from "../preloggedin/CustomButtons";

const TermsPage = (props) => {
	const { activeStep, setActiveStep } = props;
	const [open, setOpen] = useState(false);
	const [switchOne, setSwitchOne] = useState(props.formData ? true : false);
	const [switchTwo, setSwitchTwo] = useState(props.formData ? true : false);
	const [switchThree, setSwitchThree] = useState(props.formData ? true : false);
	const [agreeCheckBox, setCheckBox] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [modalSelect, setModalSelect] = useState();

	const handleOpen = (value) => {
		setModalSelect(value);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setPageNumber(1);
	};

	const handleSwitchOne = (data) => {
		setSwitchOne(data);
	};
	const handleSwitchTwo = (data) => {
		setSwitchTwo(data);
	};
	const handleSwitchThree = (data) => {
		setSwitchThree(data);
	};

	const handleCheckBox = (data) => {
		setCheckBox(data);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleSubmit = () => {
		props.loadData(true);
		setActiveStep(activeStep + 1);
	};

	const useStyles = makeStyles((theme) => ({
		container: {
			height: "35vh",
			[theme.breakpoints.up(960)]: {
				height: "45vh",
			},
		},
		box: {
			display: "flex",
			alignItems: "center",
			marginLeft: !isMobile && "2vw",
		},
		closeModal: {
			cursor: "pointer",
			float: "right",
			marginTop: "5px",
		},
	}));

	const classes = useStyles();
	return (
		<>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					height: "60vh",
					// margin: "1vh 0 5vh 0",
				}}
			>
				<Grid item xs={10} sm={10} elevation={0}>
					<Grid
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
						className={classes.container}
					>
						<div
							style={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							<Grid item xs={12}>
								<Box className={classes.box}>
									<Switch turnOnSwitch={handleSwitchOne} checked={props.formData} />
									<Typography
										style={{
											color: "black",
											fontSize: isMobile ? 14 : 18,
											fontWeight: "700",
										}}
									>
										The Important Cogito
										<Link
											onClick={() => handleOpen("termsAndConditions")}
											style={{
												fontSize: isMobile ? 14 : 18,
												fontWeight: "700",
												textDecorationLine: "underline",
												color: "#FF7300",
												paddingLeft: isMobile ? 0 : 5,
												cursor: "pointer",
											}}
										>
											Terms and Conditions
										</Link>
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.box}>
									<Switch turnOnSwitch={handleSwitchTwo} checked={props.formData} />
									<Typography
										style={{
											color: "black",
											fontSize: isMobile ? 14 : 18,
											fontWeight: "700",
										}}
									>
										Here
										<Link
											onClick={() => handleOpen("privacyPolicy")}
											style={{
												fontSize: isMobile ? 14 : 18,
												fontWeight: "700",
												textDecorationLine: "underline",
												color: "#FF7300",
												paddingLeft: 5,
												cursor: "pointer",
												paddingRight: "4px",
											}}
										>
											Privacy Policy
										</Link>
										<span
											style={{
												color: "black",
												fontSize: isMobile ? 14 : 18,
												fontWeight: "700",
											}}
										>
											by Cogito to save the information
										</span>
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.box}>
									<Switch turnOnSwitch={handleSwitchThree} checked={props.formData} />
									<Typography
										style={{
											color: "black",
											fontSize: isMobile ? 14 : 18,
											fontWeight: "700",
										}}
									>
										I am taking the Cogito
										<Link
											onClick={() => handleOpen("oath")}
											style={{
												fontSize: isMobile ? 14 : 18,
												fontWeight: "700",
												textDecorationLine: "underline",
												color: "#FF7300",
												paddingLeft: 5,
												cursor: "pointer",
											}}
										>
											Declaration
										</Link>
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className={classes.box}>
									<CheckBox isChecked={handleCheckBox} />
								</Box>
							</Grid>
							<Modal
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								className={classes.root}
								open={open}
								onClose={handleClose}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500,
								}}
								style={{
									marginLeft: "30%",
									margin: "5vw",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Fade in={open}>
									<Grid
										container
										sm={12}
										md={6}
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{modalSelect === "termsAndConditions" ||
										modalSelect === "privacyPolicy" ? (
											<PdfViwer
												file={
													modalSelect === "termsAndConditions" ? agreement : privacyPolicy
												}
											/>
										) : null}

										{modalSelect === "oath" ? (
											<Box
												display="flex"
												width="100vw"
												height="100vh"
												alignItems="center"
												justifyContent="center"
											>
												<Paper>
													<IconButton onClick={handleClose} className={classes.closeModal}>
														<CloseIcon />
													</IconButton>
													<Typography
														style={{ padding: "2%" }}
													>{`From this time forward I declare myself as a digital citizen of the Principality of Cogito.`}</Typography>
												</Paper>
											</Box>
										) : null}
									</Grid>
								</Fade>
							</Modal>
						</div>
					</Grid>

					<Grid item container xs={12} justifyContent="center">
						<Box
							style={{
								display: "flex",
								justifyContent: "space-evenly",
								width: "80%",
							}}
						>
							<CustomButtons
								activeStep={activeStep}
								setActiveStep={setActiveStep}
								// allChecked={!formik.isSubmitting}
								allChecked={switchOne && switchTwo && switchThree && agreeCheckBox}
							/>
						</Box>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

export default TermsPage;
