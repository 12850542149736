import { number } from "prop-types";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
const subject = new Subject();
export const messageService = {
  sendMessage: (message) => {
    subject.next(message);
  },
  clearMessages: () => subject.next(),
  getMessages: () => subject.asObservable(),
  getPaymentMesssages: () =>
    subject.pipe(filter((message) => message.action == "payment_received")),
};
