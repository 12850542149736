import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Icon1 from "../../asset/images/RightSideBar/bankingbutton.svg";
import Icon2 from "../../asset/images/RightSideBar/chevronRight.svg";

//components
import * as actionCreator from "../../store/action/index";
import Cogflow from "./components/cogflow";
import Conversion from "./components/conversion";
import Chart from "./components/chart";
import CogInfo from "./components/coginfo";
import CogValueStocks from "./components/cogValueStocks/index";
//material
import { Box, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import SideBarCurrencyList from "../stock/SideBarCurrencyList";
import { messageService } from "../../sockets/messageService";
// import {
//   isCurrencyAvailable,
//   isInvestmentAvailable,
// } from "../../utils/stocksMethods/curriencyCheck";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    width: "100%",
    height: "1.5rem",
    opacity: "0.7",
    background: localStorage.getItem("dark") && "#D1D1D1",
    [theme.breakpoints.between("sm", "lg")]: {
      height: "2.5rem",
    },
    // animation: "skeleton-loading 1s linear infinite alternate",
  },

  // "@keyframes skeleton-loading": {
  //   "0%": {
  //     background: localStorage.getItem("dark")
  //       ? "#D1D1D1"
  //       : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 20%)",
  //   },

  //   "100%": {
  //     background: localStorage.getItem("dark")
  //       ? theme.palette.background.default
  //       : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
  //   },
  // },

  rightSidebar: {
    display: "flex",
    flexDirection: "row",
    height: "78.5vh",
    overflow: "scroll",
    padding: theme.spacing(3),
    // maxHeight: "78.5vh",
    // height: "fit-content",
    // borderRadius: 23,
    borderTopLeftRadius: 23,
    borderBottomLeftRadius: 23,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow:
      "-4px -4px 10px rgba(58, 58, 58, 0.7), 4px 4px 10px rgba(0, 0, 0, 0.7)",

    [theme.breakpoints.down(960)]: {
      borderBottomLeftRadius: 23,
    },

    // [theme.breakpoints.down(760)]: {
    //   width: "60%",
    //   position: "relative",
    //   top: "2rem",
    //   left: "20%",
    //   marginBottom: "5rem",
    //   borderTopLeftRadius: 23,
    //   borderBottomLeftRadius: 23,
    //   borderTopRightRadius: 23,
    //   borderBottomRightRadius: 23,
    // },
  },
  coginfoContainer: {
    borderRadius: 11,
    height: 75,
    minHeight: "100%",
    marginBottom: 10,
    // border: "2px solid black",
    background: localStorage.getItem("dark")
      ? theme.palette.background.default
      : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
    // boxShadow: localStorage.getItem("dark")
    //   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
    //   : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
    // background: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
    // padding: "12px 17px 8px 24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap,",
  },
  statsContainer: {
    height: "100%",
    overflowY: "scroll",
    marginRight: "-7px",
    // [theme.breakpoints.up(1200)]: {
    // 	height: "auto",
    // },
  },
  boxCenter: {
    display: "flex",
    justifyContent: "center",
  },
  list: {
    width: 300,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      height: "auto",
      overflowY: "scroll",
    },
  },
  fullList: {
    width: "auto",
  },
  buttonDrawer: {
    position: "absolute",
    top: "15%",
    right: 0,
    zIndex: 1000,
    borderBottomLeftRadius: "50px",
    borderTopLeftRadius: "50px",
    height: "3.5rem",
    width: "2rem",
    backgroundColor: theme.palette.background.default,
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  drawerPaper: {
    margin: "2rem 0 2rem 0",
    height: "92.5%",
    width: 300,
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    padding: 16,
  },
  drawerPaperEs: {
    margin: "2rem 0 2rem 0",
    height: "92.5%",
    width: 330,
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    // padding: 16,
  },
  tabData: {
    height: "100%",
    overflowY: "scroll",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue(newValue + 2);
  };

  // useEffect(() => {
  //   let subscription = messageService
  //     .getPaymentMesssages()
  //     .subscribe((message) => {
  //       updateBalance({
  //         amount: message?.data?.amount,
  //         account: message?.data?.account_type,
  //       });
  //     });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // });

  const matches = useMediaQuery("(max-width:992px)");
  const {
    cogStats,
    cbcCirculation,
    accounts,
    getAccounts,
    assets,
    getAssetList,
    location,
    esWindow,
    updateBalance,
    getCogValueOfStock,
    cogValueStock,
    buyValueStock,
    sellValueStock,
    // updateBalance,
  } = props;
  const mobileWidth = "98%";
  const [overdraftAmount, setOverdraftAmount] = useState();
  const [euroOverdraftAmount, setEuroOverdraftAmount] = useState();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const routeData = {
    "/home": 0,
    "/banking": 1,
    "/profile": 0,
    "/business": 0,
    "/refer": 0,
    "/family": 0,
    "/uri": 0,
    "/Stocks": 2,
    "/qrcode": 0,
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "left" || anchor === "right",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid gray",
          paddingBottom: "5px",
        }}
      >
        Overview
      </Typography>

      {cogStats && (
        <div>
          {cogStats?.length && (
            <Box className={classes.boxCenter}>
              <Cogflow
                location="COG in Circulation"
                cogFlow={cogStats || []}
                width={mobileWidth}
              />
            </Box>
          )}
          {cbcCirculation && (
            <Cogflow
              location="CBC in Circulation"
              cogFlow={cbcCirculation || ""}
              width={mobileWidth}
            />
          )}
          {cogStats?.length && (
            <Box className={classes.boxCenter}>
              <Conversion euroFlow={cogStats || []} width={mobileWidth} />
            </Box>
          )}
          {cogStats?.length && (
            <Box className={classes.boxCenter}>
              <Chart graphData={cogStats} width={mobileWidth} aspect={3} />
            </Box>
          )}
        </div>
      )}

      <Typography
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid gray",
          paddingBottom: "5px",
        }}
      >
        Stocks Overview
      </Typography>
      {cogValueStock !== null && cogValueStock !== undefined && (
        <Box className={classes.boxCenter}>
          <CogValueStocks
            location="Total value of shares"
            cogValue={cogValueStock || 0}
            width={mobileWidth}
          />
        </Box>
      )}
      {buyValueStock !== null && buyValueStock !== undefined && (
        <Box className={classes.boxCenter}>
          <CogValueStocks
            location="Total value of buy orders"
            cogValue={buyValueStock || 0}
            width={mobileWidth}
          />
        </Box>
      )}
      {sellValueStock !== null && sellValueStock !== undefined && (
        <Box className={classes.boxCenter}>
          <CogValueStocks
            location="Total value of sell orders"
            cogValue={sellValueStock || 0}
            width={mobileWidth}
          />
        </Box>
      )}

      <Typography
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid gray",
          paddingBottom: "5px",
        }}
      >
        Account details
      </Typography>
      {accounts?.map((account, index) => {
        return (
          <Box className={classes.boxCenter}>
            <Box style={{ width: mobileWidth }}>
              <CogInfo
                key={index}
                title={account.account_type}
                price={
                  account.account_type === "Cash Backed Cog"
                    ? account.balance
                    : account.account_type === "Cog Transaction" ||
                      account.account_type === "Cog Investment"
                    ? account.account_type === "Cog Transaction" &&
                      overdraftAmount
                      ? `${(account.balance + overdraftAmount).toLocaleString(
                          "en-US"
                        )}C`
                      : `${account.balance.toLocaleString("en-US")}C`
                    : account.account_type === "Euro Transaction" &&
                      euroOverdraftAmount
                    ? `${(account.balance + euroOverdraftAmount).toLocaleString(
                        "en-US"
                      )}€`
                    : `${account.balance.toLocaleString("en-US")}€`
                }
              />
            </Box>
          </Box>
        );
      })}
      {overdraftAmount ? (
        <Box className={classes.boxCenter}>
          <Box style={{ width: mobileWidth }}>
            <CogInfo
              title={"Active Overdraft"}
              price={`${overdraftAmount.toLocaleString("en-US")}C`}
            />
          </Box>
        </Box>
      ) : null}
      {euroOverdraftAmount ? (
        <CogInfo
          title={"Active Euro Overdraft"}
          price={`${euroOverdraftAmount.toLocaleString("en-US")} €`}
        />
      ) : null}



      {/* <Typography
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid gray",
          paddingBottom: "5px",
        }}
      >
        Currency
      </Typography> */}
      {/* <SideBarCurrencyList /> */}

      {/* <Typography
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid gray",
          paddingBottom: "5px",
        }}
      >
        Investment
      </Typography> */}
      {/* <SideBarCurrencyList isInvestment={true} /> */}
    </div>
  );

  useEffect(() => {
    let subscription = messageService
      .getPaymentMesssages()
      .subscribe((message) => {
        updateBalance({
          amount: message?.data?.amount,
          account: message?.data?.account_type,
        });
      });
    return () => {
      subscription.unsubscribe();
    };
  });


  const boxList = (anchor) => {
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            height: "10%",
            width: "100%",
            // borderBottom: "2px solid gray",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF7300",
                flexWrap: "wrap",
              },
            }}
          >
            <Tab
              label="OverView"
              style={{
                color: localStorage.getItem("dark") ? "#000" : "#fff",
                fontWeight: "bold",
                // marginLeft: 10,
                fontSize: 12,
              }}
              wrapped
              {...a11yProps(0)}
            />
            <Tab
              label="Stocks OverView"
              style={{
                color: localStorage.getItem("dark") ? "#000" : "#fff",
                fontWeight: "bold",
                // marginLeft: 10,
                fontSize: 12,
              }}
              wrapped
              {...a11yProps(1)}
            /> 

            <Tab
              label="Account
              Details"
              style={{
                color: localStorage.getItem("dark") ? "#000" : "#fff",
                fontWeight: "bold",
                fontSize: 12,
              }}
              wrapped
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box style={{ height: "90%", width: "100%" }}>
          {/* overview */}
          <TabPanel value={value} index={0} className={classes.tabData}>
            <Grid>
              {!cogStats ? (
                [1, 2, 3, 4].map((item, index) => {
                  return (
                    <Grid
                      container
                      item
                      className={classes.coginfoContainer}
                      key={index}
                    >
                      <Grid>
                        <div className={classes.skeleton}></div>
                      </Grid>
                      <Grid>
                        <div className={classes.skeleton}></div>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid className={classes.statsContainer}>
                  {cogStats?.length && (
                    <Cogflow
                      location="COG in Circulation"
                      cogFlow={cogStats || []}
                    />
                  )}
                  {cbcCirculation && (
                    <Cogflow
                      location="CBC in Circulation"
                      cogFlow={cbcCirculation || ""}
                    />
                  )}
                  {cogStats?.length && (
                    <Conversion euroFlow={cogStats || []} width={"100%"} />
                  )}
                  {cogStats?.length && (
                    <Chart graphData={cogStats} width={"100%"} />
                  )}
                </Grid>
              )}
            </Grid>
          </TabPanel>
          {/* stock overview */}
          <TabPanel value={value} index={1} className={classes.tabData}>
            <Grid>
              {!cogValueStock ? (
                [1, 2, 3].map((item, index) => {
                  return (
                    <Grid
                      container
                      item
                      className={classes.coginfoContainer}
                      key={index}
                    >
                      <Grid>
                        <div className={classes.skeleton}></div>
                      </Grid>
                      <Grid>
                        <div className={classes.skeleton}></div>
                      </Grid>
                      <Grid>
                        <div className={classes.skeleton}></div>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid className={classes.statsContainer}>
                  {cogValueStock !== null && cogValueStock !== undefined && (
                    <CogValueStocks
                      location="Total value of shares"
                      cogValue={cogValueStock || 0}
                      width={mobileWidth}
                    />
                  )}
                  {buyValueStock !== null && buyValueStock !== undefined && (
                    <Box className={classes.boxCenter}>
                      <CogValueStocks
                        location="Total value of buy orders"
                        cogValue={buyValueStock || 0}
                        width={mobileWidth}
                      />
                    </Box>
                  )}
                  {sellValueStock !== null && sellValueStock !== undefined && (
                    <Box className={classes.boxCenter}>
                      <CogValueStocks
                        location="Total value of sell orders"
                        cogValue={sellValueStock || 0}
                        width={mobileWidth}
                      />
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </TabPanel>
          {/* currency */}
          {/* <TabPanel value={value} index={2} className={classes.tabData}>
            <SideBarCurrencyList />
          </TabPanel> */}
          {/* investment*/}
          {/* <TabPanel value={value} index={3} className={classes.tabData}>
            <SideBarCurrencyList isInvestment={true} />
          </TabPanel> */}

          {/* accounts */}
          <TabPanel value={value} index={2} className={classes.tabData}>
            <Grid container item xs={12} spacing={1}>
              {accounts === null
                ? [1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (
                      <Grid
                        container
                        item
                        className={classes.coginfoContainer}
                        key={index}
                      >
                        <Grid item xs={12} md={6}>
                          <div className={classes.skeleton}></div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className={classes.skeleton}></div>
                        </Grid>
                      </Grid>
                    );
                  })
                : accounts?.map((account, index) => {
                    return (
                      <CogInfo
                        key={index}
                        title={account.account_type}
                        price={
                          account.account_type === "Cash Backed Cog"
                            ? account.balance
                            : account.account_type === "Cog Transaction" ||
                              account.account_type === "Cog Investment"
                            ? account.account_type === "Cog Transaction" &&
                              overdraftAmount
                              ? `${(
                                  account.balance + overdraftAmount
                                ).toLocaleString("en-US")} C`
                              : `${account.balance.toLocaleString("en-US")} C`
                            : account.account_type === "Euro Transaction" &&
                              euroOverdraftAmount
                            ? `${(
                                account.balance + euroOverdraftAmount
                              ).toLocaleString("en-US")}€`
                            : `${account.balance.toLocaleString("en-US")} €`
                        }
                      />
                    );
                  })}
              {overdraftAmount ? (
                <CogInfo
                  title={"Active COG Overdraft"}
                  price={`${overdraftAmount.toLocaleString("en-US")} C`}
                />
              ) : null}
              {euroOverdraftAmount ? (
                <CogInfo
                  title={"Active Euro Overdraft"}
                  price={`${euroOverdraftAmount.toLocaleString("en-US")}€`}
                />
              ) : null}
            </Grid>
          </TabPanel>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    getCogValueOfStock();
  }, []);

  useEffect(() => {
    if (!accounts) {
      getAccounts();
    }
    accounts &&
      accounts.forEach((account, index) => {
        if (account.account_type === "Cog Transaction" && account.overdraft) {
          setOverdraftAmount(account.overdraft);
        } else if (
          account.account_type === "Euro Transaction" &&
          account.overdraft
        ) {
          setEuroOverdraftAmount(account.overdraft);
        }
      });
  }, [accounts]);

  {
    /* useEffect(() => {
    console.log(location);
    console.log(assets?.length);
    console.log(location && assets?.length < 1);
    if (location == "/Stocks" && (assets?.length < 1 || assets == undefined)) {
      setValue(0);
    } else {
      setValue(routeData[location]);
    }
  }, [location, assets]); */
  }

  return (
    <>
      {matches ? (
        //mobile view
        <>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
                className={classes.buttonDrawer}
                onClick={toggleDrawer(anchor, true)}
              >
                <img src={Icon2} alt="icon2" />
                <img src={Icon1} alt="icon1" />
              </Button>
              <SwipeableDrawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                PaperProps={{ elevation: 9 }}
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </>
      ) : (
        //web view
        <>
          {!matches && !esWindow ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  height: "10%",
                  width: "100%",
                  // borderBottom: "2px solid gray",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FF7300",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  <Tab
                    label="OverView"
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      fontWeight: "bold",
                      // marginLeft: 10,
                      fontSize: 12,
                    }}
                    wrapped
                    {...a11yProps(0)}
                  />

                  <Tab
                    label="Stocks OverView"
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      fontWeight: "bold",
                      // marginLeft: 10,
                      fontSize: 12,
                    }}
                    wrapped
                    {...a11yProps(1)}
                  />

                  <Tab
                    label="Account 
                  Details"
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    wrapped
                    {...a11yProps(2)}
                  />
                </Tabs>
                {/* ) : (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FF7300",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  <Tab
                    label="OverView"
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      fontWeight: "bold",
                      // marginLeft: 10,
                      fontSize: 12,
                    }}
                    wrapped
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Account 
                  Details"
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                    wrapped
                    {...a11yProps(1)}
                  />
                </Tabs>
              )} */}

                {/* <Tabs
                value={value-2}
                onChange={handleChange2}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FF7300",
                    flexWrap:'wrap'
                  },
                }}
              >
                
              </Tabs> */}
                {/* <Divider
                style={{
                  height: "2px",
                  width: "95%",
                  margin: "auto",
                  marginTop: "10px",
                }}
              /> */}
              </Box>

              <Box style={{ height: "90%", width: "100%" }}>
                {/* overview */}
                <TabPanel value={value} index={0} className={classes.tabData}>
                  <Grid>
                    {!cogStats ? (
                      [1, 2, 3, 4].map((item, index) => {
                        return (
                          <Grid
                            container
                            item
                            className={classes.coginfoContainer}
                            key={index}
                          >
                            <Grid>
                              <div className={classes.skeleton}></div>
                            </Grid>

                            <Grid>
                              <div className={classes.skeleton}></div>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid className={classes.statsContainer}>
                        {cogStats?.length && (
                          <Cogflow
                            location="COG in Circulation"
                            cogFlow={cogStats || []}
                          />
                        )}
                        {cbcCirculation && (
                          <Cogflow
                            location="CBC in Circulation"
                            cogFlow={cbcCirculation || ""}
                          />
                        )}
                        {cogStats?.length && (
                          <Conversion
                            euroFlow={cogStats || []}
                            width={"100%"}
                          />
                        )}
                        {cogStats?.length && (
                          <Chart graphData={cogStats} width={"100%"} />
                        )}
                      </Grid>
                    )}
                    {/* {cogStats && (
                  <Grid>
                    {cogStats?.length && (
                      <Cogflow location="COG in Circulation" cogFlow={cogStats || []} />
                    )}
                    {cbcCirculation && (
                      <Cogflow
                        location="CBC in Circulation"
                        cogFlow={cbcCirculation || ""}
                      />
                    )}
                    {cogStats?.length && (
                      <Conversion euroFlow={cogStats || []} width={"100%"} />
                    )}
                    {cogStats?.length && <Chart graphData={cogStats} width={"100%"} />}
                  </Grid>
                )} */}
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={1} className={classes.tabData}>
                  <Grid>
                    {!cogValueStock ? (
                      [1, 2, 3].map((item, index) => {
                        return (
                          <Grid
                            container
                            item
                            className={classes.coginfoContainer}
                            key={index}
                          >
                            <Grid>
                              <div className={classes.skeleton}></div>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid className={classes.statsContainer}>
                        {cogValueStock !== null && cogValueStock !== undefined && (
                          <CogValueStocks
                            location="Total value of shares"
                            cogValue={cogValueStock || 0}
                            width={mobileWidth}
                          />
                        )}
                        {buyValueStock !== null && buyValueStock !== undefined && (
                          <Box className={classes.boxCenter}>
                            <CogValueStocks
                              location="Total value of buy orders"
                              cogValue={buyValueStock || 0}
                              width={mobileWidth}
                            />
                          </Box>
                        )}
                        {sellValueStock !== null && sellValueStock !== undefined && (
                          <Box className={classes.boxCenter}>
                            <CogValueStocks
                              location="Total value of sell orders"
                              cogValue={sellValueStock || 0}
                              width={mobileWidth}
                            />
                          </Box>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
                {/* currencyList */}
                {/* <TabPanel value={value} index={2} className={classes.tabData}>
                  <SideBarCurrencyList />
                </TabPanel> */}

                {/* Investment */}
                {/* <TabPanel value={value} index={3} className={classes.tabData}>
                  <SideBarCurrencyList isInvestment={true} />
                </TabPanel> */}
                {/* accounts */}
                <TabPanel value={value} index={2} className={classes.tabData}>
                  <Grid container item xs={12} spacing={1}>
                    {accounts === null
                      ? [1, 2, 3, 4, 5, 6].map((item, index) => {
                          return (
                            <Grid
                              container
                              item
                              className={classes.coginfoContainer}
                              key={index}
                            >
                              <Grid item xs={12} md={6}>
                                <div className={classes.skeleton}></div>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <div className={classes.skeleton}></div>
                              </Grid>
                            </Grid>
                          );
                        })
                      : accounts?.map((account, index) => {
                          return (
                            <CogInfo
                              key={index}
                              title={account.account_type}
                              price={
                                account.account_type === "Cash Backed Cog"
                                  ? account.balance
                                  : account.account_type ===
                                      "Cog Transaction" ||
                                    account.account_type === "Cog Investment"
                                  ? account.account_type ===
                                      "Cog Transaction" && overdraftAmount
                                    ? `${(
                                        account.balance + overdraftAmount
                                      ).toLocaleString("en-US")} C`
                                    : `${account.balance.toLocaleString(
                                        "en-US"
                                      )} C`
                                  : account.account_type ===
                                      "Euro Transaction" && euroOverdraftAmount
                                  ? `${(
                                      account.balance + euroOverdraftAmount
                                    ).toLocaleString("en-US")} €`
                                  : `${account.balance.toLocaleString(
                                      "en-US"
                                    )} €`
                              }
                            />
                          );
                        })}
                    {overdraftAmount ? (
                      <CogInfo
                        title={"Active COG Overdraft"}
                        price={`${overdraftAmount.toLocaleString("en-US")} C`}
                      />
                    ) : null}
                    {euroOverdraftAmount ? (
                      <CogInfo
                        title={"Active Euro Overdraft"}
                        price={`${euroOverdraftAmount.toLocaleString(
                          "en-US"
                        )} €`}
                      />
                    ) : null}
                  </Grid>
                </TabPanel>
              </Box>
            </Box>
          ) : (
            <>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    className={classes.buttonDrawer}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <img src={Icon2} alt="icon2" />
                    <img src={Icon1} alt="icon1" />
                  </Button>
                  <SwipeableDrawer
                    classes={{
                      paper: classes.drawerPaperEs,
                    }}
                    PaperProps={{ elevation: 9 }}
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {boxList(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    accounts: userReducer.accounts,
    cogStats: userReducer.cogStats,
    cbcCirculation: userReducer.cbcCirculation,
    assets: userReducer.assets,
    esWindow: userReducer.esWindow,
    cogValueStock: userReducer.cogValueStock,
    buyValueStock: userReducer.buyValueStock,
    sellValueStock: userReducer.sellValueStock,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAccounts: () => dispatch(actionCreator.getAccounts()),
    getCogValueOfStock: () => dispatch(actionCreator.getCogValueOfStock()),
    // updateBalance: (data) => dispatch(actionCreator.updateBalance(data)),
    getAssetList: () => dispatch(actionCreator.getAssetList()),
    updateBalance: (data) => dispatch(actionCreator.updateBalance(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTabs);
