import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Sidebar from "../components/preloggedin/Sidebar";
import LinearStepper from "../components/Register/LinearStepper";
import LinkExpiredPage from "../components/Signup/linkExpiredPage";
import * as actionCreator from "../store/action/index";
import Spinner from "../components/common/Spinner/Spinner";
import { getWithExpiry, setWithExpiry } from "../asset/utility";
const theme = createTheme();

const Register = (props) => {
  const {
    regUser,
    confirmOTP,
    successMessage,
    errorMessage,
    notify,
    confirmToken,
  } = props;
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(getWithExpiry("regUser") || null);
  const [loadingUser, setLoadingUser] = useState(true);

  // validating token
  useEffect(() => {
    if (!window.location.pathname.includes("/staff")) {
      if (token) confirmToken({ token: token });
    }
  }, [token]);

  useEffect(() => {
    if (successMessage || errorMessage) {
      setLoading(false);
      if (successMessage) {
        notify(successMessage, "success");
      }
      if (errorMessage) {
        notify(errorMessage, "error");
      }
    } else if (user) {
      setLoading(false);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (regUser) {
      setUser(regUser);
      setLoadingUser(false);
    } else if (user === null && !token) {
      setLoadingUser(false);
    }
  }, [user, regUser, token]);

  useEffect(() => {
    if (!loadingUser && user === null) {
      window.location = "/login";
    }
  }, [loading, user]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: "100vh", background: "#fff" }}>
          <Sidebar />
          {/* {regUser ? <LinearStepper /> : <LinkExpiredPage />} */}
          {!loading ? (
            user ? (
              <LinearStepper />
            ) : (
              <LinkExpiredPage />
            )
          ) : (
            <Spinner />
          )}
        </Grid>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    regUser: authReducer.regUser,
    successMessage: authReducer.successMessage,
    errorMessage: authReducer.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmOTP: (data) => dispatch(actionCreator.confirmOTP(data)),
    confirmToken: (data) => dispatch(actionCreator.confirmToken(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
