import { combineReducers } from "redux";
import auth from "./authReducer";
import user from "./userReducer";
import notify from "./notifyReducer";

const rootReducer = combineReducers({
  authReducer: auth,
  userReducer: user,
  notifyReducer: notify,
});

export default rootReducer;
