import React from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  TextField,
  InputBase,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Add,
  AddCircleOutline,
  AddCircleOutlined,
  CancelOutlined,
  Remove,
} from "@material-ui/icons";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import OTPInput from "react-otp-input";
import { typeOf } from "react-is";
import Spinner from "../../common/Spinner/Spinner";
import Graph from "./Graph/Graph";
import LineChart from "./Graph/newGraph";
import { Select } from "@mui/material";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  selectedMenuItem: {
    backgroundColor: "#1B5563",
    color: "#fff",
  },

  modalContainer: {
    // height: "60vh",
    // width: "50%",
    // right: "10%",
    marginTop: "8rem",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    boxShadow: "",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      right: "0",
    },
    boxShadow: localStorage.getItem("dark")
      ? "-2px -2px 2px 0px rgba(255,255,255,1)"
      : " -2px -2px 5px 0px rgba(0,0,0,0.75)",
  },
  createAccountContainer: {
    height: "auto",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    position: "relative",
    right: "10%",
    boxShadow: localStorage.getItem("dark")
      ? "-2px -2px 2px 0px rgba(255,255,255,1)"
      : " -2px -2px 5px 0px rgba(0,0,0,0.75)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark")
      ? "#cdcdcd"
      : "rgb(31, 31, 31)",
    height: "10vh",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subText: {
    display: "flex",
    color: "#ACACAC",
    margin: "0",
  },
  priceBox: {
    height: "7vh",
    width: "100%",
    backgroundColor: "#C9E5EC",
    borderRadius: "50px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "2% 6%",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "2%",
      padding: "10px 20px",
    },
  },
  quantityField: {
    width: "100%",
    marginTop: "0.5rem",
    borderRadius: "5px",
    fontWeight: "bold",
    // [`& .MuiInputBase-input`]: {
    //   textAlign: "center",
    //   // color: "#C49C39",
    // },
    // [`& .MuiFormHelperText-root`]: {
    //   backgroundColor: "#1a1a1a",
    // },
    // [`& .MuiFormHelperText-marginDense`]: {
    //   color: "red",
    //   fontSize: "0.6rem",
    //   marginTop: 0,
    // },

    [theme.breakpoints.down("xs")]: {
      width: "16vw",
    },
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  Typography: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  detailBox: {
    padding: "3% 8% 0%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 2%",
    },
  },
  CreateDetailBox: {
    padding: "8%",
    alignItems: "center",
  },
  pinField: {
    justifyContent: "center",
    alignItems: "left",
    margin: "4%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  createButtonGrid: {
    textAlign: "center",
    paddingTop: "4%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  CreateButton: {
    backgroundColor: "#C49C39",
    textAlign: "center",
    width: "30%",
    // margin: "6px 0px",
    borderRadius: "25px",
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
  },
  timeDurationButton: {
    backgroundColor: "tranparent",
    textAlign: "center",
    margin: "6px 0px",
    border: "1px solid #848484",
    borderRadius: "15px",
    // width: "24px !important",
    height: "40px",
    minWidth: "47px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0",
    color: "#848484",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
    "&.active": {
      backgroundColor: "#359DB6",
      color: "#fff",
    },
  },
  selectedTransaction: {
    color: "#FFB476",
    borderBottom: "1px solid #FFB476",
  },
  buttonsForBuyType: {
    backgroundColor: "transparent",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    border: "none",
    "&.active": {
      color: "#FFB476",
      borderBottom: "1px solid #FFB476",
    },
  },
  buttonsForSellType: {
    backgroundColor: "transparent",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    border: "none",
    "&.active": {
      color: "#FFB476",
      borderBottom: "1px solid #FFB476",
    },
  },
  daysSection: {
    backgroundColor: "tranparent",
    textAlign: "center",
    margin: "6px 0px",
    height: "40px",
    minWidth: "47px",
    width: "100px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  icon: {
    fill: "#FFB476",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: "10px",
    fontSize: 12,

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    "&:focus": {
      borderRadius: 10,
      border: "1px solid #ff7300",
    },
  },
}))(InputBase);

const CssTextField = withStyles({
  root: {
    fontSize: "0.6rem",
    "& label.Mui-focused": {
      color: "#ff7300",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ff7300",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&:hover fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "10px",
        border: "1px solid #ff7300",
      },
    },
    "& .Mui-error .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: "2px",
      },
    },
    otpBox: {
      width: "45px !important",
      height: "45px !important",

      // [theme.breakpoints.down("md")]: {
      // 	width: "30px !important",
      // 	height: "30px !important",
      // },
    },
    BuyButton: {
      backgroundColor: "#359DB6",
      textAlign: "center",
      margin: "6px 0px",
      borderRadius: "10px",
      width: "25%",
      fontSize: "0.8rem",
      fontWeight: "bold",
    },
  },
})(TextField);

const useHelperTextStyles = makeStyles(() => ({
  root: {
    fontSize: "0.6rem",
  },
}));

const BuyDetails = (props) => {
  const {
    open,
    buyOpen,
    walletModalOpen,
    handleWalletModal,
    stockDetails,
    handleBuyStocks,
    currType,
    handleClose,
    title,
    buyStocks,
    createStock,
    createStockAccount,
    handleState,
    stockAccount,
    minQuantity,
    currencyOption,
    tradeStockEngine,
    notify,
    getStockPriceHistory,
    stockPriceData,
    closeModal,
    page,
    closeOrderBook,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [quantity, setQuantity] = useState(0);
  const [security, setSecurity] = useState("");
  const [error, setError] = useState("");
  const [transactionType, setTransactionType] = useState("Buy");
  const [currencyType, setCurrencyType] = useState("COG100");
  const [type, setType] = useState("Limit Price");
  const [activeButton, setActiveButton] = useState(0);
  const [activeBuyTransaction, setActiveBuyTransaction] = useState("Buy");
  const [activeSellTransaction, setActiveSellTransaction] = useState(null);
  const [price, setPrice] = useState(type == "Market Price" ? 10 : 0);
  const [startDate, setStartDate] = useState(new Date());
  const [showPinSection, setShowPinSection] = useState(false);

  useEffect(() => {
    if (title == undefined) {
      const formattedDate = formatDateToYYYYMMDD(startDate);
      setStartDate(formattedDate);
      getStockPriceHistory({
        stockId: stockDetails.stockId,
        currencyName: currencyType,
        from: formattedDate,
        till: "",
      });
    }
  }, []);

  const time = ["1D", "1W", "1M", "3M", "6M", "9M", "1y"];
  const setToMidnight = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };
  const calculateStartDate = (duration) => {
    const currentDate = new Date();
    let newStartDate;

    switch (duration) {
      case "1D":
        newStartDate = new Date();
        break;
      case "1W":
        newStartDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
        break;
      case "1M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 1)
        );
        break;
      case "3M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 3)
        );
        break;
      case "6M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 6)
        );
        break;
      case "9M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 9)
        );
        break;
      case "1y":
        newStartDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() - 1)
        );
        break;
      default:
        newStartDate = new Date();
    }

    return setToMidnight(newStartDate);
  };

  const currencyLabels = {
    COG100: "COG 100%",
    CB25CO75: "Cog 75%,CBC 25%",
    CB50CO50: "Cog 50%,CBC 50%",
    CB75CO25: "Cog 25%,CBC 75%",
    CBC100: "CBC 100%",
  };

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
        // marginTop: "50px",
        fontSize: "12px",
        width: "auto",

        // color: "#fff",
      },
    },
  };

  const handleQuantity = (e) => {
    let inputValue = e.target.value.trim();
    let parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    if (parsedValue === "" || isNaN(parsedValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      if (
        Number(parsedValue) >
        Number(stockDetails?.availableQuantity) /
          Number(stockDetails?.minQuantity)
      ) {
        setError("You cannot purchase more than available lots");
      } else {
        setError("");
      }
      setQuantity(parsedValue);
    }
  };

  const handlePrice = (e) => {
    const newPrice = parseFloat(e.target.value);
    setPrice(isNaN(newPrice) ? 0 : newPrice);
  };

  const stockHandler = () => {
    if (security === "" || security?.length < 6) {
      setError("Please enter 6 digit PIN");
    } else {
      let reqBody = {
        stockId: stockDetails,
        walletName: currencyType,
        isBuy: transactionType === "Buy" ? true : false,
        orderType: type === "Limit Price" ? "limit" : "market",
        quantity: quantity,
        price: price,
        pin: security,
      };
      tradeStockEngine(reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            notify(successMessage, "success");
            closeModal();
          } else {
            notify(errorMessage || error, "error");
            closeModal();
          }
        })
        .catch((err) => {
          notify(err?.errorMessage, "error");
          closeModal();
        });
    }
  };

  const setPin = (i) => {
    if (isNaN(i)) {
      setError("Please enter numeric value");
      return;
    } else {
      setError("");
      setSecurity(i);
    }
  };

  const handleCreateAccount = () => {
    createStockAccount().then(() => {
      handleState();
    });
  };

  const handleDecreaseAmount = () => {
    // let updatedQuantity = quantity - minQuantity;
    if (quantity <= 0) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleIncreaseQuantity = () => {
    // let updatedQuantity = minQuantity + quantity;
    setQuantity(quantity + 1);
  };

  const handleTransactionSelection = (type) => {
    setTransactionType(type);
    if (type == "Buy") {
      setActiveSellTransaction(null);
      setActiveBuyTransaction("Buy");
    } else {
      setActiveSellTransaction("Sell");
      setActiveBuyTransaction(null);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrencyType(event.target.value);
    getStockPriceHistory({
      stockId: stockDetails.stockId,
      currencyName: event.target.value,
      from: startDate,
      till: "",
    });
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    if (event.target.value == "Market Price") {
      setPrice(10);
    }
  };

  useEffect(() => {
    if (title == undefined) {
      const formattedDate = formatDateToYYYYMMDD(startDate);
      setStartDate(formattedDate);
      getStockPriceHistory({
        stockId: stockDetails.stockId,
        currencyName: currencyType,
        from: formattedDate,
        till: "",
      });
    }
  }, []);

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleButtonClick = (index) => {
    setActiveButton(index);
    const selectedDuration = time[index];
    const calculatedDate = calculateStartDate(selectedDuration);
    const formattedDate = formatDateToYYYYMMDD(calculatedDate);
    setStartDate(formattedDate);
    getStockPriceHistory({
      stockId: stockDetails.stockId,
      currencyName: currencyType,
      from: formattedDate,
      till: "",
    });
  };
  let totalAmount = 0;
  const getCurrentPrice = () => {
    if (stockDetails?.StockPrice) {
      const priceObj = stockDetails?.stockPrice.find(
        (price) => price.currencyName === currType
      );
      return priceObj ? priceObj.currentPrice : "N/A";
      totalAmount = price * quantity;
    } else {
      return (totalAmount = 0);
    }
  };
  return (
    <div>
      <Grid item container xs={12} className={classes.modalHeading}>
        <Typography style={{ paddingLeft: "20px", fontSize: "1.2rem" }}>
          {createStock
            ? "Create Stock Account"
            : title == "0"
            ? "Buy Stock Preview"
            : title == "1"
            ? "Sell Stock Preview"
            : "Stock Details"}
        </Typography>
        <IconButton id="close-buy-stocks" onClick={closeModal}>
          <CancelOutlined />
        </IconButton>
      </Grid>
      {title == "0" || title == "1" || showPinSection ? (
        <>
          <Grid
            item
            container
            xs={12}
            style={{ padding: "28px", display: "flex", gap: "44px" }}
          >
            <Grid
              item
              xs={6}
              direction="column"
              style={{
                display: "flex",
                borderRight: "1px solid #4F4F4F",
                gap: "20px",
              }}
            >
              <Typography>PIN</Typography>
              <Typography>Confirm Your Transaction</Typography>
              <FormControl>
                <OTPInput
                  numInputs={6}
                  renderSeparator={<span style={{ margin: "2%" }}></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className={classes.otpBox}
                      autoComplete="new-password"
                    />
                  )}
                  shouldAutoFocus
                  isInputNum={true}
                  inputType="password"
                  value={security}
                  onChange={setSecurity}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    color: localStorage.getItem("dark") ? "black" : "#DCDCDC",
                    fontWeight: "400",
                    backgroundColor: localStorage.getItem("dark")
                      ? "#c6c6c6"
                      : "#2e2e2e",
                  }}
                />
              </FormControl>
              <Button
                onClick={() => {
                  stockHandler();
                }}
                style={{ width: "30%" }}
                className={classes.PayButton}
                disabled={security?.length == 6 ? false : true}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={5} direction="column" container spacing={3}>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Typography>Stock Quantity</Typography>
                <Typography>{quantity}</Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Typography>Currency Option</Typography>
                <Typography>{currencyLabels[currencyType]}</Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Typography>Transaction Total</Typography>
                <Typography>{totalAmount}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {createStock ? (
            <>
              <Grid item container className={classes.CreateDetailBox}>
                <Grid item xs={12}>
                  <Typography>
                    You don't have a Stock account. Do you wish to create Stock
                    account for buying the stocks?
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.createButtonGrid}>
                  <Button
                    id={"create-account-yes"}
                    className={classes.CreateButton}
                    onClick={handleCreateAccount}
                  >
                    Yes
                  </Button>

                  <Button
                    id={"create-account-no"}
                    className={classes.CreateButton}
                    onClick={closeModal}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : stockAccount ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              <Grid item container style={{ padding: "28px", display: "flex" }}>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography>
                    <span style={{ color: "#359DB6" }}>{currType}</span>{" "}
                    &nbsp;&nbsp;
                    {`${getCurrentPrice().toLocaleString("en-US")}`}
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                  >
                    {stockDetails?.stockName}
                  </Typography>
                  <Grid
                    item
                    xs={10}
                    style={{
                      border: "1px solid #494949",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid item style={{ height: "45vh", width: "100%" }}>
                      <LineChart data={stockPriceData} />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    {time.map((time, index) => {
                      return (
                        <Button
                          key={index}
                          className={`${classes.timeDurationButton} ${
                            activeButton === index ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick(index)}
                        >
                          {time}
                        </Button>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  xs={6}
                  style={{
                    border: "1px solid #494949",
                    padding: " 1% 4%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    width: "90%",
                  }}
                >
                  <Grid
                    item
                    style={{
                      borderBottom: "1px solid #494949",
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Button
                      className={`${classes.buttonsForBuyType} ${
                        activeBuyTransaction === "Buy" ? "active" : ""
                      }`}
                      onClick={() => handleTransactionSelection("Buy")}
                    >
                      Buy
                    </Button>
                    <Button
                      className={`${classes.buttonsForSellType} ${
                        activeSellTransaction === "Sell" ? "active" : ""
                      }`}
                      onClick={() => handleTransactionSelection("Sell")}
                    >
                      Sell
                    </Button>
                  </Grid>
                  <Grid item style={{ padding: "10px" }}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20%",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {stockDetails?.name}
                        </Typography>
                        <Typography style={{ marginTop: "1rem" }}>
                          Stock Quantity
                        </Typography>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          className={classes.quantityField}
                          onChange={handleQuantity}
                          value={
                            quantity === "" || isNaN(quantity) ? 0 : quantity
                          }
                          helperText={
                            isNaN(quantity) || typeOf(quantity) === String
                              ? "Please enter number"
                              : quantity < 0
                              ? "  Please enter positive value"
                              : null
                          }
                        />
                      </div>
                      <FormControl variant="outlined" fullWidth margin="dense">
                        <Typography style={{ marginTop: "0.5rem" }}>
                          Wallet Currency Option
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={currencyLabels[currencyType] || ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  value=""
                                  onChange={handleCurrencyChange}
                                  input={<BootstrapInput />}
                                  IconComponent={ArrowDropDownIcon}
                                  MenuProps={MenuProps}
                                  id="paymentTypeDropdown"
                                  classes={{ icon: classes.icon }}
                                >
                                  <MenuItem
                                    value="COG100"
                                    id="cog100"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        currencyType == "COG100"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        currencyType === "COG100"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    COG 100%
                                  </MenuItem>
                                  <MenuItem
                                    value="CB25CO75"
                                    id="cog75"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        currencyType == "CB25CO75"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        currencyType === "CB25CO75"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    Cog 75%,CBC 25%
                                  </MenuItem>
                                  <MenuItem
                                    value="CB50CO50"
                                    id="CBC50"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        currencyType == "CB50CO50"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        currencyType === "CB50CO50"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    Cog 50%,CBC 50%
                                  </MenuItem>
                                  <MenuItem
                                    value="CB75CO25"
                                    id="cog25"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        currencyType == "CB75CO25"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        currencyType === "CB75CO25"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    Cog 25%,CBC 75%
                                  </MenuItem>
                                  <MenuItem
                                    value="CBC100"
                                    id="cbc100"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        currencyType == "CBC100"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        currencyType === "CBC100"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    CBC 100%
                                  </MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <FormControl variant="outlined" fullWidth margin="dense">
                        <Typography style={{ marginTop: "0.5rem" }}>
                          Stock Sell Value
                        </Typography>
                        <TextField
                          disabled={type === "Market Price"}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={price}
                          onChange={handlePrice}
                          helperText={
                            isNaN(price) || typeOf(price) === String
                              ? "Please enter a number"
                              : price < 0
                              ? "Please enter a positive value"
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  value=""
                                  variant="outlined"
                                  onChange={handleTypeChange}
                                  input={<BootstrapInput />}
                                  MenuProps={MenuProps}
                                  IconComponent={ArrowDropDownIcon}
                                  style={{ fontSize: "12px" }}
                                  classes={{ icon: classes.icon }}
                                >
                                  <MenuItem
                                    value="Limit Price"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        type == "Limit Price"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        type === "Limit Price"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    Limit Price
                                  </MenuItem>
                                  <MenuItem
                                    value="Market Price"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor:
                                        type == "Market Price"
                                          ? "#1B5563"
                                          : theme.palette.background.default,
                                      color:
                                        !localStorage.getItem("dark") ||
                                        type === "Market Price"
                                          ? "#fff"
                                          : "#212121",
                                    }}
                                  >
                                    Market Price
                                  </MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{ marginTop: "1rem", color: "#C49C39" }}
                        >
                          Total Amount
                        </Typography>
                        <Typography
                          style={{ marginTop: "1rem", color: "#C49C39" }}
                        >
                          {currencyType.replace(/\s+/g, "").replace(/%/g, "")}
                          &nbsp;&nbsp;&nbsp;
                          {totalAmount}
                        </Typography>
                      </div>

                      <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        justifyContent="center"
                      >
                        <Button
                          className={classes.PayButton}
                          onClick={() => {
                            handleBuyStocks({
                              stockDetails: stockDetails?.stockId,
                              currencyType: currencyType,
                              title: transactionType === "Buy" ? "0" : "1",
                            });
                            setShowPinSection(true);
                          }}
                          id={quantity * stockDetails?.stockPrice}
                          disabled={
                            !quantity ||
                            error !== "" ||
                            isNaN(quantity) ||
                            typeOf(quantity) === String ||
                            quantity < 1
                          }
                        >
                          {transactionType == "Buy" ? "Buy" : "Sell"}
                        </Button>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    stockAccount: userReducer.loadings.stockAccount,
    stockPriceData: userReducer.stockPriceData,
    stockPriceLoading: userReducer.loadings.stockPrice,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    buyStocks: (data) => dispatch(actionCreator.buyStocks(data)),
    createStockAccount: () => dispatch(actionCreator.createStockAccount()),
    tradeStockEngine: (data) => dispatch(actionCreator.tradeStockEngine(data)),
    getStockPriceHistory: (data) =>
      dispatch(actionCreator.getStockPriceHistory(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyDetails);
