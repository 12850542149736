import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { priceFormatter } from "../../../../utils/numberFormater/priceFormater";
//material
import { Box, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	cogflow: {
		maxWidth: "100%",
		borderRadius: 13,
		width: 305,
		// height: 90,
		background: localStorage.getItem("dark")
			? theme.palette.background.default
			: "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
		boxShadow: localStorage.getItem("dark")
			? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
			: "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
	},
	cogflowContainer: {
		padding: "5px 8px 5px 9px",
	},
	headingContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
	heading: {
		// color: "rgba(255, 255, 255, 0.7)",
		fontSize: 16,
		fontWeight: "bold",
		[theme.breakpoints.down(860)]: {
			fontSize: 14,
		},
		[theme.breakpoints.down(1250)]: {
			fontSize: 12,
		},
	},
	month: {
		display: "flex",
		alignItems: "center",
		color: "rgba(255, 115, 0, 0.7)",
		fontSize: 10,
		[theme.breakpoints.down(860)]: {
			fontSize: 8,
		},
	},
	arrow: {
		position: "relative",
		right: 5,
	},
	priceContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "start",
		flexWrap: "wrap",
	},
	amountContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
	},
	price: {
		fontSize: 23,
		fontWeight: "bold",
		// color: "#DCDCDC",
		maxWidth: "100%",
		overflowWrap: "break-word",
		[theme.breakpoints.down(960)]: {
			fontSize: 18,
		},
		[theme.breakpoints.down(1250)]: {
			fontSize: 16,
		},
		[theme.breakpoints.down(850)]: {
			fontSize: 13,
			overflowWrap: "break-word",
		},
		[theme.breakpoints.down(780)]: {
			fontSize: 11,
			overflowWrap: "break-word",
		},
		[theme.breakpoints.down(560)]: {
			fontSize: 18,
		},
		
	},
	percentage: {
		display: "flex",
		alignItems: "center",
		fontSize: 10,
		fontWeight: "bold",
		[theme.breakpoints.down(860)]: {
			fontSize: 8,
		},
		[theme.breakpoints.down(1250)]: {
			fontSize: 8,
		},
		// color: "#359DB6",
	},
}));

const Cogflow = (props) => {
	const classes = useStyles();
	const { cogFlow, width, location } = props;
	let dataLength = cogFlow.length;

	const percentage =
		dataLength > 1
			? (
					((cogFlow[0].total_cog_circulation - cogFlow[1].total_cog_circulation) *
						100) /
					cogFlow[1].total_cog_circulation
			  )?.toFixed(3)
			: null;
	return (
		<Grid
			item
			xs={12}
			className={classes.cogflow}
			style={{
				width: width ?? '100%',
				marginTop: location === "CBC in Circulation" ? "24px" : "0px",
			}}
		>
			<Box className={classes.cogflowContainer}>
				<Box className={classes.headingContainer}>
					<Typography variant="body1" className={classes.heading}>
						{location}
					</Typography>
					{/* <Typography variant="body1" className={classes.month}>
						Month
						<Box className={classes.arrow}>
							<ArrowDropDownIcon />
						</Box>
					</Typography> */}
				</Box>
				<Box className={classes.priceContainer}>
					<Box className={classes.amountContainer}>
						<Typography variant="body1" className={classes.price}>
							{location === "COG in Circulation"
								? cogFlow[0]?.total_cog_circulation.toLocaleString("en-US", {
										minimumFractionDigits: 3,
								  })
								: cogFlow.toLocaleString("en-US", { minimumFractionDigits: 3 })}
						</Typography>
						<Box>
							<img
								src={
									location === "COG in Circulation"
										? "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/COG.svg"
										: "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/COG%20Premium.svg"
								}
								style={{ width: 42 }}
							/>
						</Box>
					</Box>
					{percentage != null && location === "COG in Circulation" && false ? (
						<Typography
							className={classes.percentage}
							style={{
								color: percentage > 0 ? "#359DB6" : "rgba(255, 115, 0, 0.7)",
							}}
						>
							{percentage > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
							<Typography className={classes.percentage}> {percentage}%</Typography>
						</Typography>
					) : null}
				</Box>
			</Box>
		</Grid>
	);
};

export default Cogflow;
