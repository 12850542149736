import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import facebook from "../../asset/images/footer/facebook.svg";
import instagram from "../../asset/images/footer/instagram.svg";
import linkedin from "../../asset/images/footer/linkedin.svg";
import twitter from "../../asset/images/footer/twitter.svg";

const useStyles = makeStyles((theme) => ({
	footer: {
		marginTop: 65,
		paddingBottom: 10,
		[theme.breakpoints.down("sm")]: {
			paddingBottom: 56,
		},
	},
	line1: {
		height: 5,
		background: "#075062",
		border: "none",
		width: "40%",
		float: "left",
		display: "block",
		color: "#075062 !important",
	},
	line2: {
		height: 5,
		background: "#075062",
		border: "none",
		width: "100%",
		float: "left",
		display: "block",
		color: "#075062 !important",
	},
	line3: {
		height: 5,
		background: "#075062",
		width: "40%",
		border: "none",
		float: "right",
		display: "block",
		color: "#075062 !important",
	},
	buttonText: {
		color: "#FF7300",
		textTransform: "none",
	},
	img: {
		width: "60%",
		height: "auto",
		display: "inline-block",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	link: {
		display: "flex",
		alignItems: "center",
		"&:hover,&:focus": {
			textDecoration: "underline",
			textDecorationColor: "#ff7300",
		},
	},
	text: {
		display: "inline-block",
		fontSize: "1rem",
		color: "#FF7300",
		[theme.breakpoints.down(690)]: {
			fontSize: "0.6rem",
		},
		"&:hover,&:focus": {
			textDecoration: "underline",
			textDecorationColor: "#ff7300",
		},
	},
}));

const data = [
	{
		name: "Instagram",
		imgSrc: instagram,
		link: " https://www.instagram.com/cogito/",
	},
	{
		name: "Facebook",
		imgSrc: facebook,
		link: "https://www.facebook.com/cogitometaverse/",
	},
	{
		name: "Twitter",
		imgSrc: twitter,
		link: "https://twitter.com/CogitoMetaverse",
	},
	{
		name: "Linkedin",
		imgSrc: linkedin,
		link: " https://www.linkedin.com/company/principality-cogito/",
	},
];

const Footer = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} className={classes.footer}>
			<hr className={classes.line1} />
			<hr className={classes.line2} />
			<hr className={classes.line3} />
			<Grid item container direction="row" justifyContent="space-around" xs={12}>
				{data.map((item, index) => {
					return (
						<Grid item container alignItems="center" item xs={2} key={index}>
							<Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
								<img className={classes.img} src={item.imgSrc} alt={item.name} />
							</Grid>
							<Grid item xs={9} md={5}>
								<Link href={item.link} target="_blank" className={classes.link}>
									<Typography className={classes.text}>{item.name}</Typography>
								</Link>
							</Grid>
						</Grid>
					);
				})}
			</Grid>

			{/* <Typography
        variant="body2"
        color="grey"
        align="center"
        style={{ float: "right",padding:"1%" }}
      >
        {"Copyright © "}
        <Link style={{ color: "#359DB6" }} href="#">
          Principality of Cogito
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography> */}
		</Grid>
	);
};

export default Footer;
