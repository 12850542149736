import * as actionTypes from "../action/actionTypes";
import { updateObject, arrayUnique } from "../../asset/utility";

const initialState = {
  status: null,
  profile: null,
  accounts: null,
  companies: null,
  businesses: null,
  kyc: null,
  kycToken: null,
  sponsorList: null,
  overdrafts: null,
  activeOverdrafts: null,
  nodes: [],
  recommendations: [],
  cogTransactions: null,
  filledData: {},
  kycId: null,
  traders: null,
  assets: null,
  stockAccount: null,
  escrowAccount: null,
  userUriGroupId: null,
  userUriDetail: null,
  allAssets: null,
  selectedTrader: null,
  escrowWindow: false,
  escrowStatusTab: {
    tab: "initiate",
    isReceived: true,
  },
  quizQuestions: [],
  exchangeDirectory: [],
  allAssets: null,
  selectedTrader: null,
  totalBalance: 0,
  pmActivity: [],
  notification: null,
  readNotifications: false,
  cbcTransfers: [],
  bankAccounts: null,
  cbcTransfers: [],
  // addressDetails: {
  // 	address: null,
  // 	country: null,
  // 	state: null,
  // 	city: null,
  // 	pincode: null,
  // },
  stockList: [],
  userStocks: [],
  userStocksTransations: [],
  userStockTransactionCount: 0,
  businessDirectory: [],
  cryptopiaData: null,
  userKYC: undefined,
  isCryptopiaDataAvailable: false,
  allStockEngineList: [],
  allStockDataCount: 0,
  allStockDataCurrentPage: 0,
  singleUserStock: [],
  singleUserStockCount: 0,
  portfolioDataCurrentPage: 0,
  walletData: [],
  stockTransactionData: null,
  totalTransactionDataCount: 0,
  stockWalletTransactionData: null,
  totalWalletDataCount: 0,
  stockPriceData: null,
  orderBook: null,
  notification: null,
  buyOrder: null,
  sellOrder: null,
  currentStockId: null,
  singleStockPriceArr: null,
  stockCategory: null,
  selectedStockCategory: [],
  stockTransactionDataUser: null,
  cogValueStock: 0,
  singleUserPreviousStock: [],
  singleUserPreviousStockCount: 0,
  portfolioDataCurrentPagePreviousStock: 0,
  stockDetailsData: null,
  buyValueStock: 0,
  sellValueStock: 0,
  investmentData: null,
  stockTransactionExportData: null,
  totalStockTransactionExportDataCount: 0,
  stockWalletExportTransactionData: null,
  totalWalletTransactionExportDataCount: 0,

  loadings: {
    registered: false,
    getProfile: false,
    getAccounts: false,
    updateProfile: false,
    updateCompany: false,
    updatePassword: false,
    updateEmail: false,
    getKycToken: false,
    getRecommendation: false,
    teansferCogs: false,
    payCogs: false,
    euroRecharge: false,
    getSponsorList: false,
    setPin: false,
    resetPin: false,
    getBusinesses: false,
    getTraders: false,
    stocksTransaction: false,
    optinCall: false,
    initiateEscrow: false,
    getEscrowAccounts: false,
    cancelEscrow: false,
    updateEscrowConditions: false,
    exchangeDirectory: false,
    Notification: false,
    stockList: false,
    stocksOwned: false,
    stockAccount: false,
    stockTransaction: false,
    cryptopiaForm: false,
    updateCryptopiaForm: false,
    sendAgreement: false,
    getBusinessDirectoryLoader: false,
    getPmActivity: false,
    stockEngineList: false,
    userStock: false,
    walletDataLoading: false,
    transactionList: false,
    stockPrice: false,
    Notification: false,
    depositWithdrawLoading: false,
    stockEngineAccount: false,
    stockCategoryLoading: false,
    userPreviousStock: false,
    stockDetailsLoading: false,
    investmentData: false,
  },
};

//# HERE IS THE REDUCER OR CASE'S
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER_LOADING:
      return setUserLoading(state, payload);
    case actionTypes.SET_USER:
      return setUser(state, payload);
    case actionTypes.SET_ACCOUNT:
      return setAccount(state, payload);
    case actionTypes.SET_BANK_STATS:
      return setBankStats(state, payload);
    case actionTypes.SET_KYC_TOKEN:
      return setKycToken(state, payload);
    case actionTypes.ADD_RECOMMENDS:
      return addRecommends(state, payload);
    case actionTypes.LOGOUT:
      return logout(state, payload);
    case actionTypes.SET_SPONSOR_LIST:
      return setSponsorList(state, payload);
    case actionTypes.SET_OVER_DRAFTS:
      return setOverDrafts(state, payload);
    case actionTypes.SET_ACTIVE_OVERDRAFTS:
      return setActiveOverdrafts(state, payload);
    case actionTypes.SET_COMPANY_DETAILS:
      return setCompany(state, payload);
    case actionTypes.SET_BUSINESSES:
      return setBusinesses(state, payload);
    case actionTypes.SET_FILLEDDATA:
      return setFilledData(state, payload);
    case actionTypes.SET_KYC_ID:
      return setKycId(state, payload);
    case actionTypes.SET_TRADERS:
      return setTraderList(state, payload);
    case actionTypes.SET_TRADER:
      return setSelectedTrader(state, payload);
    case actionTypes.SET_ASSETS:
      return setAssetsList(state, payload);
    case actionTypes.SET_ALL_ASSETS:
      return setAllAssetsList(state, payload);

    case actionTypes.SET_USER_ACCOUNT:
      return setStockAccount(state, payload);
    case actionTypes.SET_ESCROW_ACCOUNT:
      return setEscrowAccount(state, payload);

    case actionTypes.SET_ESCROW_WINDOW:
      return setEscrowWindow(state, payload);
    case actionTypes.SET_USER_URI_GROUP_ID:
      return setUserUriGroupId(state, payload);
    case actionTypes.SET_USER_URI_DETAIL:
      return setUserUriDetail(state, payload);
    case actionTypes.SET_ESCROW_WINDOW:
      return setEscrowWindow(state, payload);

    case actionTypes.SET_USER_STATE:
      return setUserState(state, payload);
    // case actionTypes.UPDATE_BALANCE:
    //   return setAccount(state, payload);
    case actionTypes.SET_QUIZ_QUESTION:
      return setQuizQuestion(state, payload);
    case actionTypes.UPDATE_USER_REDUCER:
      return updateObject(state, payload);
    case actionTypes.SET_NOTIFICATIONS:
      return setNotifications(state, payload);
    case actionTypes.SET_BANK_ACCOUNTS:
      return setBankAccounts(state, payload);
    case actionTypes.SET_STOCK_LIST:
      return setStockList(state, payload);
    case actionTypes.SET_USER_STOCKS:
      return setUserStocks(state, payload);
    case actionTypes.SET_STOCK_TRANSACTIONS:
      return setSTockTransactions(state, payload);
    case actionTypes.SET_PM_ACTIVITY:
      return setPmActivity(state, payload);
    case actionTypes.SET_CBC_TRANSFERS:
      return setCbcTransfers(state, payload);
    case actionTypes.SET_BUSINESS_DIRECTORY:
      return setBusinessDirectory(state, payload);
    case actionTypes.SET_CRYPTOPIA_DATA:
      return setCryptopiaData(state, payload);
    case actionTypes.SET_BANK_ACCOUNTS:
      return setBankAccounts(state, payload);
    case actionTypes.SET_CBC_TRANSFERS:
      return setCbcTransfers(state, payload);

    case actionTypes.SET_USER_KYC:
      return setUserKYC(state, payload);
    case actionTypes.SET_STOCK_ENGINE_LIST:
      return setStocksEngineList(state, payload);
    case actionTypes.SET_STOCK_WALLET_DATA:
      return setWalletData(state, payload);
    case actionTypes.SET_STOCK_TRANSACTION_DATA:
      return setStockTxnEngine(state, payload);
    case actionTypes.SET_STOCK_WALLET_TRANSACTION_DATA:
      return setWalletTxnEngine(state, payload);
    case actionTypes.SET_STOCK_PRICE_DATA:
      return setStockPrice(state, payload);
    case actionTypes.SET_ORDER_BOOK:
      return setOrderBook(state, payload);
    case actionTypes.SET_NOTIFICATIONS:
      return setNotifications(state, payload);
    case actionTypes.UPDATE_STOCK_REALTIME:
      return updateObject(state, {
        allStockEngineList: payload?.stockList || [],
        singleUserStock: payload?.userStockList || [],
      });
    case actionTypes.GET_ORDER_BOOK_REAL_TIME:
      return updateObject(state, {
        buyOrder: payload?.buyOrder || [],
        sellOrder: payload?.sellOrder || [],
      });
    case actionTypes.SET_CRYPTOPIA_DATA_AVAILABLE:
      return setIsCryptopiaDataAvailable(state, payload);
    case actionTypes.SET_CURRENT_ID:
      return setCurrId(state, payload);
    case actionTypes.SET_SPECIFIC_STOCK_PRICE_ARR:
      return setSingleStockPriceArr(state, payload);
    case actionTypes.SET_STOCK_CATEGORY_DATA:
      return setStockCategory(state, payload);
    case actionTypes.SET_CURRENT_STOCK_CATEGORY:
      return setCurrStockCat(state, payload);
    case actionTypes.SET_COG_VALUE_STOCK:
      return setCogValueStock(state, payload);
    case actionTypes.SET_USER_PREVIOUS_STOCKS:
      return setUserPreviousStocks(state, payload);
    case actionTypes.SET_STOCKS_DETAILS_TEMPLATE:
      return setStockDetailsTemplate(state, payload);
    case actionTypes.SET_INVESTMENT_DETAILS:
      return setInvestmentDetail(state, payload);
    case actionTypes.SET_STOCK_TRANSACTION_EXPORT_DATA:
      return setStockTxnExportEngine(state, payload);
    case actionTypes.SET_WALLET_TRANSACTION_EXPORT_DATA:
      return setWalletTxnExportEngine(state, payload);
    case actionTypes.SET_CRYPTOPIA_DATA_AVAILABLE:
      return setIsCryptopiaDataAvailable(state, payload);

    default:
      return state;
  }
};

/*--- CASES ARE HERE DEFINED */
// UTILITY FUNCTION CALCULATE UTILIZATION

const setUserLoading = (state, data) => {
  return updateObject(state, {
    loadings: updateObject(state.loadings, data),
  });
};

const setUser = (state, data) => {
  let { user } = data;
  let newUser = {
    status: null,
    profile: null,
    kyc: null,
    nodes: [],
  };
  if (user) {
    let { status, kyc, nodes, ...profile } = user;
    newUser = {
      status: user.status || null,
      nodes: user.nodes || [],
      kyc: user.kyc || null,
      profile: profile || null,
    };
    if (newUser.profile) {
      newUser.profile = { ...newUser.profile, status: status };
    }
  }

  return updateObject(state, newUser);
};

const setCompany = (state, data) => {
  let companies = data;
  return updateObject(state, {
    companies: companies,
  });
};

const setAccount = (state, data) => {
  let { accounts } = data;
  return updateObject(state, {
    accounts: accounts,
  });
};

const setBankStats = (state, data) => {
  let { cogStats, cbcCirculation } = data;

  return updateObject(state, {
    cogStats: cogStats,
    cbcCirculation: cbcCirculation,
  });
};

const setKycToken = (state, data) => {
  let { token } = data;
  return updateObject(state, {
    kycToken: token,
  });
};
const setKycId = (state, data) => {
  let { id } = data;
  return updateObject(state, {
    kycId: id,
  });
};

const addRecommends = (state, data) => {
  let { user } = data;
  return updateObject(state, {
    // recommendations: arrayUnique(state.recommendations.concat(user)),
    recommendations: user,
  });
};

const setSponsorList = (state, data) => {
  let { sponsorList } = data;
  return updateObject(state, {
    sponsorList: sponsorList,
  });
};

const setOverDrafts = (state, data) => {
  let { overdrafts } = data;
  return updateObject(state, {
    overdrafts: overdrafts,
  });
};

const setActiveOverdrafts = (state, data) => {
  let { activeOverdrafts } = data;
  return updateObject(state, {
    activeOverdrafts: activeOverdrafts,
  });
};

const setBusinesses = (state, data) => {
  let { business } = data;
  return updateObject(state, {
    businesses: business,
  });
};

const setFilledData = (state, data) => {
  let { filledData } = data;
  return updateObject(state, {
    filledData: filledData,
  });
};

const logout = (state, data) => {
  return updateObject(state, initialState);
};

const setTraderList = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    traders: data,
  });
};

const setSelectedTrader = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    selectedTrader: data,
  });
};

const setAssetsList = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    assets: data || [],
  });
};

const setAllAssetsList = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    allAssets: data || [],
  });
};

const setStockAccount = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    stockAccount: data || null,
  });
};

const setEscrowAccount = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    escrowAccount: data || null,
  });
};

const setUserUriGroupId = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    userUriGroupId: data || null,
  });
};

const setUserUriDetail = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    userUriDetail: data || null,
  });
};
const setEscrowWindow = (state, payload) => {
  let esWindow = payload;
  return updateObject(state, {
    esWindow: esWindow,
  });
};

const setUserState = (state, payload) => {
  return updateObject(state, payload);
};

const setQuizQuestion = (state, payload) => {
  return updateObject(state, {
    quizQuestions: payload,
  });
};
const setNotifications = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    notification: [...data] || null,
  });
};

const setBankAccounts = (state, payload) => {
  return updateObject(state, {
    bankAccounts: payload,
  });
};

const setStockList = (state, payload) => {
  let { data } = payload?.stockList;

  return updateObject(state, {
    stockList: payload?.stockList || [],
  });
};
const setUserStocks = (state, payload) => {

  return updateObject(state, {
    singleUserStock: payload?.singleUserStock,
    singleUserStockCount: payload.singleUserStockCount,
    portfolioDataCurrentPage: payload.portfolioDataCurrentPage,
  });
};
const setSTockTransactions = (state, payload) => {
  return updateObject(state, {
    userStocksTransations: payload.userStocksTransations,
    userStockTransactionCount: payload.totalCount,
  });
};
const setCryptopiaData = (state, payload) => {
  return updateObject(state, {
    cryptopiaData: payload?.cryptopiaData || null,
  });
};
const setPmActivity = (state, payload) => {
  let data = payload;
  return updateObject(state, {
    pMActivity: data || null,
  });
};
const setBusinessDirectory = (state, payload) => {
  let data = payload;
  return updateObject(state, {
    businessDirectory: data || null,
  });
};

const setCbcTransfers = (state, payload) => {
  return updateObject(state, {
    cbcTransfers: payload,
  });
};

const setUserKYC = (state, payload) => {
  return updateObject(state, {
    userKYC: payload || null,
  });
};

const setIsCryptopiaDataAvailable = (state, payload) => {
  return updateObject(state, {
    isCryptopiaDataAvailable: payload || false,
  });
};

const setStocksEngineList = (state, payload) => {
  return updateObject(state, {
    allStockEngineList: payload?.allStockEngineList || [],
    allStockDataCount: payload?.allStockDataCount || 0,
    allStockDataCurrentPage: payload?.allStockDataCurrentPage || 0,
  });
};

const setWalletData = (state, payload) => {
  return updateObject(state, {
    walletData: payload?.walletData || [],
  });
};

const setStockTxnEngine = (state, payload) => {
  return updateObject(state, {
    stockTransactionData: payload?.stockTransactionData || [],
    totalTransactionDataCount: payload.totalTransactionDataCount || 0,
  });
};

const setWalletTxnEngine = (state, payload) => {
  return updateObject(state, {
    stockWalletTransactionData: payload?.stockWalletTransactionData || [],
    totalWalletDataCount: payload.totalWalletDataCount || 0,
  });
};

const setStockPrice = (state, payload) => {
  return updateObject(state, {
    stockPriceData: payload?.stockPriceData || [],
  });
};
const setOrderBook = (state, payload) => {
  return updateObject(state, {
    orderBook: payload?.orderBook || [],
  });
};

const setCurrId = (state, payload) => {
  let { data } = payload;
  return updateObject(state, {
    currentStockId: data || null,
  });
};

const setSingleStockPriceArr = (state, payload) => {


  let data =
    payload?.data?.find((stock) => stock.currencyName === payload.type) || null;
   
  return updateObject(state, {
    singleStockPriceArr: data || null,
  });
};

const setStockCategory = (state, payload) => {
  return updateObject(state, {
    stockCategory: payload.stockCategory || null,
  });
};

const setCurrStockCat = (state, payload) => {
  return updateObject(state, {
    selectedStockCategory: payload.data || null,
  });
};

const setCogValueStock = (state, payload) => {
  return updateObject(state, {
    cogValueStock: payload.cogValueStock || 0,
    buyValueStock: payload.buyValueStock || 0,
    sellValueStock: payload.sellValueStock || 0,
  });
};

const setUserPreviousStocks = (state, payload) => {
  return updateObject(state, {
    singleUserPreviousStock: payload?.singleUserPreviousStock,
    singleUserPreviousStockCount: payload.singleUserPreviousStockCount,
    portfolioDataCurrentPagePreviousStock:
      payload.portfolioDataCurrentPagePreviousStock,
  });
};

const setStockDetailsTemplate = (state, payload) => {
  return updateObject(state, {
    stockDetailsData: payload.stockDetailsData || null,
  });
};
const setInvestmentDetail = (state, payload) => {
  return updateObject(state, {
    // investmentData: payload.investmentData || null,
    investmentData:
      { ...state.investmentData, [payload.stockId]: payload?.investmentData } ||
      null,
  });
};

const setStockTxnExportEngine = (state, payload) => {
  return updateObject(state, {
    stockTransactionExportData: payload?.stockTransactionExportData || [],
    totalStockTransactionExportDataCount:
      payload.totalStockTransactionExportDataCount || 0,
  });
};

const setWalletTxnExportEngine = (state, payload) => {
  return updateObject(state, {
    walletTransactionExportData:
      payload?.stockWalletExportTransactionData || [],
    totalWalletTransactionExportDataCount:
      payload.totalWalletTransactionExportDataCount || 0,
  });
};
export default userReducer;
