import React, { useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Select,
  MenuItem,
  Grid,
  Typography,
  Modal,
  Backdrop,
  IconButton,
  Button,
  Divider,
} from "@material-ui/core";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CancelOutlined } from "@material-ui/icons";
import Spinner from "../../common/Spinner/Spinner";
import moment from "moment";
import PaginationComponent from "../../pagination/PaginationComponent";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Delete_Stock from "../../../asset/images/stocks/DeleteStock.svg";
import OTPInput from "react-otp-input";
import SmallSpinner from "../../common/smallSpinner/smallSpinner";
import NumberWithTooltip from "../../../utils/numberFormater/NumberWithTooltip";
import * as actionCreator from "../../../store/action/index";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import NoOrders from "../../../asset/images/stocks/Rectangle.gif";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: "10px",
    marginTop: "2rem",
    boxShadow: localStorage?.getItem("dark")
      ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
      : "none",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
  },
  paper: {
    padding: theme.spacing(2),
    color: "#fff",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    borderRadius: "10px",
    overflowX: "scroll",
  },
  table: {
    minWidth: 750,
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
  },
  headerCell: {
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  },
  bodyCell: {
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    borderBottom: "none",
  },
  verticalDivider: {
    display: "inline-block",
    width: "3px",
    height: "20px",
    backgroundColor: "red",
    margin: "0 8px",
    [theme.breakpoints.down("sm")]: {
      width: "0",
      margin: 0,
    },
  },
  status: {
    textAlign: "center",
    padding: "0.5rem",
    borderRadius: "0.25rem",
  },
  partially_fulfilled: {
    backgroundColor: "#AD7E58",
    color: "#493423",
  },

  partially_cancelled: {
    backgroundColor: "#AD7E58",
    color: "#493423 ",
  },

  success: {
    backgroundColor: "#DEFFC4",
    color: "#60AB26",
  },
  inProgress: {
    backgroundColor: "#848484",
    color: "#3A3A3A",
  },
  failed: {
    backgroundColor: "#FFB171",
    color: "#8C5930",
  },
  menu: {
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
  },
  transparentButton: {
    background: "transparent",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  PinSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    color: localStorage.getItem("dark") ? "#000" : "#fff",
    backgroundColor: localStorage.getItem("dark") ? "#fff" : "#000",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      flexDirection: "column",
    },
  },
  PinModalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "20vw",
    height: "50vh",
    width: "80vw",
    margin: "10% 12%",
    inset: 132,
    background: localStorage.getItem("dark") ? "#ffff" : "#1a1a1a",
    border: localStorage.getItem("dark")
      ? "1px solid #0000"
      : "1px solid #1a1a1a",
    // position: "relative",
    right: 20,
    border: "1px solid green",

    [theme.breakpoints.down("sm")]: {
      right: "0",
      width: "80%",
      height: "56vh",
    },
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark")
      ? "#cdcdcd"
      : "rgb(31, 31, 31)",
    height: "13vh",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  verticalDivider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "baseline",
      height: "0",
    },
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",

    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  ButtonSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
}));

const StockTransactionList = (props) => {
  const {
    stockTransactionData,
    stockWalletTransactionData,
    transactionListLoading,
    walletTransactionListLoading,
    totalWalletDataCount,
    totalTransactionDataCount,
    getStockTxnEngine,
    getWalletTxnEngine,
    notify,
    cancelTradeStockEngine,
    singleUserStockList,
    singleUserPreviousStockList,
    stockId,
    location,
    getStockTxnExportEngine,
    stockTransactionExportData,
    getPrevInvestment,
    getWalletTxnExportEngine,
    walletTransactionExportData,
    getPortfolioEngine,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dataPerPage = 10;
  const totalWalletPage = Math.ceil(totalWalletDataCount / dataPerPage);
  const totalTransactionsPage = Math.ceil(
    totalTransactionDataCount / dataPerPage
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [transactionType, setTransactionType] = React.useState("Transactions");
  const [currencyOption, setCurrencyOption] = React.useState("all");
  const [modelOpen, setModelOpen] = React.useState(false);
  const [security, setSecurity] = React.useState("");
  const [modalData, setModalData] = React.useState(null);
  const [disclamerScreen, setDisclamerScreen] = React.useState(true);
  const [confirmLoader, setConfirmLoader] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [selectedNewStock, setSelectedNewStock] = React.useState("all");
  const [selectedOldStock, setSelectedOldStock] = React.useState("all");
  const [selectedStockId, setSelectedStockId] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [orderType, setOrderType] = React.useState("All");
  const [downloadClick, setDownloadClick] = React.useState(false);

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(0);
  };

  const handleTransactionType = (event) => {
    setTransactionType(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrentPage(0);
    setCurrencyOption(event.target.value);
  };

  useEffect(() => {
    getPrevInvestment({
      limit: 1000,
      page: 0,
    });
    getPortfolioEngine({
      limit: 1000,
      page: 0,
    });
  }, []);

  useEffect(() => {
    if (location === "MO") {
      const isBuy =
        orderType === "Buy" ? true : orderType === "Sell" ? false : null;
      if (statusFilter === "all" && currencyOption == "all" && isBuy == null) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
        });
      } else if (statusFilter == "all" && currencyOption == "all" && isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          orderStatus: "pending",
        });
      } else if (statusFilter == "all" && currencyOption == "all" && !isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          orderStatus: "pending",
        });
      } else if (
        statusFilter !== "all" &&
        currencyOption == "all" &&
        isBuy == null
      ) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          orderStatus: statusFilter,
        });
      } else if (
        statusFilter === "all" &&
        currencyOption !== "all" &&
        isBuy == null
      ) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          currencyName: currencyOption,
          orderStatus: "pending",
        });
      } else if (statusFilter !== "all" && currencyOption == "all" && isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          orderStatus: statusFilter,
        });
      } else if (statusFilter !== "all" && currencyOption == "all" && !isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          orderStatus: statusFilter,
        });
      } else if (statusFilter === "all" && currencyOption !== "all" && isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          currencyName: currencyOption,
          orderStatus: "pending",
        });
      } else if (statusFilter === "all" && currencyOption !== "all" && !isBuy) {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          isBuy: isBuy,
          currencyName: currencyOption,
          statusFilter: "pending",
        });
      } else {
        getStockTxnEngine({
          limit: dataPerPage,
          page: currentPage,
          orderStatus: statusFilter,
          isBuy: isBuy,
          currencyName: currencyOption,
        });
      }
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnEngine({
        stockId: selectedStockId,
        limit: dataPerPage,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      location === "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      statusFilter == "all"
    ) {
      getStockTxnEngine({
        stockId: stockId,
        limit: dataPerPage,
        page: currentPage,
      });
    } else if (transactionType !== "Transactions") {
      getWalletTxnEngine({
        limit: dataPerPage,
        page: currentPage,
      });
    } else if (location == "BuyStock" && location !== "Portfolio") {
      getStockTxnEngine({
        stockId: stockId,
        limit: dataPerPage,
        page: currentPage,
        orderStatus: "pending",
      });
    } else {
      getStockTxnEngine({
        stockId: stockId,
        limit: dataPerPage,
        page: currentPage,
        orderStatus: statusFilter,
      });
    }
  }, [
    currentPage,
    transactionType,
    currencyOption,
    getStockTxnEngine,
    getWalletTxnEngine,
    statusFilter,
    fromDate,
    toDate,
    location,
    stockId,
    currentPage,
    selectedStockId,
    orderType,
    selectedNewStock,
    selectedOldStock,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [transactionType]);

  useEffect(() => {
    if (toDate && fromDate) {
      setCurrentPage(0);
    }
  }, [toDate, fromDate]);

  useEffect(() => {
    if (stockTransactionExportData && downloadClick) {
      downloadHtmlAsExcel(stockTransactionExportData);
    }

    if (walletTransactionExportData && downloadClick) {
      downloadHtmlAsExcel(walletTransactionExportData);
    }
  }, [stockTransactionExportData, walletTransactionExportData]);

  const walletTransactions = stockWalletTransactionData;

  const transactions = stockTransactionData;

  const jsonToHtmlTable = (jsonData) => {
    let html = '<table border="1" style="width:100%; text-align:center;">';

    const headers = Object.keys(jsonData[0]);
    html += "<thead><tr>";
    headers.forEach((header) => {
      html += `<th style="font-weight:bold; font-size:18px;text-align:'center;">${header}</th>`;
    });
    html += "</tr></thead>";

    html += "<tbody>";
    jsonData.forEach((item) => {
      html += `<tr style=" font-size:18px; text-align:'center;">`;
      headers.forEach((header) => {
        html += `<td style="text-align:center;">${item[header]}</td>`;
      });
      html += "</tr>";
    });
    html += "</tbody>";

    html += "</table>";
    return html;
  };

  const filterDataForExport = (data) => {
    return data.map((item) => ({
      createdAt: moment(item.createdAt).format("MMM Do, YYYY"),
      stockName: item.stockName,
      quantity: item.quantity,
      stockPrice: item.stockPrice,
      totalAmount: item.quantity * item.stockPrice,
    }));
  };

  const filterDataForWalletExport = (data) => {
    return data.map((item) => ({
      createdAt: moment(item.createdAt).format("MMM Do, YYYY"),
      walletName: item.walletName,
      type: item.type,
      amount: item.amount,
      currentValue: item.currentValue,
      notes: item.note,
    }));
  };

  const downloadHtmlAsExcel = (jsonData) => {
    const filteredData =
      transactionType === "Wallet"
        ? filterDataForWalletExport(jsonData)
        : filterDataForExport(jsonData);

    if (!Array.isArray(filteredData) || filteredData.length === 0) {
      alert("No data available to export.");
      return;
    }

    try {
      const htmlTable = jsonToHtmlTable(filteredData);
      const blob = new Blob([htmlTable], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.xls";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error converting JSON to HTML table:", error);
      alert("An error occurred while exporting the data.");
    }
  };

  const downloadTransactionDocument = () => {
    if (location === "MO") {
      const isBuy =
        orderType === "Buy" ? true : orderType === "Sell" ? false : null;
      if (statusFilter === "all" && currencyOption == "all" && isBuy == null) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
        });
      } else if (statusFilter == "all" && currencyOption == "all" && isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
        });
      } else if (statusFilter == "all" && currencyOption == "all" && !isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
        });
      } else if (
        statusFilter !== "all" &&
        currencyOption == "all" &&
        isBuy == null
      ) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: statusFilter,
          isBuy: isBuy,
        });
      } else if (
        statusFilter === "all" &&
        currencyOption !== "all" &&
        isBuy == null
      ) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
        });
      } else if (statusFilter !== "all" && currencyOption == "all" && isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: statusFilter,
          isBuy: isBuy,
        });
      } else if (statusFilter !== "all" && currencyOption == "all" && !isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: statusFilter,
          isBuy: isBuy,
        });
      } else if (statusFilter === "all" && currencyOption !== "all" && isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
          currencyName: currencyOption,
        });
      } else if (statusFilter === "all" && currencyOption !== "all" && !isBuy) {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: "pending",
          isBuy: isBuy,
          currencyName: currencyOption,
        });
      } else {
        getStockTxnExportEngine({
          stockId: stockId,
          limit: totalTransactionDataCount,
          page: currentPage,
          orderStatus: statusFilter,
          isBuy: isBuy,
          currencyName: currencyOption,
        });
      }
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate == null &&
      toDate == null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      !selectedNewStock &&
      !selectedOldStock &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption == "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter == "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption === "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      currencyOption !== "all" &&
      statusFilter !== "all" &&
      (selectedNewStock || selectedOldStock) &&
      location !== "Portfolio" &&
      fromDate !== null &&
      toDate !== null
    ) {
      getStockTxnExportEngine({
        stockId: selectedStockId,
        limit: totalTransactionDataCount,
        page: currentPage,
        currencyName: currencyOption,
        orderStatus: statusFilter,
        startDate: fromDate,
        endDate: toDate,
      });
    } else if (
      transactionType === "Transactions" &&
      location === "Portfolio" &&
      location !== "MO" &&
      location !== "BuyStock" &&
      statusFilter == "all"
    ) {
      getStockTxnExportEngine({
        stockId: stockId,
        limit: totalTransactionDataCount,
        page: currentPage,
      });
    } else if (transactionType !== "Transactions") {
      getWalletTxnExportEngine({
        limit: totalWalletDataCount,
        page: currentPage,
      });
    }
    //  else {
    //   getStockTxnExportEngine({
    //     stockId: stockId,
    //     limit: totalTransactionDataCount,
    //     page: currentPage,
    //     orderStatus: statusFilter,
    //   });
    // }
  };

  let portfolioStock = singleUserStockList?.filter((stock) => {
    return stock?.stockId === stockId;
  });

  const renderWalletTable = () =>
    walletTransactionListLoading ? (
      <Spinner />
    ) : (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Table
            exportButton={true}
            className={classes.table}
            aria-label="wallet table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  <Select
                    value={transactionType}
                    onChange={handleTransactionType}
                    className={classes.menu}
                  >
                    <MenuItem value="Transactions">Transactions</MenuItem>

                    <MenuItem value="Wallet">Wallet</MenuItem>
                  </Select>
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Type
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Amount
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Current Value
                </TableCell>

                <TableCell className={classes.headerCell} align="center">
                  Notes
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletTransactions?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ borderBottom: "none" }}
                  >
                    <Typography style={{ marginTop: "2rem" }} variant="h6">
                      No Wallet Data
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                walletTransactions?.map((transaction) => {
                  return (
                    <TableRow key={transaction?.id}>
                      <TableCell align="center" className={classes.bodyCell}>
                        {moment(transaction?.createdAt).format(
                          "MMM DD, YYYY | hh:mm A"
                        )}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {transaction?.walletName}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {transaction?.isDebit ? (
                          <span style={{ color: "#F7A9A0" }}>Withdraw </span>
                        ) : (
                          <span style={{ color: "#76CEAB" }}>Deposit</span>
                        )}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {transaction?.isDebit ? (
                          <span style={{ color: "#F7A9A0" }}>
                            <NumberWithTooltip value={transaction?.amount} />
                          </span>
                        ) : (
                          <span style={{ color: "#76CEAB" }}>
                            <NumberWithTooltip value={transaction?.amount} />
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.bodyCell}>
                        <NumberWithTooltip value={transaction?.currentValue} />
                      </TableCell>

                      <TableCell align="center" className={classes.bodyCell}>
                        <Typography>
                          {transaction?.note ? transaction?.note : "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );

  const renderStockTransactionTable = () =>
    transactionListLoading ? (
      <Spinner />
    ) : (
      <Grid container>
        <Grid item xs={12}>
          <Table
            exportButton={true}
            className={classes.table}
            aria-label="stock transaction table"
          >
            {" "}
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {location === "MO" ? (
                    <Select
                      value={orderType}
                      onChange={(e) => setOrderType(e.target.value)}
                      className={classes.menu}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Buy">Buy</MenuItem>
                      <MenuItem value="Sell">Sell</MenuItem>
                    </Select>
                  ) : location !== "Portfolio" && location !== "BuyStock" ? (
                    <Select
                      value={transactionType}
                      onChange={handleTransactionType}
                      className={classes.menu}
                    >
                      <MenuItem value="Transactions">Transactions</MenuItem>
                      <MenuItem value="Wallet">Wallet</MenuItem>
                    </Select>
                  ) : (
                    <Typography>Transactions</Typography>
                  )}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {location == "BuyStock" ? (
                    <Typography>Currency</Typography>
                  ) : (
                    <Select
                      value={currencyOption}
                      onChange={handleCurrencyChange}
                      className={classes.menu}
                    >
                      <MenuItem
                        value="all"
                        id="all"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "All"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "All"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        value="COG100"
                        id="cog100"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "COG100"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "COG100"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        COG 100%
                      </MenuItem>
                      <MenuItem
                        value="CB25CO75"
                        id="cog75"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "CB25CO75"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "CB25CO75"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        Cog 75%,CBC 25%
                      </MenuItem>
                      <MenuItem
                        value="CB50CO50"
                        id="CBC50"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "CB50CO50"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "CB50CO50"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        Cog 50%,CBC 50%
                      </MenuItem>
                      <MenuItem
                        value="CB75CO25"
                        id="cog25"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "CB75CO25"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "CB75CO25"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        Cog 25%,CBC 75%
                      </MenuItem>
                      <MenuItem
                        value="CBC100"
                        id="cbc100"
                        style={{
                          fontSize: "12px",
                          backgroundColor:
                            currencyOption == "CBC100"
                              ? "#1B5563"
                              : theme.palette.background.default,
                          color:
                            !localStorage.getItem("dark") ||
                            currencyOption === "CBC100"
                              ? "#fff"
                              : "#212121",
                        }}
                      >
                        CBC 100%
                      </MenuItem>
                    </Select>
                  )}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Quantity
                </TableCell>

                <TableCell
                  className={classes.headerCell}
                  style={{ width: "130px" }}
                  align="center"
                >
                  Amount
                </TableCell>

                <TableCell className={classes.headerCell} align="center">
                  {location == "BuyStock" ? (
                    <Typography>Status</Typography>
                  ) : (
                    <Select
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                      className={classes.menu}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="partial_fulfilled">
                        Partially Fulfilled
                      </MenuItem>
                      {location !== "MO" ? (
                        <MenuItem value="partial_cancelled">
                          Partially Cancelled
                        </MenuItem>
                      ) : null}
                      <MenuItem value="inprogress">In Progress</MenuItem>
                      {location !== "MO" && location !== "BuyStock" ? (
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                      ) : null}

                      {location !== "MO" && location !== "BuyStock" ? (
                        <MenuItem value="completed">Completed</MenuItem>
                      ) : null}
                    </Select>
                  )}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {transactions == null || transactions?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={12}
                  align="center"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src={NoOrders} width={"80%"} />
                    <Typography
                      style={{
                        color: "#C1C1C1",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Currently you have no transactions.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {transactions?.map((transaction) => {
                  return (
                    <TableRow key={transaction?.id}>
                      <TableCell className={classes.bodyCell} align="center">
                        <div style={{ color: "#757575" }}>
                          {moment(transaction?.createdAt).format(
                            "MMM Do, YYYY"
                          )}
                        </div>
                        <div style={{ color: "#757575" }}>
                          {moment(transaction?.createdAt)
                            .utc()
                            .format("h:mm A")}
                        </div>
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <span style={{ marginLeft: "8px" }}>
                            {transaction?.stockName}
                          </span>
                          <span
                            style={{
                              color: transaction?.isBuy ? "#76CEAB" : "#F7A9A0",
                            }}
                          >
                            {transaction?.isBuy ? "Buy" : "Sell"}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell className={classes.bodyCell} align="center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <NumberWithTooltip value={transaction?.stockPrice} />

                          <span style={{ color: "#359DB6" }}>
                            {transaction?.walletName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        <span
                          style={{
                            color: transaction?.isBuy ? "#76CEAB" : "#F7A9A0",
                          }}
                        >
                          {`${
                            transaction?.executedQuantity?.toLocaleString(
                              "en-US"
                            ) || 0
                          }/${transaction?.quantity?.toLocaleString("en-US")}`}
                        </span>
                      </TableCell>

                      <TableCell className={classes.bodyCell} align="center">
                        <span
                          style={{
                            color: transaction?.isBuy ? "#F7A9A0" : "#76CEAB",
                          }}
                        >
                          <NumberWithTooltip
                            value={
                              transaction?.quantity * transaction?.stockPrice
                            }
                          />

                          {transaction?.executedQuantity ==
                          null ? null : transaction?.isBuy ? null : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                  transaction?.profit > 0
                                    ? "#76CEAB"
                                    : transaction?.profit < 0
                                    ? "#F7A9A0"
                                    : null,
                              }}
                            >
                              {transaction?.profit > 0 ? (
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  Profit:&nbsp;
                                  <NumberWithTooltip
                                    value={
                                      transaction?.profit *
                                      transaction?.executedQuantity
                                    }
                                    position="profit_loss"
                                  />
                                </Typography>
                              ) : transaction?.profit < 0 ? (
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  Loss:&nbsp;
                                  <NumberWithTooltip
                                    value={
                                      transaction?.profit *
                                      transaction?.executedQuantity
                                    }
                                    position="profit_loss"
                                  />
                                </Typography>
                              ) : null}
                            </div>
                          )}
                        </span>
                      </TableCell>

                      <TableCell className={classes.bodyCell} align="center">
                        <Box
                          style={{ borderRadius: "8px" }}
                          className={`${classes.status} ${
                            transaction?.orderStatus === "partial_fulfilled"
                              ? classes.partially_fulfilled
                              : transaction?.orderStatus === "partial_cancelled"
                              ? classes.partially_cancelled
                              : transaction?.orderStatus === "inprogress"
                              ? classes.inProgress
                              : transaction?.orderStatus === "cancelled"
                              ? classes.failed
                              : classes.success
                          }`}
                        >
                          {transaction?.orderStatus}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {transaction?.orderStatus == "partial_fulfilled" ||
                        transaction?.orderStatus == "inprogress" ? (
                          <button
                            className={classes.transparentButton}
                            onClick={() => handleStockDeletion(transaction)}
                          >
                            <img src={Delete_Stock} alt="delete_stock" />
                          </button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>
    );

  const renderPagination = () => {
    if (transactionType === "Transactions" && transactions?.length == 0) {
      return null;
    }

    if (
      transactionType === "Wallet" &&
      stockWalletTransactionData?.length === 0
    ) {
      return null;
    }

    return (
      <Box display="flex" justifyContent="center">
        <PaginationComponent
          page={currentPage + 1}
          pageCount={
            transactionType === "Wallet"
              ? totalWalletPage
              : totalTransactionsPage
          }
          onChange={(e, v) => {
            setCurrentPage(v - 1);
          }}
        />
      </Box>
    );
  };

  const handleStockDeletion = (data) => {
    const modalData = {
      stock_name: data?.stockName,
      stock_id: data?.stockTransactionId,
      quantity: data?.quantity,
      executed_quantity: data?.executedQuantity,
      price_limit: data?.stockPrice,
      wallet: data?.walletName,
      buyModal: data?.isBuy,
    };
    setModalData(modalData);
    setModelOpen(true);
  };

  const closeModal = () => {
    setModelOpen(false);
    setDisclamerScreen(true);
    setSecurity("");
  };

  const handleConfirm = () => {
    setDisclamerScreen(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const deleteHandler = () => {
    setConfirmLoader(true);
    if (security === "" || security?.length < 6) {
      notify("Please enter 6 digit PIN", "error");
    } else {
      let reqBody = {
        stockTxnId: modalData?.stock_id,
        pin: parseInt(security),
      };
      cancelTradeStockEngine(reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;

          if (status) {
            notify(successMessage, "success");
            setConfirmLoader(false);
            setSecurity("");
            if (location === "BuyStock") {
              getStockTxnEngine({
                stockId: stockId,
                limit: dataPerPage,
                page: currentPage,
                orderStatus: "pending",
              });
            } else if (location === "MO") {
              getStockTxnEngine({
                stockId: stockId,
                limit: dataPerPage,
                page: currentPage,
                orderStatus: "pending",
              });
            } else {
              getStockTxnEngine({
                limit: dataPerPage,
                page: currentPage,
              });
            }
            closeModal();
          } else {
            notify(errorMessage || error, "error");
            setConfirmLoader(false);
            setSecurity("");
            closeModal();
          }
        })
        .catch((err) => {
          setSecurity("");
          setConfirmLoader(false);
          notify(err?.errorMessage, "error");
          closeModal();
        });
    }
  };

  const newStockHandler = (e) => {
    setCurrentPage(0);
    if (e.target.value == "all") {
      setSelectedNewStock(e.target.value);
      if (selectedOldStock !== "all") {
        setSelectedOldStock(null);
      }
      setSelectedOldStock("all");
      setSelectedStockId(null);
    } else {
      setSelectedNewStock(e.target.value);
      setSelectedOldStock(null);
      setSelectedStockId(e.target.value);
    }
  };

  const oldStockHandler = (e) => {
    setCurrentPage(0);
    if (e.target.value == "all") {
      setSelectedOldStock(e.target.value);
      if (selectedNewStock !== "all") {
        setSelectedNewStock(null);
      }
      setSelectedNewStock("all");
      setSelectedStockId(null);
    } else {
      setSelectedOldStock(e.target.value);
      setSelectedNewStock(null);
      setSelectedStockId(e.target.value);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {transactionType == "Wallet" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}
              onClick={() => {
                downloadTransactionDocument();
                setDownloadClick(true);
              }}
            >
              <CloudDownloadIcon />
            </Button>
            <Typography style={{ marginRight: "1rem" }}>Export</Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}
              onClick={() => {
                downloadTransactionDocument();
                setDownloadClick(true);
              }}
            >
              <CloudDownloadIcon />
            </Button>
            <Typography style={{ marginRight: "1rem" }}>Export</Typography>
          </div>
        )}
        <Grid container spacing={3}>
          {transactionType === "Wallet" ||
          location == "BuyStock" ||
          location == "MO" ? null : (
            <Grid
              item
              xs={12}
              container
              spacing={2}
              justifyContent="space-evenly"
              alignItems="center"
              style={{ marginTop: "1rem" }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-from"
                    label="From"
                    format="MM/dd/yyyy"
                    value={fromDate}
                    // onChange={setFromDate}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-to"
                    label="To"
                    format="MM/dd/yyyy"
                    value={toDate}
                    // onChange={setToDate}
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item>
                <Typography>
                  {location == "Portfolio"
                    ? "Current Stock"
                    : location !== "Portfolio" &&
                      singleUserStockList?.length > 0
                    ? "Current Stocks"
                    : null}
                </Typography>
                {location === "Portfolio" ? (
                  <Typography
                    style={{
                      color: localStorage.getItem("dark") ? "#000" : "#fff",
                      textAlign: "center",
                    }}
                  >
                    {portfolioStock[0]?.stockName}
                  </Typography>
                ) : singleUserStockList && singleUserStockList?.length > 0 ? (
                  <Select
                    fullWidth
                    labelId="stock-select-label"
                    value={selectedNewStock}
                    onChange={(e) => newStockHandler(e)}
                    style={{ textAlign: "center" }}
                  >
                    {[
                      { stockName: "All", stockId: "all" },
                      ...singleUserStockList,
                    ].map((stock, index) => (
                      <MenuItem key={index} value={stock?.stockId}>
                        {stock?.stockName}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </Grid>
              <Grid item>
                {singleUserPreviousStockList &&
                singleUserPreviousStockList?.length > 0 ? (
                  <>
                    <Typography>Old Stocks</Typography>
                    <Select
                      fullWidth
                      labelId="stock-select-label"
                      value={selectedOldStock}
                      onChange={(e) => oldStockHandler(e)}
                      style={{ textAlign: "center" }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {singleUserPreviousStockList &&
                        singleUserPreviousStockList.map((stock, index) => (
                          <MenuItem key={index} value={stock?.stockId}>
                            {stock?.stockName}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : null}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {transactionType === "Wallet"
                ? renderWalletTable()
                : renderStockTransactionTable()}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {renderPagination()}
          </Grid>
        </Grid>
      </div>

      {modelOpen && (
        <Modal
          open={modelOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className={classes.PinModalContainer}
        >
          <>
            <Grid item className={classes.modalHeading}>
              <Typography style={{ paddingLeft: "20px", fontSize: "1.4rem" }}>
                Order Details
              </Typography>
              <IconButton id="close-buy-stocks" onClick={closeModal}>
                <CancelOutlined style={{ fontSize: "2rem" }} />
              </IconButton>
            </Grid>
            <Grid item className={classes.PinSectionContainer}>
              <Grid
                item
                xs={12}
                sm={5}
                direction="column"
                justifyContent="flex-start"
                container
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "2rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Stock Name: &nbsp;{modalData?.stock_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Quantity: &nbsp;{modalData?.quantity}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Executed Quantity: &nbsp;
                    {modalData?.executed_quantity
                      ? modalData.executed_quantity
                      : 0}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Price Limit: &nbsp;{modalData?.price_limit}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    Wallet: &nbsp;{modalData?.wallet}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={1}
                direction="column"
                container
                className={classes.verticalDivider}
              >
                <Divider
                  style={{
                    background: localStorage.getItem("dark")
                      ? "#ccc"
                      : "#808080",
                  }}
                  orientation="vertical"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                direction="column"
                container
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                {disclamerScreen && modalData?.buyModal ? (
                  <>
                    <Typography
                      style={{ fontSize: "1rem", padding: "8% 5% 0" }}
                    >
                      If cancelled, you will get €{" "}
                      {(modalData?.quantity - modalData?.executed_quantity) *
                        modalData?.price_limit}{" "}
                      into your {modalData?.wallet} wallet as refund.
                    </Typography>

                    <Grid item className={classes.ButtonSection}>
                      <Button
                        onClick={() => {
                          handleConfirm();
                        }}
                        style={{
                          width: "30%",
                          height: "90%",
                          backgroundColor: "#505050",
                          color: "#fff",
                        }}
                      >
                        Confirm
                      </Button>

                      <Button
                        onClick={() => {
                          handleCancel();
                        }}
                        style={{
                          width: "30%",
                          height: "90%",
                          backgroundColor: "#ccc",
                          marginLeft: "0.5rem",
                          color: "#000",
                        }}
                      >
                        cancel
                      </Button>
                    </Grid>
                  </>
                ) : null}

                {disclamerScreen && !modalData?.buyModal ? (
                  <>
                    <Typography
                      style={{ fontSize: "1rem", padding: "5% 5% 0" }}
                    >
                      If cancelled, you will get €{" "}
                      {modalData?.quantity - modalData?.executed_quantity}{" "}
                      stocks back as refund.
                    </Typography>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleConfirm();
                        }}
                        style={{
                          width: "30%",
                          height: "90%",
                          backgroundColor: "#505050",
                          color: "#fff",
                        }}
                      >
                        Confirm
                      </Button>

                      <Button
                        onClick={() => {
                          handleCancel();
                        }}
                        style={{
                          width: "30%",
                          height: "90%",
                          backgroundColor: "#ccc",
                          marginLeft: "0.5rem",
                          color: "#000",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                ) : null}

                {!disclamerScreen && (
                  <Grid
                    item
                    flexDirection="column"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <Typography
                      style={{ fontSize: "1rem", padding: "8% 5% 0" }}
                    >
                      PIN
                    </Typography>
                    <Typography
                      style={{ fontSize: "1rem", padding: "1% 5% 0" }}
                    >
                      Confirm Your Transaction
                    </Typography>

                    <div style={{ padding: "4% 5% 0" }}>
                      <OTPInput
                        numInputs={6}
                        renderSeparator={<span style={{ margin: "2%" }}></span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className={classes.otpBox}
                            autoComplete="new-password"
                          />
                        )}
                        shouldAutoFocus
                        isInputNum={true}
                        inputType="password"
                        value={security}
                        onChange={setSecurity}
                        inputStyle={{
                          border: "1px solid transparent",
                          borderRadius: "8px",
                          color: localStorage.getItem("dark")
                            ? "black"
                            : "#DCDCDC",
                          fontWeight: "400",

                          backgroundColor: localStorage.getItem("dark")
                            ? "#c6c6c6"
                            : "#2e2e2e",
                        }}
                      />
                    </div>

                    <Button
                      onClick={() => {
                        deleteHandler();
                      }}
                      style={{
                        height: "2.3rem",
                        width: "30%",
                        margin: "5% 22% 0",
                      }}
                      className={classes.PayButton}
                      disabled={security?.length == 6 ? false : true}
                    >
                      {confirmLoader ? <SmallSpinner /> : "Confirm"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  singleUserStockList: userReducer.singleUserStock,
  stockTransactionData: userReducer.stockTransactionData,
  stockWalletTransactionData: userReducer.stockWalletTransactionData,
  transactionListLoading: userReducer.loadings.transactionList,
  walletTransactionListLoading: userReducer.loadings.walletDataLoading,
  totalWalletDataCount: userReducer.totalWalletDataCount,
  totalTransactionDataCount: userReducer.totalTransactionDataCount,
  singleUserPreviousStockList: userReducer.singleUserPreviousStock,
  stockTransactionExportData: userReducer.stockTransactionExportData,
  totalStockTransactionExportDataCount:
    userReducer.totalStockTransactionExportDataCount,
  walletTransactionExportData: userReducer.walletTransactionExportData,
  totalWalletTransactionExportDataCount:
    userReducer.totalWalletTransactionExportDataCount,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getStockTxnEngine: (data) =>
      dispatch(actionCreator.getStockTxnEngine(data)),
    getStockTxnExportEngine: (data) =>
      dispatch(actionCreator.getStockTxnExportEngine(data)),
    getWalletTxnEngine: (data) =>
      dispatch(actionCreator.getWalletTxnEngine(data)),
    getWalletTxnExportEngine: (data) =>
      dispatch(actionCreator.getWalletTxnExportEngine(data)),
    cancelTradeStockEngine: (data) =>
      dispatch(actionCreator.cancelTradeStockEngine(data)),
    getPrevInvestment: (data) =>
      dispatch(actionCreator.getPrevInvestment(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockTransactionList);
