import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  modalHeading: {
    width: "100%",
    height: 80,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  title: {
    fontSize: 25,
    color: "#ff7300",
    flex: 1,
    textAlign: "center",
  },
  closeIcon: {
    marginLeft: theme.spacing(2),
  },
}));
const ModalHeader = ({ title, handleClose }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.modalHeading}>
      <Typography
        variant="body1"
        className={classes.title}
        style={{
          marginTop: title === "KYC steps" || "My QR Code" ? "0" : "100px",
        }}
      >
        {title}
      </Typography>
      <IconButton
        id="closeIconButton"
        onClick={handleClose}
        className={classes.closeIcon}
      >
        <CancelIcon />
      </IconButton>
    </Grid>
  );
};

export default ModalHeader;
