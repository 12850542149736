import {
  makeStyles,
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import ThemeButton from "../components/common/button/index";
import callApi, { callApi_multipart, API } from "../asset/api";
import * as actionCreator from "../store/action/index";
import logodark from "../asset/images/Header/logo_dark.svg";
import { notify } from "../store/action/notifyAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  img: {
    width: "105%",

    [theme.breakpoints.down(1280)]: {
      width: "90%",
    },
    [theme.breakpoints.down(960)]: {
      width: "85%",
    },
  },
}));

const Unsubscribe = (props) => {
  const classes = useStyles();
  const { notify, profile } = props;
  const [subscribeStatus,setSubscribeStatus] = useState(false);
  const [show, setShow] = useState();
  const [id, setId] = useState(useParams().id || null);
  const [value, setValue] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const UnsubscribeImage =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/Unsubscribe.svg";


  const funCheck = () => {
    if (!subscribeStatus) {
      return new Promise((resolve, reject) => {
        let reqBody = {
          profileId: id,
        };

        callApi("GetSubscriptionStatus", "POST", reqBody)
          .then((res) => {
            const { status, successMessage, errorMessage, subStatus, error } = res;
            setSubscribeStatus(subStatus);
            if (successMessage.length) notify(successMessage, "success");
            if (errorMessage.length) notify(errorMessage, "error");
          })
          .catch((err) => {
            reject(err?.response?.data);
          });
      });
    }
  }

  useEffect(() => {
    funCheck()
  }, []);

  useEffect(() => {
    if (subscribeStatus) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [subscribeStatus]);

  useEffect(() => {
    const item = localStorage.getItem("token");
    if (item) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, []);

  const unsubscribeHandler = (id) => {
    return new Promise((resolve, reject) => {
      let reqBody = {
        id: id,
      };

      callApi("UnsubscribeUser", "POST", reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (successMessage.length) {
            notify(successMessage, "success");
          }
          if (errorMessage.length) notify(errorMessage, "error");
          setTimeout(() => {
            window.close();
          }, 500);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  };

  const closeHandler = (e) => {
    window.close();
  };




  return (
    <>
      <div className={classes.root}>
        {!value ? (
          <>
            <Grid container>
              <Grid item container lg={4} justify="flex-start">
                <Box>
                  <img className={classes.img} src={logodark} />
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}

        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item container lg={2} justify="center">
            <Box>
              <img className={classes.img} src={UnsubscribeImage} />
            </Box>
          </Grid>

          <Grid item container lg={8} justify="center">
            <Box py={{ lg: 4 }}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Do you really want to unsubscribe newsletter?
              </Typography>
              <Typography
                variant="h6"
                style={{ marginTop: "1.5rem", textAlign: "center" }}
              >
                If you unsubscribe now, you will stop reciving our monthly
                newsletter.
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <ThemeButton
                  background="#359DB6"
                  title="Unsubscribe"
                  size={14}
                  height={38}
                  width={140}
                  radius={11}
                  onClick={() => unsubscribeHandler(id)}
                  margin={"1.5rem 0rem 0rem 0rem"}
                  disabled={show}
                />
                <Button
                  variant="outline"
                  onClick={closeHandler}
                  style={{
                    background: theme.palette.background.default,
                    border: "1px solid #359DB6",
                    borderRadius: "2rem",
                    height: 38,
                    width: 140,
                    margin: "1.5rem 0rem 0rem 1rem",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
