import React, { useState, useEffect, createRef, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { CssBaseline, IconButton, Grid, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import Skeleton from "@material-ui/lab/Skeleton";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Notifier from "./components/common/notifier/index";
import LogIn from "./pages/login";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import UpdatePassword from "./components/forgotpassword/UpdatePassword";
// import Transaction from "./pages/transaction";
import Unsubscribe from "./pages/unsubscribe";
// import Uri from "./pages/uri";
// import Refer from "./pages/refer";
import RightSideTabs from "./components/rightSidebar/index";
// import Cryptopia from "./components/Cryptopia/index.js";
import ModalCommon from "./components/common/modal/index";
import ThemeButton from "./components/common/button/index";

import * as actionCreator from "./store/action/index";
import {
  setLocal,
  getLocal,
  removeLocal,
  setWithExpiry,
} from "./asset/utility";

import Header from "./components/header/Header";
import LeftSidebar from "./components/leftSidebar/index.jsx";
import RightSidebar from "./components/rightSidebar";
import Footer from "./components/footer/Footer";
import ChartIcon from "./components/chartbot";
// import Profile from "./pages/profile";
// import Business from "./pages/businessTabs";
// import Banking from "./components/refer/bankingTransTabs";
// import Home from "./pages/home";
import Register from "./pages/register";
import Signup from "./pages/signup";
import SessionExpired from "./components/preloggedin/SessionExpired";
import StaffRegister from "./pages/staffRegister";
import callApi, { API } from "../src/asset/api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slide from "@material-ui/core/Slide";
import ResetEmailPage from "./pages/resetEmail";
import SubscribePage from "./components/subscribe/subscribe";
// import Members from "./components/profile/addFamily/members";
import { useRef } from "react";
import Stocks from "./components/stocks";
import SocketConnection from "./sockets/socketConnection.js";
// import qrPage from "./components/profile/QRCode/qrPage";
// import StockDetailPage from "./components/stocks/newStocks/StockDetailPage.js";
import StaticPage from "./components/stocks/exchangeDirectoryPages/index.js";

// Lazy load your components
const Home = lazy(() => import("./pages/home"));
const Profile = lazy(() => import("./pages/profile"));
const Members = lazy(() => import("./components/profile/addFamily/members"));
const qrPage = lazy(() => import("./components/profile/QRCode/qrPage"));
const Banking = lazy(() => import("./components/refer/bankingTransTabs"));
// const Stocks = lazy(() => import("./components/stocks"));
const StockDetailPage = lazy(() =>
  import("./components/stocks/newStocks/StockDetailPage")
);
const Transaction = lazy(() => import("./pages/transaction"));
const Refer = lazy(() => import("./pages/refer"));
const Business = lazy(() => import("./pages/businessTabs"));
const Uri = lazy(() => import("./pages/uri"));
const Cryptopia = lazy(() => import("./components/Cryptopia/index.js"));

const themeLight = createTheme({
  palette: {
    type: "light",
    background: {
      default: "#fafafa",
    },
  },
});

const themeDark = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#1A1A1A",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    overflow: "hidden",
    padding: 0,
    [theme.breakpoints.down(1000)]: {
      overflowY: "scroll",
    },
  },
  esRoot: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    padding: 0,
  },
  header: {
    width: "100%",
    height: "fit-content",
  },
  main: {
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
    width: "100%",
  },
  escrowMain: {
    display: "flex",
    height: "fit-content",
    width: "100%",
  },
  mainContainer: {
    padding: "0 5vh",
    // width: "100%",
    overflow: "scroll",
    height: "82vh",
    width: "68%",
    marginTop: "1rem",
    overflowX: "hidden",
    [theme.breakpoints.down(992)]: {
      width: "93%",
      // paddingLeft: "2rem",
      height: "100vh",
    },
    [theme.breakpoints.down(600)]: {
      width: "99%",
      padding: "2rem 1rem 2rem 1.5rem",
      marginBottom: "2rem",
    },
  },
  escrowMainContainer: {
    padding: "0 5vh",
    // width: "100%",
    overflow: "scroll",
    height: "82vh",
    width: "91.5%",
    marginTop: "1rem",
    overflowX: "hidden",
    [theme.breakpoints.down(992)]: {
      width: "93%",
      // paddingLeft: "2rem",
      height: "100vh",
    },
    [theme.breakpoints.down(600)]: {
      width: "99%",
      padding: "2rem 1rem 2rem 1.5rem",
      marginBottom: "2rem",
    },
  },
  sideBarContainer: {
    display: "flex",
    alignItems: "flex-start",
    height: "fit-content",
    width: "7%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "7.3rem",
      margin: 0,
      padding: 0,
      zIndex: 12,
      height: "70%",
      bottom: 10,
    },
  },
  rightSideBarContainer: {
    paddingLeft: "1rem",
    // overflow: "scroll",
    display: "flex",
    alignItems: "flex-start",
    width: "25%",
    marginTop: "5vh",
  },
  rightSideBarContainer1: {
    height: "3rem",
    width: "100%",
    // [theme.breakpoints.between(760,992)]: {
    // display: 'none',
    // },
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      //backgroundColor: "#242424",
      width: "0.6rem",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#3298b1",
      outline: "0.5px solid #3298b1",
      borderRadius: "5px",
    },
  },
}));

const App = (props) => {
  const {
    kyc,
    profile,
    getProfile,
    notify,
    logout,
    updateUser,
    esWindow,
    getCryptopiaAgreementForm,
    cryptopiaData,
    getKycStatus,
    userKYC,
    isCryptopiaDataAvailable,
    isCryptopiaDataAvailableStatus,
  } = props;

  const modalContent = () => {
    return (
      <Typography style={{ textAlign: "center" }}>
        Your previous KYC verification is no longer valid. To ensure
        uninterrupted access to banking and other essential features, please
        complete your KYC verification again as soon as possible. We appreciate
        your prompt attention to this matter.
        {/* <Typography
        onClick={() => {
          setKycModalShown(true);
          window.location = "/profile";
          setOpen(false);
        }}
      >
        {`Goto Profile >`}
      </Typography> */}
        <div>
          <ThemeButton
            title="Go To Profile"
            width="30%"
            margin={"20px"}
            onClick={() => {
              window.location = "/profile";
            }}
          />
        </div>
      </Typography>
    );
  };

  const cryptopiaFormModalContent = () => {
    return (
      <Typography style={{ textAlign: "center" }}>
        It looks like you haven't filled out your Cryptopia form yet. Please
        complete it by clicking the button below. Your prompt response is
        appreciated!
        {/* <Typography
          onClick={() => {
            setKycModalShown(true);
            window.location = "/profile";
            setOpen(false);
          }}
        >
          {`Goto Profile >`}
        </Typography> */}
        <div>
          <ThemeButton
            title="Fill your form here"
            width="30%"
            margin={"20px"}
            onClick={() => {
              window.open(
                "https://principalityofcogito.com/cryptopiaform",
                "_blank"
              );
            }}
          />
        </div>
      </Typography>
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [cryptopiaFormModalOpen, setCrptopiaFormModalOpen] =
    React.useState(false);
  const handleCryptopiaFormModalClose = () => setCrptopiaFormModalOpen(false);

  const matches = useMediaQuery("(max-width:992px)");
  // for mobile view
  const [showLeftSideBar, setShowLeftSideBar] = useState(
    isMobile ? false : true
  );
  // for web view
  const [kycModalShown, setKycModalShown] = useState(false);
  const [showLeftSideBar1, setShowLeftSideBar1] = useState(true);
  const classes = useStyles();
  const { ResetErrors, IsLogInAsync, isLoggedIn, checking, loading } = props;
  const [darkMode, setDarkMode] = useState(
    getLocal("dark") === "false" ? false : true
  );

  const currentLocation = useLocation();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    if (isLoggedIn) {
      getKycStatus();
      isCryptopiaDataAvailableStatus();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // sumsubbed users
    if (
      window.location.pathname === "/home" &&
      kyc?.status &&
      kyc?.id &&
      userKYC === null
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (
      window.location.pathname === "/home" &&
      kyc?.status &&
      kyc?.id &&
      userKYC?.status === "completed" &&
      isCryptopiaDataAvailable &&
      cryptopiaData === null
    ) {
      setCrptopiaFormModalOpen(true);
    } else {
      setCrptopiaFormModalOpen(false);
    }
  }, [window.location.pathname, kyc, userKYC, isCryptopiaDataAvailable]);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      IsLogInAsync();
    }
    return () => {
      ResetErrors();
    };
  }, [ResetErrors, IsLogInAsync]);

  useEffect(() => {
    if (!profile && isLoggedIn) {
      getProfile();
    }
  }, [profile]);

  useEffect(() => {
    if (profile && profile.dateOfBirth) {
      var age = moment().diff(profile?.dateOfBirth, "years");
      if (age <= 16 && !profile.isMinor) {
        updateUser({ changes: { isMinor: true } });
      } else if (age > 16 && profile.isMinor) {
        updateUser({ changes: { isMinor: false } });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!cryptopiaData && profile) {
      getCryptopiaAgreementForm();
    }
  }, [cryptopiaData, profile]);

  const refreshToken = () => {
    callApi("RefreshToken", "POST", {})
      .then((res) => {
        const { status, successMessage, errorMessage, error, token } = res;
        if (status) {
          setWithExpiry("token", token, 1);
        }
      })
      .catch((err) => notify(err?.response?.data?.errorMessage, "error"));
  };

  // refreshing token
  useEffect(() => {
    try {
      let expiryTimestamp = JSON.parse(localStorage?.getItem("token"));
      if (isLoggedIn) {
        const interval1 = setInterval(function () {
          expiryTimestamp = JSON.parse(localStorage?.getItem("token"));
          if (expiryTimestamp) {
            if (
              new Date().getTime() + 240000 > expiryTimestamp?.expiry &&
              !document.hidden
            ) {
              refreshToken();
            } else if (
              new Date().getTime() + 240000 > expiryTimestamp?.expiry &&
              document.hidden
            ) {
              logout();
              window.location = "/sessionExpired";
            }
          } else {
            logout();
            window.location = "/sessionExpired";
          }
        }, 3 * 60 * 1000);
        return () => {
          clearInterval(interval1);
        };
        // 360000 = 6 min, 300000 = 5 min, 720000 = 12 min, 240000 = 4 min
      }
    } catch (err) {
      console.log("JSON.parse Error: ", err);
    }
  }, [isLoggedIn, profile]);

  const changeTheme = (value) => {
    {
      value ? removeLocal("dark") : setLocal("dark", value);
    }
    setDarkMode(value);
  };

  // add action to all snackbarss
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const authRoutes = (
    <Switch>
      <Route path="/" component={LogIn} exact></Route>
      <Route path="/register/:token?" component={Register}></Route>
      <Route path="/signup/:ref?" component={Signup}></Route>
      <Route path="/login/:ref?" component={LogIn}></Route>
      <Route path="/forgotpassword/" component={ForgotPassword}></Route>
      <Route path="/resetpassword/:token" component={UpdatePassword}></Route>
      <Route path="/sessionExpired" component={SessionExpired} />
      <Route path="/staff/register/:token" component={StaffRegister}></Route>
      <Route path="/resetemail/:token?" component={ResetEmailPage}></Route>
      <Route path="/unsubscribe/:id" component={Unsubscribe}></Route>
      <Route path="/deleteprofile/:token" component={StaticPage}></Route>

      <Redirect
        to={{
          pathname: "/login",
          state: { prevLocation: currentLocation },
        }}
      />
    </Switch>
  );
  const UserRouteSwitch = (
    <>
      <SocketConnection />
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/home" component={Home}></Route>
          <Route path="/profile" component={Profile}></Route>
          <Route exact path="/family" component={Members} />
          <Route exact path="/qrcode" component={qrPage} />
          <Route path="/banking" component={Banking}></Route>
          <Route exact path="/stocks" component={Stocks}></Route>
          <Route path="/stocks/:stockName" component={StockDetailPage}></Route>
          <Route path="/transactions" component={Transaction}></Route>
          <Route path="/refer" component={Refer}></Route>
          <Route path="/business" component={Business}></Route>
          {profile?.type !== "company" && (
            <Route path="/uri" component={Uri}></Route>
          )}
          <Route path="/cryptopia" component={Cryptopia}></Route>
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    </>
  );
  const UserRoutes = (
    <>
      {!matches ? (
        //web view

        <Grid container className={esWindow ? classes.esRoot : classes.root}>
          <ModalCommon
            title={"Renew your KYC"}
            data={modalContent()}
            open={open && !kycModalShown}
            handleClose={handleClose}
          />
          <ModalCommon
            title={"Fill your Cryptopia Form"}
            data={cryptopiaFormModalContent()}
            open={cryptopiaFormModalOpen}
            handleClose={handleCryptopiaFormModalClose}
          />
          <Grid item xs={12} className={classes.header}>
            <Header
              leftSideVisible={setShowLeftSideBar}
              showLeft={showLeftSideBar}
              changeTheme={changeTheme}
              darkMode={darkMode}
            />
          </Grid>
          <Grid
            container
            item
            className={esWindow ? classes.escrowMain : classes.main}
          >
            {true && (
              <Grid item className={classes.sideBarContainer}>
                <LeftSidebar
                  kycDone={kyc?.status && userKYC?.status === "completed"}
                  leftSideVisible={setShowLeftSideBar1}
                  showLeft={showLeftSideBar1}
                  cryptData={cryptopiaData}
                />
              </Grid>
            )}
            <Grid
              item
              className={
                esWindow ? classes.escrowMainContainer : classes.mainContainer
              }
            >
              {UserRouteSwitch}
              <Footer />
            </Grid>
            <Grid
              className={
                esWindow
                  ? classes.rightSideBarContainer1
                  : classes.rightSideBarContainer
              }
            >
              {/* <RightSidebar /> */}
              <RightSideTabs location={window.location.pathname} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // mobile view ie. <992px
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.header}>
            <Header
              leftSideVisible={setShowLeftSideBar}
              showLeft={showLeftSideBar}
              changeTheme={changeTheme}
              darkMode={darkMode}
            />
          </Grid>
          <Grid container item className={classes.main}>
            {showLeftSideBar && (
              <Slide
                direction="right"
                in={showLeftSideBar}
                mountOnEnter
                unmountOnExit
              >
                <Grid item className={classes.sideBarContainer}>
                  <LeftSidebar
                    kycDone={kyc?.status}
                    leftSideVisible={setShowLeftSideBar}
                    showLeft={showLeftSideBar1}
                    cryptData={cryptopiaData}
                  />
                </Grid>
              </Slide>
            )}
            <Grid item className={classes.mainContainer}>
              {UserRouteSwitch}
              <Footer />
            </Grid>
          </Grid>
          <Grid container item className={classes.rightSideBarContainer1}>
            <RightSidebar />
          </Grid>
        </Grid>
      )}
      <ChartIcon />
    </>
  );
  return (
    <ThemeProvider theme={darkMode ? themeDark : themeLight}>
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClickDismiss(key)}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      >
        <CssBaseline />
        <Notifier />
        {checking ? (
          <Skeleton variant="rect" width="100%" height={100} />
        ) : (
          <React.Fragment>
            {isLoggedIn ? UserRoutes : authRoutes}
          </React.Fragment>
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ authReducer, notifyReducer, userReducer }) => {
  return {
    userKYC: userReducer.userKYC,
    checking: authReducer.checking,
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
    notifications: notifyReducer.notifications,
    kyc: userReducer.kyc,
    profile: userReducer.profile,
    esWindow: userReducer.esWindow,
    cryptopiaData: userReducer.cryptopiaData,
    isCryptopiaDataAvailable: userReducer.isCryptopiaDataAvailable,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKycStatus: () => dispatch(actionCreator.getKYC()),
    IsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
    getProfile: () => dispatch(actionCreator.getProfile()),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    logout: () => dispatch(actionCreator.OnLogout()),
    getCryptopiaAgreementForm: () =>
      dispatch(actionCreator.getCryptopiaAgreementForm()),
    isCryptopiaDataAvailableStatus: () =>
      dispatch(actionCreator.isCryptopiaDataAvailable()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
