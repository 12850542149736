import React from "react";

const formatNumber = (value) => {
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return value;
  }

  let formattedValue;
  let hasMoreThanTwoDecimals = false;

  if (Math.abs(numericValue) >= 1.0e12) {
    formattedValue = (numericValue / 1.0e12).toFixed(2) + "T";
  } else if (Math.abs(numericValue) >= 1.0e9) {
    formattedValue = (numericValue / 1.0e9).toFixed(2) + "B";
  } else if (Math.abs(numericValue) >= 1.0e6) {
    formattedValue = (numericValue / 1.0e6).toFixed(2) + "M";
  } else if (Math.abs(numericValue) >= 1.0e3) {
    formattedValue = (numericValue / 1.0e3).toFixed(2) + "K";
  } else {
    formattedValue = numericValue.toFixed(2);
  }

  const originalDecimalPart = numericValue.toString().split(".")[1];
  if (originalDecimalPart && originalDecimalPart.length > 2) {
    hasMoreThanTwoDecimals = true;
  }

  return hasMoreThanTwoDecimals ? formattedValue + "..." : formattedValue;
};

const NumberWithTooltip = ({ value, position }) => {
  const formattedValue = formatNumber(value);
  const numericValue = Number(value);
  const showTooltip =
    !isNaN(numericValue) && numericValue.toString() !== formattedValue;

  return (
    <span
      title={showTooltip ? value : ""}
      style={{
        cursor: showTooltip ? "pointer" : "default",
        fontSize:
          position === "profit_loss"
            ? "0.8rem"
            : position == "wallet"
            ? "1.5rem"
            : "1rem",
      }}
    >
      € {formattedValue}
    </span>
  );
};

export default NumberWithTooltip;
