import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { Box, ButtonBase, InputAdornment } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import Spinner from "../../components/common/Spinner/Spinner";
import { Typography } from "@material-ui/core";
import ThemeButton from "../common/button";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import FallBackImg from "../../asset/images/stocks/fallback.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingBottom: "5rem",

    [theme.breakpoints.down(950)]: {
      marginTop: "1rem",
      width: "100%",
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: "16px",
    background: "#359Db6",
    marginTop: "2vh",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
    width: "100%",
    maxWidth: 220,
    "&:disabled": {
      background: "#9ACEDB",
      color: "#919191",
    },
  },
  titleStyle: {
    fontSize: 22,
    fontWeight: 600,
    minHeight: 40,
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: "2rem",
  },
  iframeContainer: {
    width: "100%",
    maxWidth: "960px", // Ensures the iframe width matches the preview area
    margin: "0 auto", // Centers the iframe
    border: "none",
    boxSizing: "border-box", // Ensures padding is included in the width and height
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0",
  },
  filterSearchField: {
    width: "50%",
    maxWidth: "24rem",
    borderRadius: 50,
  },
  categoryFilterContainer: {
    position: "relative",
  },
  categoryFilterButton: {
    borderRadius: 15,
    border: "1px solid #646464",
    padding: "0.48rem",
    fontSize: "0.8rem",
  },
  categoryOptionsContainer: {
    position: "absolute",
    top: "3.2rem",
    padding: "0.5rem",
    left: 0,
    backgroundColor: "#2D2D2D",
    borderRadius: 12,
    zIndex: 3,
  },
  categoryOption: {
    display: "flex",
    justifyContent: "center",
  },
  categoryOptionBorder: {
    borderTop: "1px solid #656565",
  },
  selected: {
    backgroundColor: "#656565",
  },
}));

const StockExchangePage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { stockDetailsLoading, getStockTemplate, stockDetailsData } = props;
  const [stockName, setStockName] = useState(useParams().stockName || null);

  useEffect(() => {
    getStockTemplate({ stockName: stockName });
  }, [stockName]);

  const adjustIframeHeight = () => {
    const iframe = document.getElementById("stock-iframe");
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + "px";
  };

  return (
    <>
      <Grid container xs={12} className={classes.container}>
        {!stockDetailsLoading ? (
          <>
            <Grid item xs={12}>
              <Box className={classes.filterContainer}>
                <ThemeButton
                  title="Back"
                  cursor="pointer"
                  width="100px"
                  id="backFromAddingBankAccount"
                  height="35px"
                  color="#fff"
                  border="2px solid #359DB6"
                  onClick={() => {
                    history.push("/stocks");
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {stockDetailsData && stockDetailsData?.length > 0 ? (
                <iframe
                  id="stock-iframe"
                  className={classes.iframeContainer}
                  srcDoc={stockDetailsData?.[0]?.template}
                  title="Template Preview"
                  width="100%"
                  height="100%"
                  onLoad={adjustIframeHeight}
                  scrolling="no"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={FallBackImg}
                    alt="Fallback GIF"
                    height="250px"
                    width="300px"
                  />
                  <Typography
                    style={{ textAlign: "center", padding: "0 10rem" }}
                  >
                    Please wait, we apologize for the inconvenience. The
                    detailed business information for this stock is currently
                    unavailable. Please check back later for updates.
                  </Typography>
                </div>
              )}
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    stockDetailsData: userReducer.stockDetailsData,
    stockDetailsLoading: userReducer.loadings.stockDetailsLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockTemplate: (data) => dispatch(actionCreator.getStockTemplate(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StockExchangePage);
