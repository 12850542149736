import * as actionTypes from "./actionTypes";
import axios from "axios";

import callApi from "../../asset/api";
import {
 setWithExpiry,
 getWithExpiry,
 deleteAllCookies,
} from "../../asset/utility";
import { notify, closeSnackbar } from "./notifyAction";
import { getProfile } from "./userAction";
// import { closeSocket } from "./socketAction";

const setLoading = () => {
 return {
  type: actionTypes.SET_LOADING,
 };
};

export const setStatus = (data) => {
 return {
  type: actionTypes.SET_STATUS,
  payload: data,
 };
};

const setUser = (data) => {
 return {
  type: actionTypes.SET_USER,
  payload: data,
 };
};

export const ResetErrors = () => {
 return (dispatch, getState) => {
  dispatch(
   setStatus({
    error: null,
    successMessage: "",
    errorMessage: "",
   })
  );
 };
};

export const Signup = ({ email, type, ref }, callback) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   email: email,
   type: type,
   ref: ref,
  };

  callApi("SignUp", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res;
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
    if (status) {
     if (callback) {
      callback();
     }
     dispatch(notify(successMessage, "success"));
    }
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data.error || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
   });
 };
};

export const setRegUser = (data) => {
 return {
  type: actionTypes.SET_REG_USER,
  payload: data,
 };
};

export const confirmOTP = ({ email, otp }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   email: email,
   otp: otp,
  };

  callApi("Confirm", "POST", reqBody)
   .then((res) => {
    const {
     status,
     successMessage,
     errorMessage,
     user,
     registered,
     error,
     token,
    } = res;
    dispatch(
     setRegUser({
      user: user,
      registered: registered,
     })
    );

    if (status) {
     let keysToRemove = ["email", "timer"];
     keysToRemove.forEach((k) => localStorage.removeItem(k));
     // localStorage.setItem(
     // 	"regUser",
     // 	JSON.stringify({
     // 		user: user,
     // 		registered: registered,
     // 	})
     // );
     setWithExpiry(
      "regUser",
      {
       user: user,
       registered: registered,
      },
      0.5 * 7 // 30min
     );
     localStorage.setItem("token", JSON.stringify(token));
    }
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
    // localStorage.removeItem("email");
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data?.error || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
    // localStorage.removeItem("email");
   });
 };
};

export const confirmToken = ({ token }) => {
	//# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
	return (dispatch, getState) => {
		//# HERE MAKE ASYNC CALLS.
		dispatch(setLoading());
		const reqBody = {
			token: token,
		};

		callApi("ConfirmToken", "POST", reqBody)
			.then((res) => {
				const { status, successMessage, errorMessage, user, registered, error } =
					res;
				dispatch(
					setRegUser({
						user: user,
						registered: registered,
					})
				);
        localStorage.setItem("token", JSON.stringify(token));
				dispatch(
					setStatus({
						error: error,
						successMessage: successMessage,
						errorMessage: errorMessage,
					})
				);
			})
			.catch((err) => {
				dispatch(
					setStatus({
						error: err?.response?.data?.error || err,
						errorMessage:
							err?.response?.data?.errorMessage || "Please try again by refresh..!",
					})
				);
			});
	};
};

export const register = (data) => {
 return {
  type: actionTypes.REGISTER,
  payload: data,
 };
};

export const RegisterAsync = (user) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = user;

  callApi("Register", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res;
    dispatch(
     register({
      registered: status,
     })
    );
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
    // if (status) localStorage.removeItem("ref");
   })
   .catch((err) => {
    dispatch(notify(err?.response?.data?.errorMessage, "error"));
    dispatch(
     setStatus({
      error: err?.response?.data?.error || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
      successMessage: err?.response?.data?.successMessage || "",
     })
    );
   });
 };
};

const logIn = (data) => {
 return {
  type: actionTypes.LOGIN,
  payload: data,
 };
};

export const LogInAsync = (user, history, prevLocation) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = { ...user, channel: "cm-website" };

  callApi("Login", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, token, user, error } = res;
    if (status) {
     setWithExpiry("token", token, 1);
    }
    dispatch(
     logIn({
      status: status,
     })
    );
    history.push(prevLocation);
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
    dispatch(
     setUser({
      user: user,
     })
    );
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
   });
 };
};

export const ForgotPassword = (email) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   email: email,
  };
  callApi("ForgotPassword", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res;
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
   });
 };
};

export const ResetPassword = ({ token, password }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   token: token,
   password: password,
  };
  callApi("ResetPassword", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res;
    if (status) {
     dispatch(notify(successMessage, "success"));
    }
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
   })
   .catch((err) => {
    dispatch(notify(err?.response?.data?.errorMessage, "error"));
    dispatch(
     setStatus({
      error: err?.response?.data || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
   });
 };
};

const isLogIn = (data) => {
 return {
  type: actionTypes.IS_LOG_IN,
  payload: data,
 };
};

export const IsLogInAsync = () => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return async (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  let storedToken = getWithExpiry("token");
  const reqBody = {
   token: storedToken,
  };

  callApi("IsLoggedIn", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, user, error } = res;
    dispatch(
     isLogIn({
      status: status,
     })
    );
    if (user) {
     dispatch(
      setUser({
       user: user,
      })
     );
    }
   })
   .catch((err) => {
    dispatch(
     isLogIn({
      status: false,
     })
    );
   });
 };
};

export const logoutActivity = () => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

 return new Promise((response, reject) => {
  //# HERE MAKE ASYNC CALLS.
  let storedToken = getWithExpiry("token");
  const reqBody = {
   token: storedToken,
   channel: "cm-website",
  };

  callApi("logoutActivity", "POST", reqBody)
   .then((res) => {
    response(res);
   })
   .catch((err) => {
    reject(err);
   });
 });
};

const logout = () => {
 return {
  type: actionTypes.LOGOUT,
 };
};

export const OnLogout = () => {
 return async (dispatch, getState) => {
  await logoutActivity();
  dispatch(setLoading());
  dispatch(closeSnackbar());
  localStorage.clear();
  deleteAllCookies();
  window.location.replace("/login");
  //dispatch(logout());
 };
};

export const ResendPasswordOTP = ({ email }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   email: email,
  };

  callApi("resendPasswordOtp", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error } = res;
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
    if (status) {
     dispatch(notify(successMessage, "success"));
    }
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data.error || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
   });
 };
};

export const confirmForgotPasswordOTP = ({ email, otp }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return (dispatch, getState) => {
  //# HERE MAKE ASYNC CALLS.
  dispatch(setLoading());
  const reqBody = {
   email: email,
   otp: otp,
  };

  callApi("confirmForgotPasswordOtp", "POST", reqBody)
   .then((res) => {
    const { status, successMessage, errorMessage, error, token } = res;

    if (status) localStorage.setItem("otp_token", token);
    dispatch(
     setStatus({
      error: error,
      successMessage: successMessage,
      errorMessage: errorMessage,
     })
    );
   })
   .catch((err) => {
    dispatch(
     setStatus({
      error: err?.response?.data?.error || err,
      errorMessage:
       err?.response?.data?.errorMessage || "Please try again by refresh..!",
     })
    );
    // localStorage.removeItem("email");
   });
 };
};

export const deleteProfile = ({ token }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return (dispatch, getState) => {
  return new Promise((resolve, reject) => {
   //# HERE MAKE ASYNC CALLS.

   const reqBody = {
    token,
   };

   callApi("deleteProfile", "POST", reqBody)
    .then((res) => {
     const { status, successMessage, errorMessage, error } = res;
     dispatch(
      setStatus({
       error: error,
       successMessage: successMessage,
       errorMessage: errorMessage,
      })
     );
     if (status) {
      resolve(res);
     } else {
      reject(res);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 };
};

export const deleteProfileMail = ({ pin }) => {
 //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
 return async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
   //# HERE MAKE ASYNC CALLS.

   const reqBody = {
    pin,
   };

   callApi("deleteprofilemail", "POST", reqBody)
    .then((res) => {
     const { status, successMessage, errorMessage, error } = res;
     dispatch(
      setStatus({
       error: error,
       successMessage: successMessage,
       errorMessage: errorMessage,
      })
     );
     if (status) {
      resolve(res);
     } else {
      reject(res);
     }
    })
    .catch((err) => {
     reject(err);
    });
  });
 };
};
