import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
    notifications: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENQUEUE_SNACKBAR:
            return updateObject(state ,{
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            });

        case actionTypes.CLOSE_SNACKBAR:
            return updateObject(state ,{
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            });

        case actionTypes.REMOVE_SNACKBAR:
            return updateObject(state ,{
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            });

        default:
            return state;
    }
};