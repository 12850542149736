export const priceFormatter = (price) => {
  if (!isNaN(price)) {
    const [integerPart, decimalPart] = price.toString().split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    let formattedDecimalPart = decimalPart ? decimalPart.slice(0, 9) : "";

    return formattedDecimalPart
      ? `${formattedIntegerPart}.${formattedDecimalPart}`
      : formattedIntegerPart;
  } else {
    return "0";
  }
};
export const formatPrice = (price) => {
  const decimatLimit = 6;
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimatLimit,
  }).format(price);
};
