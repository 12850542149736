import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Nav from "../components/preloggedin/Nav";
import * as actionCreator from "../store/action/index";
import CheckYourEmailPage from "../components/Signup/checkYourEmailPage";
import Sidebar from "../components/preloggedin/Sidebar";
import Spinner from "../components/common/Spinner/Spinner";
import SmallSpinner from "../components/common/smallSpinner/smallSpinner";
import { validateField } from "../components/business/businessUtility";

import {
  updateObject,
  updateObjectProperty,
  setWithExpiry,
  getWithExpiry,
} from "../asset/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    "& label.Mui-focused": {
      color: "#359DB6",
    },
    "& .MuiInputBase-root": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#359Db6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "#359Db6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#359Db6",
      },
    },
  },

  input: {
    color: "grey",
  },

  inputText: {
    color: "#000",
  },

  buttonContainer: {
    background: "red",
    width: isMobile ? "40vw" : "15vw",
    fontSize: 18,
    margin: "auto",
    height: isMobile ? 50 : 48,
    borderRadius: 8,
    color: "#fff",
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
    "&:disabled": {
      background: "#9ACEDB",
      color: "#919191",
    },
  },
  resendOTP: {
    cursor: "pointer",
    color: "#FF7300",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "#FF7300",
    },
  },
}));

const SignUpForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [otpSent, setOtpSent] = useState(false);
  const { errorMessage, successMessage, Signup, loading, confirmOTP } = props;
  const [errorObj, setErrorObj] = useState({});
  const [user, setUser] = useState({
    email: localStorage.getItem("email") || "",
    ref: "",
  });

  const [onSend, setOnSend] = useState({
    email: localStorage.getItem("email") || "",
    otp: "",
  });

  const [timer, setTimer] = useState({
    minutes: localStorage.getItem("timer")
      ? JSON.parse(localStorage.getItem("timer")).minutes
      : 0,
    seconds: localStorage.getItem("timer")
      ? JSON.parse(localStorage.getItem("timer")).seconds
      : 5,
  });

  const paramRef = useParams().ref || null;
  const storedRef = getWithExpiry("ref") || null;
  const [showRef, setShowRef] = useState(paramRef || storedRef ? true : false);

  useEffect(() => {
    if (paramRef && showRef) {
      setUser((user) => updateObject(user, { ref: paramRef }));
      setWithExpiry("ref", paramRef, 24 * 7);
    } else if (storedRef && showRef) {
      setUser((user) => updateObject(user, { ref: storedRef }));
    }
  }, [paramRef, showRef, storedRef, setWithExpiry]);

  useEffect(() => {
    if (successMessage) setOtpSent(true);
    if (successMessage === "OTP verified") {
      if (window.location.pathname.includes("/signup")) {
        history.push("/register");
      }
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage === "OTP already sent!") {
      localStorage.setItem("email", user.email);
      setOtpSent(true);
    }
    if (errorMessage === "OTP expired!") {
      setTimer({ ...timer, minutes: 0, seconds: 0 });
    }
  }, [errorMessage]);

  // useEffect(() => {
  // 	if (user.email && user.email === onSend.email) {
  // 		// setTimer({
  // 		// 	minutes: JSON.parse(localStorage.getItem("timer")).minutes,
  // 		// 	seconds: JSON.parse(localStorage.getItem("timer")).seconds,
  // 		// });
  // 		setOtpSent(true);
  // 	}
  // }, [user.email]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (timer.seconds > 0) {
        setTimer({ ...timer, seconds: timer.seconds - 1 });
      }
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          clearInterval(myInterval);
        } else {
          setTimer({
            ...timer,
            minutes: timer.minutes - 1,
            seconds: 59,
          });
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  const onInputValueChangedUser = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUser(updateObjectProperty(user, name, value));
    if (name === "email") {
      setOtpSent(false);
      setTimer({ minutes: 4, seconds: 59 });
    }
    setErrorObj(validateField(e.target.name, e.target.value, errorObj));
  };

  const onInputValueChangedOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOnSend(updateObjectProperty(onSend, name, value));
  };

  const setTimerDetails = () => {
    setOnSend({ ...onSend, email: user.email });
    localStorage.setItem("email", user.email);
    setTimer({ minutes: 4, seconds: 59 });
  };

  const handleResend = () => {
    Signup(user, setTimerDetails);
    // setTimer({ ...timer, minutes: 4, seconds: 59 });
  };

  // const onRefferalCodeChange = (value) => {
  // 	setUser(updateObjectProperty(user, "ref", value));
  // };

  // const removeRefferalCode = () => {
  // 	setUser(updateObjectProperty(user, "ref", ""));
  // 	setShowRef(false);
  // 	localStorage.removeItem("ref");
  // };

  window.onbeforeunload = function (event) {
    localStorage.setItem("timer", JSON.stringify(timer));
  };

  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  //   return (
  //     <div>
  //       <input
  //         id="message"
  //         name="message"
  //         value={message}
  //         onChange={handleChange}
  //       />
  //       {error && <h2 style={{color: 'red'}}>{error}</h2>}
  //     </div>
  //   );
  // }
  return (
    <Grid container style={{ height: "100vh", background: "#fff" }}>
      <Sidebar />
      <Grid style={{ color: "black" }} item xs={12} sm={12} md={8}>
        <Grid
          container
          align="center"
          direction="column"
          style={{ height: isMobile ? "85vh" : "100vh" }}
        >
          <Nav detailOne={"Already have an account? "} detailTwo={"Sign In"} />

          <Grid
            container
            // style={{ height: isMobile ? "80vh" : "95vh" }}
            align="center"
            justify="center"
            direction="column"
          >
            <Box
              // onSubmit={handleSubmit}
              style={{
                height: isMobile ? "80vh" : "95vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "black",
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    marginBottom: "2.5rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "2rem",
                      textShadow: "0px 1px 2px #787A91",
                    }}
                  >
                    Sign up
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Enter your email here..."
                    name="email"
                    type="email"
                    value={user.email}
                    autoFocus
                    className={classes.root}
                    error={errorObj.hasOwnProperty("email")}
                    InputLabelProps={{
                      className: classes.input,
                    }}
                    InputProps={classes.inputText}
                    onChange={onInputValueChangedUser}
                    helperText={errorObj?.email}
                  />
                </Grid>
                {showRef ? (
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="referral_code"
                      type="text"
                      label="Referral code"
                      id="referral_code"
                      className={classes.root}
                      InputLabelProps={{
                        className: classes.input,
                      }}
                      InputProps={classes.inputText}
                      value={user.ref}
                      // onChange={(e) => onRefferalCodeChange(e.target.value)}
                    />
                    {/* <Typography
                        				align="left"
                        				variant="caption"
                        				component="h3"
                        				color="primary"
                        				style={{ cursor: "pointer" }}
                        				onClick={removeRefferalCode}
                      					>				
                      >
                      					>				
                      >
                      					>				
                        				Remove Referral Code
                      					</				Typography> */}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={8}
                    style={{
                      marginBottom: "0.2rem",
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      style={{
                        color: "#ff7300",
                      }}
                    >
                      You don't have any referral code
                    </Typography>
                  </Grid>
                )}

                {/* otp section */}
                {otpSent && (
                  <>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        margin="normal"
                        id="otp"
                        type="tel"
                        name="otp"
                        placeholder="Enter your 6 digit OTP here..."
                        label="Enter OTP"
                        autoFocus
                        className={classes.root}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                        InputProps={classes.inputText}
                        onChange={onInputValueChangedOtp}
                      />
                    </Grid>

                    {timer.minutes == 0 && timer.seconds == 0 ? (
                      <Grid xs={8}>
                        <Typography
                          align="right"
                          variant="caption"
                          component="h3"
                          color="primary"
                          className={classes.resendOTP}
                          onClick={handleResend}
                          style={{
                            marginTop: "0rem",
                          }}
                        >
                          Resend OTP
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid xs={8}>
                        <Typography
                          align="right"
                          variant="caption"
                          style={{
                            // color: "#075062",
                            color: "#FF7300",
                            marginBottom: "1rem",
                          }}
                        >
                          Resend OTP in{" "}
                          <strong>
                            {" "}
                            {timer.minutes}:
                            {timer.seconds < 10
                              ? `0${timer.seconds}`
                              : timer.seconds}{" "}
                          </strong>
                          seconds
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}

                {/* <Grid item xs={8}> */}
                {errorMessage.length && !loading ? (
                  <Grid item xs={8}>
                    <Alert
                      severity="error"
                      icon={false}
                      style={{
                        background: "#FCDDC4",
                        color: "#FE7B10",
                      }}
                    >
                      <strong>Error: {errorMessage}</strong>
                    </Alert>
                  </Grid>
                ) : null}
                {successMessage.length && !loading ? (
                  <Grid item xs={8}>
                    <Alert
                      severity="success"
                      icon={false}
                      style={{
                        backgroundColor: "#B7D2D9",
                        color: "#12586A",
                      }}
                    >
                      <strong>Success: {successMessage}</strong>
                    </Alert>
                  </Grid>
                ) : null}
                {/* </Grid> */}
                <Grid
                  item
                  xs={6}
                  md={9}
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  {otpSent ? (
                    <Button
                      className={classes.buttonContainer}
                      variant="contained"
                      disabled={onSend.otp.length !== 6 || !onSend.email}
                      onClick={() => {
                        confirmOTP({
                          ...onSend,
                          email: onSend.email || localStorage.getItem("email"),
                        });
                      }}
                    >
                      {!loading ? "Verify OTP" : <SmallSpinner />}
                    </Button>
                  ) : (
                    <Button
                      className={classes.buttonContainer}
                      variant="contained"
                      onClick={() => {
                        Signup(user, setTimerDetails);
                      }}
                      disabled={errorObj?.email || !user.email}
                    >
                      {!loading ? "Send OTP" : <SmallSpinner />}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    regUser: authReducer.regUser,
    loading: authReducer.loading,
    error: authReducer.error,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Signup: (data, callback) => dispatch(actionCreator.Signup(data, callback)),
    confirmOTP: (data) => dispatch(actionCreator.confirmOTP(data)),
    LogInAsync: (data) => dispatch(actionCreator.LogInAsync(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
