import {
  Avatar,
  Box,
  Divider,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import React from "react";
import {
  notificationMessage1,
  notificationMessage2,
} from "../../../sockets/notificationConstructor";
const useStyles = makeStyles((theme) => ({
  listItem: {
    color: localStorage.getItem("dark") === "false" ? "#000" : "#fff",
    fontSize: "0.9rem",
    listStyle: "none",
    paddingRight: "2px",
    paddingTop: "0",
    alignItems: "center",
  },
  listBox: {
    display: "flex",
    padding: "6% 3% 1%",
    alignItems: "center",
    // "&:hover": {
    //   backgroundColor: localStorage.getItem("dark") ? "#e0e0e0" : "#6d6d6d",
    //   borderRadius: "10px",
    // },
  },

  time: {
    fontSize: "0.9rem",
  },
}));
const NotificationBox = (props) => {
  const { today, matches, date, message, time, newNotification } = props;
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Box key={date} className={classes.listBox}>
        {message?.data?.fromUser?.profile_pic ? (
          <Avatar
            style={{
              height: matches ? "2.5rem" : "5rem",
              width: matches ? "2.5rem" : "5rem",
            }}
            alt="profile_pic"
            src={
              process.env.REACT_APP_SPACE_KEY +
              "/" +
              message?.data?.fromUser?.profile_pic
            }
          />
        ) : (
          <AccountCircle fontSize="large" />
        )}

        <ListItem className={classes.listItem} key={message?.notification_id}>
          <Typography
            style={{ color: message.is_read == false ? "#F3E7BA" : null }}
          >
            <text>You have received </text>{" "}
            <text style={{ color: "#FFB476" }}>
              {notificationMessage1(message)}
            </text>{" "}
            {"from"}
            <text style={{ fontWeight: "bolder" }}>
              {notificationMessage2(message)}
            </text>
          </Typography>
        </ListItem>
        <Box>
          {date == today ? (
            <p className={classes.time}>{time}</p>
          ) : (
            <p className={classes.time}>{date}</p>
          )}
        </Box>
      </Box>
      <Divider variant="middle" />
    </>
  );
};

export default NotificationBox;
